import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";

import { Header } from "../Header";
import Main from "../Main";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Layout = () => {
  const location = useLocation();
  const isHeader =
    location.pathname !== "/" && location.pathname !== "/user_info";

  const isMobile = useMediaQuery("(max-width:810px)");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Container maxWidth="100%">
      <Box
        sx={{
          pt: isHeader ? 14 : 0,
          height: windowHeight <= 640 ? "100%" : "100vh",
          bgcolor: "white.main",
          display: isHeader || isMobile ? "block" : "flex",
          justifyContent: isHeader ? "normal" : "center",
          alignItems: isHeader ? "inherit" : "center",
          mb: isHeader ? "0px" : "15px",
        }}
      >
        {isHeader ? <Header /> : null}
        <Main />
      </Box>
    </Container>
  );
};
