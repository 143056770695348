import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import { styles } from "./styles";

export const SidebarIcon = ({ onClick, isCurentPage, icon, helper }) => {
  return (
    <Tooltip title={helper}>
      <IconButton onClick={onClick} sx={styles.button(isCurentPage)}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};
