export const styles = {
  collapse: {
    ml: 6.25,
    border: 0,
  },
  tableCell: {
    p: 2,
    border: 0,
  },
  functionWrapper: {
    fleDirection: "row",
    alignItems: "center",
    position: "relative",
    pl: 8,
  },
  functionIcon: {
    width: 38,
    height: 29,
    position: "absolute",
    top: 15,
    left: 0,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      maxWidth: 1000,
      "& input": {
        fontFamily: "Roboto",
      },
      "& fieldset": {
        boxShadow: "none",
        borderColor: "primary.main",
      },
      "&:hover fieldset": {
        borderColor: "primary.main",
      },
      "&:focus fieldset": {
        borderColor: "primary.main",
      },
      "& + .MuiFormHelperText-root": {
        color: "primary.main",
      },
    },
  },
  optionList: {
    position: "absolute",
    top: "105%",
    left: 64,
    width: "100%",
    maxWidth: 1000,
    border: "1px solid",
    borderColor: "primary.main",
    borderRadius: 1,
    bgcolor: "white.main",
    zIndex: 10,
    overflow: "hidden",
  },
  optionItem: {
    fontFamily: "Roboto",
  },
};
