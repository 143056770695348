import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { styles } from "./styles";
import { useLibraryModalContext } from "../../context";
import { CircleAddButton, Loader } from "../../components";

import {
  LibrarySecondModal,
  ToggleViewButtons,
  GridView,
  ListView,
} from "./components";
import NavigationSidebar from "../../components/NavigationSidebar";
import { useDispatch } from "react-redux";
import {
  AddModelBlocks,
  DeleteModelBlocks,
  EditModelBlocks,
  FetchModel,
  FetchModelBlocks,
  SyncModel,
  getBlocks,
  getModels,
} from "../../Redux/ModelsSlice";
import { useSelector } from "react-redux";

export const BuildModel = () => {
  const { isSecondLibraryModal, onToggleSecondLibraryModal } =
    useLibraryModalContext();
  const { modelId } = useParams();
  const [viewMode, setViewMode] = useState("grid");
  const [selectedFirstModalBlockName, setSelectedFirstModalBlockName] =
    useState("");
  const dispatch = useDispatch();
  const userModels = useSelector(getModels);
  const userBlocks = useSelector(getBlocks);

  useEffect(() => {
    const getModelBlocks = async () => {
      await dispatch(FetchModel({ modelId }));
      await dispatch(FetchModelBlocks({ modelId }));
    };
    getModelBlocks();
  }, []);

  const handleDeleteBlock = async (blockId) => {
    await dispatch(DeleteModelBlocks({ blockId })).then(async (response) => {
      await dispatch(FetchModelBlocks({ modelId }));
    });
  };

  const handleEditBlock = async ({ id, newName }) => {
    await dispatch(EditModelBlocks({ id, newName })).then(async (response) => {
      await dispatch(FetchModelBlocks({ modelId }));
    });
  };

  const selectViewMode = (event, viewMode) => {
    setViewMode(viewMode);
  };

  const createNewBlock = async () => {
    console.log(userBlocks?.blocks);
    const arrayWithCustomBlocks = userBlocks?.blocks?.filter((block) =>
      block.name.includes("Custom block")
    );

    let newCreatedBlock = {};

    newCreatedBlock =
      arrayWithCustomBlocks.length === 0
        ? { name: "Custom block" }
        : arrayWithCustomBlocks?.reduce((acc, block) => {
            if (block.name.includes("Custom block")) {
              acc.name = `Custom block ${arrayWithCustomBlocks.length + 1}`;
            }
            return acc;
          }, {});
    await dispatch(AddModelBlocks({ modelId, newCreatedBlock }));
  };

  const handleSync = async () => {
    await dispatch(SyncModel({ modelId }));
  };
  
  const showBlocksView = () => {
    switch (viewMode) {
      case "list":
        return (
          <ListView
            blocks={userBlocks?.blocks}
            onDelete={handleDeleteBlock}
            onUpdate={handleEditBlock}
          />
        );

      default:
        return (
          <GridView
            blocks={userBlocks?.blocks}
            onDelete={handleDeleteBlock}
            onUpdate={handleEditBlock}
          />
        );
    }
  };
  return (
    <Stack height="100%" direction="row">
      <NavigationSidebar />

      <Stack sx={styles.content} spacing={7.5}>
        {!userModels ? (
          <Typography sx={{ ...styles.pageTitle(1), color: "primary.main" }}>
            ...Loading
          </Typography>
        ) : (
          <Breadcrumbs separator={<NavigateNextIcon sx={styles.separator} />}>
            <Typography sx={styles.pageTitle(1)}>{userModels?.name}</Typography>
            <Typography sx={styles.pageTitle(2)}>Blocks</Typography>
          </Breadcrumbs>
        )}

        <Stack sx={styles.buttonsSection}>
          <Stack direction="row" spacing={5}>
            <CircleAddButton
              label="Add new block"
              icon="plus"
              onClick={createNewBlock}
            />
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2.5}>
            <Button
              submit
              variant="contained"
              bgcolor="primary.main"
              onClick={handleSync}
            >
              Sync
            </Button>
            <Typography sx={styles.viewBy}>View by:</Typography>

            <ToggleViewButtons
              viewMode={viewMode}
              onSelectView={selectViewMode}
            />
          </Stack>
        </Stack>

        <Stack sx={styles.cardsContainer}>
          {!userBlocks ? (
            <Loader justifyContent="center" height="100%" />
          ) : (
            <Stack sx={styles.grid(viewMode)}>{showBlocksView()}</Stack>
          )}
        </Stack>
      </Stack>

      <LibrarySecondModal
        open={isSecondLibraryModal}
        onClose={onToggleSecondLibraryModal}
        selectedFirstModalBlockName={selectedFirstModalBlockName}
        setSelectedFirstModalBlockName={setSelectedFirstModalBlockName}
      />
    </Stack>
  );
};
