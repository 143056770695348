import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { Handle } from "react-flow-renderer";

import { styles } from "./styles";

export const MapIndicatorNode = ({ data }) => {
  let inputHandlePos;
  let outputHandlePos;
  if (data.direction === "TB") {
    inputHandlePos = "top";
    outputHandlePos = "bottom";
  } else {
    inputHandlePos = "left";
    outputHandlePos = "right";
  }

  return (
    <div style={styles.node}>
      <Handle
        type="target"
        position={inputHandlePos}
      />
      <Tooltip title={data.label}>
        <Typography noWrap sx={styles.label}>
          {data.label}
        </Typography>
      </Tooltip>

      <Tooltip title={data.block_name}>
        <Typography noWrap sx={styles.chip}>
          {data.obj_name}
        </Typography>
      </Tooltip>
      <Handle
        type="source"
        position={outputHandlePos}
      />
    </div>
  );
};
