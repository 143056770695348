import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import {
  AuthProvider,
  PivotProvider,
  LibraryModalProvider,
  RenameIndicatorProvider,
} from "./context";

import App from "./App";

async function run() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const { REACT_APP_LAUNCH_DARKLY_CLIENT_KEY } = process.env;
  const LDProvider = await asyncWithLDProvider({
    clientSideID:
      REACT_APP_LAUNCH_DARKLY_CLIENT_KEY || "63ce906386fd1a137aa04931",
  });

  ReactDOM.render(
    <Provider store={store}>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthProvider>
              <LibraryModalProvider>
                <PivotProvider>
                  <RenameIndicatorProvider>
                    <App />
                  </RenameIndicatorProvider>
                </PivotProvider>
              </LibraryModalProvider>
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </LDProvider>
    </Provider>,
    document.getElementById("root")
  );
}

function main() {
  try {
    run();
  } catch (error) {
    console.error(error);
  }
}

main();
