import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

export const EntryTypeSelector = ({ entryType, onSelectEntryType }) => {
  return (
    <ToggleButtonGroup
      exclusive
      value={entryType}
      onChange={onSelectEntryType}
      aria-label="entry type"
      color="primary"
    >
      <ToggleButton
        value="raw"
        onClick={onSelectEntryType}
        sx={{
          pointerEvents: entryType === "raw" ? "none" : "initial",
        }}
        aria-label="raw type"
      >
        Raw
      </ToggleButton>
      <ToggleButton
        value="growth"
        onClick={onSelectEntryType}
        sx={{
          pointerEvents: entryType === "growth" ? "none" : "initial",
        }}
        aria-label="growth type"
      >
        Growth
      </ToggleButton>
      <ToggleButton
        value="constant"
        onClick={onSelectEntryType}
        sx={{
          pointerEvents: entryType === "constant" ? "none" : "initial",
        }}
        aria-label="constant type"
      >
        Constant
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
