import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import BarChartIcon from "@mui/icons-material/BarChart";

import { styles } from "./styles";
import { apiService } from "../../../../services";

export const ChartType = ({ chartType, indicatorId }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["indicator", indicatorId]);
      },
    }
  );

  const onChangeChartType = useCallback(
    (event, newType) => {
      mutate({
        indicatorId,
        data: { chart_type: newType },
      });
    },
    [indicatorId, mutate]
  );

  return (
    <Stack mt={2.5} spacing={1}>
      <Typography sx={styles.sectionTitle}>Chart Type</Typography>
      <ToggleButtonGroup
        exclusive
        value={chartType.toLowerCase()}
        onChange={onChangeChartType}
        color="primary"
      >
        <ToggleButton
          value="line"
          sx={styles.chartTypeButton(chartType === "line")}
          aria-label="line type"
        >
          <ShowChartIcon sx={{ mr: 0.5 }} />
          <Typography sx={styles.chartTypeLabel}>Line</Typography>
        </ToggleButton>
        <ToggleButton
          value="stack"
          sx={styles.chartTypeButton(chartType === "stack")}
          aria-label="stacked bar type"
        >
          <StackedBarChartIcon sx={{ mr: 0.5 }} />
          <Typography sx={styles.chartTypeLabel}>Stack</Typography>
        </ToggleButton>
        <ToggleButton
          value="bar"
          sx={styles.chartTypeButton(chartType === "bar")}
          aria-label="bar type"
        >
          <BarChartIcon sx={{ mr: 0.5 }} />
          <Typography sx={styles.chartTypeLabel}>Bar</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
