export const styles = {
  pivotButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    mb: 2,
  },
  pivotButton: {
    color: "secondary.main",
    transition: "all 275ms",
    "&:hover": {
      bgcolor: "transparent",
    },
  },
  pivotButtonIcon: {
    width: 28,
    height: 28,
  },
  pivotButtonText: {
    fontFamily: "Roboto",
    fontSize: 20,
  },
  scenarioContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "11%",
    justifyContent: "space-between",
  },
};
