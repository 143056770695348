import { MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { OUTPUTFORMAT } from "../../../../constants";
import { styles } from "./styles";
import { apiService } from "../../../../services";
export const OutputFormat = ({ outputFormat, indicatorId }) => {
  const [outputFormatDefault, setOutputFormatDefault] = useState(
    outputFormat ? outputFormat : null
  );
  const { mutate } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: (response) => {
        if (response.data.output_format) {
          setOutputFormatDefault(response.data.output_format);
        }
        useQueryClient.invalidateQueries(["indicator", indicatorId]);
      },
    }
  );

  const onChangeFormat = useCallback(
    (event) => {
      mutate({
        indicatorId,
        data: { output_format: event.target.value.toLowerCase() },
      });
    },
    [indicatorId, mutate]
  );
  return (
    <Stack mt={2.5} spacing={1}>
      <Typography sx={styles.sectionTitle}>Output Format</Typography>

      <Select
        labelId="format-select-label"
        id="format-select"
        value={
          outputFormatDefault ? outputFormatDefault.toLowerCase() : "default"
        }
        onChange={onChangeFormat}
        sx={styles.select}
      >
        {OUTPUTFORMAT.map((format) => (
          <MenuItem key={format.name} value={format.name.toLowerCase()}>
            {format.name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
