import { MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { DATA_TYPE } from "../../../../constants";
import { apiService } from "../../../../services";

export const DataType = ({ dataType, indicatorId }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["indicator", indicatorId]);
      },
    }
  );

  const onChangeDataType = useCallback(
    (event) => {
      mutate({
        indicatorId,
        data: { data_type: event.target.value },
      });
    },
    [indicatorId, mutate]
  );

  return (
    <Stack mt={2.5} spacing={1}>
      <Typography sx={styles.sectionTitle}>Data Type</Typography>
      <Select
        labelId="data-type-select-label"
        id="data-type-select"
        value={dataType.toLowerCase()}
        onChange={onChangeDataType}
        sx={styles.select}
      >
        {DATA_TYPE.map((type) => (
          <MenuItem key={type.name} value={type.value}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
