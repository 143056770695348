import React, { useState } from "react";
import { Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { styles } from "./styles";
import BuildTab from "../BuildTab";
import ViewTab from "../ViewTab";
import { MapTab } from "../MapTab";

export const Tabs = ({
  requiredBlock,
  modelDimensions,
  selectedModelDimensions,
  onSelectModelDimensions,
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Stack>
        <TabList
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab label="Build" value="1" sx={styles.tab(1, value)} />
          <Tab label="View" value="2" sx={styles.tab(2, value)} />
          <Tab label="Map" value="3" sx={styles.tab(3, value)} />
        </TabList>
      </Stack>

      <TabPanel value="1" sx={styles.tabPanel}>
        <BuildTab
          requiredBlock={requiredBlock}
          modelDimensions={modelDimensions}
          selectedModelDimensions={selectedModelDimensions}
          onSelectModelDimensions={onSelectModelDimensions}
        />
      </TabPanel>
      <TabPanel value="2" sx={styles.tabPanel}>
        {requiredBlock?.indicators?.length > 0 ? (
          <ViewTab requiredBlock={requiredBlock} />
        ) : (
          <Stack sx={styles.viewTabInfoBox}>
            <Typography sx={styles.viewTabInfoText}>
              Nothing to show. Please add indicator on the "Build" tab
            </Typography>
          </Stack>
        )}
      </TabPanel>
      <TabPanel value="3" sx={styles.tabPanel}>
        <MapTab />
      </TabPanel>
    </TabContext>
  );
};
