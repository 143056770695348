export const styles = {
  tableCell: {
    width: 400,
    px: 1,
    py: 0,
    cursor: "default",
  },
  cellContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dragIcon: { cursor: "grab", color: "black.main", opacity: 0.6 },
  indicatorName: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "black.main",
    cursor: "pointer",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
        boxShadow: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
  typeButton: (type) => ({
    position: "relative",
    ml: 1,
    mr: 3,
    color: type?.toLowerCase() === "input" ? "primary.main" : "secondary.main",
  }),
  typeButtonArrow: {
    position: "absolute",
    bottom: -10,
    right: -10,
    color: "grey.main",
  },
};
