import { TextField } from "@mui/material";
import React from "react";

import { styles } from "./styles";

export const Input = ({
  value,
  placeholder,
  onChange,
  onBlur,
  onKeyPress,
  inputProps,
}) => (
  <TextField
    variant="standard"
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    placeholder={placeholder}
    autoFocus
    inputProps={inputProps ? inputProps : { style: styles.input }}
  />
);
