import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../services";
import { Button } from "../UI";
import UploadFiles from "../UploadImage";

export const ModelProperiesModal = ({ open, onClose, data }) => {
  const [uploadingFiles,setUploadingFiles]=useState(false);
  const queryClient = useQueryClient();
  const [values, setValues] = useState({
    name: data.name,
    description: data.description,
    library_tags: data.library_tags,
    reporting_currency: data.reporting_currency,
    library_model: data.library_model,
    is_time_granularity_editable: data.is_time_granularity_editable,
    icon:data.icon
  });

  const { mutate } = useMutation(
    ({ modelId, data }) => apiService.updateModel(modelId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("models");
      },
    }
  );

  const handleChangeValues = async(event) => {
    const { name, value, checked } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]:
        name === "library_model" || name === "is_time_granularity_editable"
          ? checked
          : value,
    }));
  };

  const submitValues = () => {
    onClose();
    mutate({
      modelId: data.id,
      data: {
        ...(data.name !== values.name && { name: values.name }),
        ...(data.description !== values.description && {
          description: values.description,
        }),
        ...(data.library_tags !== values.library_tags && {
          library_tags: values.library_tags,
        }),
        ...(data.reporting_currency !== values.reporting_currency && {
          reporting_currency: values.reporting_currency,
        }),
        ...(data.library_model !== values.library_model && {
          library_model: values.library_model,
        }),
        is_time_granularity_editable: values.is_time_granularity_editable,
        icon:values.icon
      },
    });
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modal}>
        <IconButton sx={styles.close} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <TextField
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChangeValues}
          fullWidth
          size="small"
          sx={styles.textField}
        />

        <TextField
          name="description"
          label="Description"
          value={values.description}
          onChange={handleChangeValues}
          fullWidth
          size="small"
          sx={styles.textField}
        />

        <Stack direction="row" spacing={2}>
          <TextField
            name="library_tags"
            label="Library tags"
            value={values.library_tags}
            onChange={handleChangeValues}
            fullWidth
            size="small"
            sx={styles.textField}
          />

          <TextField
            name="reporting_currency"
            label="Reporting currency"
            value={values.reporting_currency}
            onChange={handleChangeValues}
            fullWidth
            size="small"
            sx={styles.textField}
          />
        </Stack>
        <Stack>
        <Stack> 
          <UploadFiles data={data} values={values} 
          deleteImages={(e)=>{handleChangeValues({target:{name:'icon',value:''}})}}
           uploadedFile={(e)=>{handleChangeValues({target:{name:'icon',value:e}})}}
           updateImages={(e)=>{handleChangeValues({target:{name:'icon',value:e}})}}
           setLoading={(e)=>{setUploadingFiles(e)}}
           />
                  </Stack>
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.library_model}
              onChange={handleChangeValues}
            />
          }
          name="library_model"
          label="Add to public library?"
          sx={styles.checkboxWithLabel}
        />

        <FormControlLabel
          control={
            <Checkbox
              name="is_time_granularity_editable"
              label="Editable time granularity"
              sx={styles.checkboxWithLabel}
              checked={values.is_time_granularity_editable}
              onChange={handleChangeValues}
            />
          }
          label="Editable time granularity"
          sx={styles.checkboxWithLabel}
        />

        <Button
          variant="outlined"
          fullWidth
          sx={styles.submit}
          onClick={submitValues}
          disabled={uploadingFiles}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};
