export const styles = {
  pivotPanel: {
    width: 350,
    height: "100%",
    pt: 2,
    px: 3.5,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar:horizontal": {
      height: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-thumb:horizontal": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
  pivotIcon: { width: 30, height: 30, color: "secondary.main" },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    lineHeight: 1.2,
    color: "black.main",
  },
  closeButton: {
    position: "absolute",
    top: 3,
    right: 3,
    "&:hover": { color: "red.error" },
  },
  closeButtonIcon: { width: 30, height: 30 },
  reorderImage: {
    width: 270,
    height: 250,
    alignSelf: "center",
    fill: "transparent",
  },
};
