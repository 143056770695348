import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";

export const IndicatorType = ({ type, indicatorId }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["indicator", indicatorId]);
      },
    }
  );

  const onChangeIndicatorType = useCallback(
    (newType) => {
      mutate({
        indicatorId,
        data: { type: newType },
      });
    },
    [indicatorId, mutate]
  );

  return (
    <Box mt={2.5}>
      <Typography sx={styles.sectionTitle}>Indicator Type</Typography>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={type.toLowerCase() === "input"}
              onChange={() => onChangeIndicatorType("input")}
              inputProps={{ "aria-label": "input type of indicator" }}
            />
          }
          label="Input"
          sx={styles.checkboxWithLabel}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={type.toLowerCase() === "calculation"}
              onChange={() => onChangeIndicatorType("calculation")}
              inputProps={{ "aria-label": "calculation type of indicator" }}
            />
          }
          label="Calculation"
          sx={styles.checkboxWithLabel}
        />
      </Stack>
    </Box>
  );
};
