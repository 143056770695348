export const styles = {
  sidebar: {
    alignItems: "center",
    width: 90,
    height: "100%",
    pt: 3,
    borderTopRightRadius: 20,
    backgroundImage: "linear-gradient(180deg, #7163d0 0%, #936bf6 100%)",
    "& button:not(:last-child)": {
      mb: 1,
    },
  },
  button: (isCurrentPage) => ({
    width: 60,
    height: 60,
    color: "white.main",
    bgcolor: isCurrentPage ? "primary.light" : "transparent",
    "&:hover": { bgcolor: isCurrentPage ? "primary.light" : "transparent" },
  }),
};
