export const styles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      p: 0,
      backgroundColor: "grey.border",
      borderRadius: 2.5,
      "& .MuiInputAdornment-root": {
        height: 1,
        maxHeight: "100%",
        width: 170,
        margin: 0,
        justifyContent: "center",
        "& p": {
          fontSize: 20,
          lineHeight: 1.15,
          letterSpacing: "-0.03em",
          color: "primary.main",
        },
      },
      "& input": {
        pl: 4,
        borderRadius: 2.5,
        fontSize: 20,
        lineHeight: 1.15,
        letterSpacing: "-0.03em",
        color: "primary.main",
      },
      "& fieldset": {
        borderColor: "grey.border",
        boxShadow: 0,
      },
      "&:hover fieldset": {
        borderColor: "grey.border",
      },
      "&.Mui-focused fieldset": {
        borderColor: "grey.border",
      },
    },
  },
};
