import { Box, Typography } from "@mui/material";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { apiService } from "../../../../services";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";

export const ViewTabTable = ({ blockId, queryString }) => {
  const [isQueryError, setIsQueryError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const scenarioId = useSelector(getSelectedScenario);
  const { data: blockValueswithParams } = useQuery(
    ["blockValueswithParams", blockId, queryString, scenarioId],
    () =>
      apiService.getBlockValueswithParams(blockId, {
        params: {
          orient: "table",
          query_params: queryString,
          scenario_id: scenarioId,
        },
      }),
    {
      onError: (error) => {
        setErrorMessage(error.response.data.message);
        setIsQueryError(true);
      },
      onSuccess: () => {
        setErrorMessage("");
        setIsQueryError(false);
      },
    }
  );

  const numberFormatter = (params) => {
    return new Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(params.value);
  };

  const rowData = blockValueswithParams?.data.data_values.data;

  const defaultColDef = {
    resizable: true,
    editable: true,
    filter: true,
    width: 200,
  };

  const columnDefs = blockValueswithParams?.data?.data_values?.schema?.fields.reduce(
    (acc, field) => {
      const column = {
        width: 200,
        colId: field.name,
        headerName: field.name,
        field: field.name,
      };
      if (field.type === "number") {
        column.type = "numericColumn";
        column.valueFormatter = numberFormatter;
      }
      acc.push(column);
      return acc;
    },
    []
  );

  return (
    <Box className="ag-theme-alpine" sx={styles.tableContainer}>
      {isQueryError ? (
        <Typography>{errorMessage}</Typography>
      ) : (
        <AgGridReact
          reactUi="true"
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
        />
      )}
    </Box>
  );
};
