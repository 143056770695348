import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as Reorder } from "../../../../assets/icons/re-order.svg";

import { styles } from "./styles";
import { usePivotContext } from "../../../../context";
import { PivotList } from "../PivotList";

const removeFromList = (list, index) => {
  const result = [...list];
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = [...list];
  result.splice(index, 0, element);
  return result;
};

const createQueryString = (pivotState, selectedItems) => {
  const queryObj = {};
  const filters = [];

  const rows = pivotState[1].dimensions.map((dimension) => dimension.name);
  const columns = pivotState[2].dimensions.map((dimension) => dimension.name);
  pivotState[3].dimensions.forEach((dimension) => {
    const dimensionName = dimension.name;
    if (selectedItems.hasOwnProperty(dimensionName)) {
      const filterObj = {};
      filterObj[dimensionName] = selectedItems[dimensionName];
      filters.push(filterObj);
    }
  });

  queryObj.rows = rows;
  queryObj.columns = columns;
  queryObj.filters = filters;

  const queryString = JSON.stringify(queryObj);
  return queryString;
};

export const PivotPanel = ({
  blockId,
  onUpdateQuery,
  blockDimensions,
  onClose,
}) => {
  const { pivotState, onSavePivotState } = usePivotContext();
  const [selectedItems, setSelectedItems] = useState({});

  let defaultAvailableDimensions = [];
  const defaultRowDimensions = [];
  const defaultColDimensions = [];
  const indicatorObj = { id: "Indicators", name: "Indicators" };
  let timeObj = {};
  let timeIndex;

  if (blockDimensions) {
    const blockDimensionsCopy = [...blockDimensions];

    blockDimensionsCopy.forEach((blockDimension, index) => {
      if (blockDimension.name.toLocaleLowerCase() === "time") {
        timeObj = { id: blockDimension.id, name: blockDimension.name };
        timeIndex = index;
      }
    });

    if (timeIndex > -1) {
      blockDimensionsCopy.splice(timeIndex, 1);
      defaultRowDimensions.push(indicatorObj);
      defaultColDimensions.push(timeObj);
    } else {
      defaultColDimensions.push(indicatorObj);
      const firstDimension = blockDimensionsCopy.splice(0, 1);
      defaultRowDimensions.push(firstDimension);
    }
    defaultAvailableDimensions = [...blockDimensionsCopy];
  }

  const panelLayout = [
    {
      id: 0,
      name: "available",
      heading: "Available",
      dimensions: defaultAvailableDimensions,
    },
    {
      id: 1,
      name: "rows",
      heading: "Rows",
      dimensions: defaultRowDimensions,
    },
    {
      id: 2,
      name: "columns",
      heading: "Columns",
      dimensions: defaultColDimensions,
    },
    {
      id: 3,
      name: "filters",
      heading: "Filters",
      dimensions: [],
    },
  ];

  const updatePivotState = (pivotState, blockDimensions) => {
    const blockDimensionNames = blockDimensions.map(
      (dimension) => dimension.name
    );
    const pivotStateModified = pivotState.reduce((acc, list) => {
      if (list.name === "available") return acc;

      return [...acc, ...list.dimensions.map((dimension) => dimension.name)];
    }, []);

    return pivotState.map((pivotList) => {
      if (pivotList.name === "available") {
        return {
          ...pivotList,
          dimensions: blockDimensions.filter(
            (dimension) => !pivotStateModified.includes(dimension.name)
          ),
        };
      }

      return {
        ...pivotList,
        dimensions: pivotList.dimensions.filter((dimension) => {
          return ["Indicators", "Time", ...blockDimensionNames].includes(
            dimension.name
          );
        }),
      };
    });
  };

  useEffect(() => {
    if (blockDimensions && pivotState.hasOwnProperty(blockId)) {
      onSavePivotState(
        blockId,
        updatePivotState(pivotState[blockId], blockDimensions)
      );
    } else {
      onSavePivotState(blockId, panelLayout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockDimensions]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const listCopy = [...pivotState[blockId]];

    const sourceList = listCopy[result.source.droppableId].dimensions;
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    listCopy[result.source.droppableId].dimensions = newSourceList;

    const destinationList = listCopy[result.destination.droppableId].dimensions;
    listCopy[result.destination.droppableId].dimensions = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    onSavePivotState(blockId, listCopy);
    updateQuery();
  };

  const updateQuery = () => {
    const queryString = createQueryString(pivotState[blockId], selectedItems);
    onUpdateQuery(queryString);
  };

  const onFilterItemSelect = (selectedItem) => {
    const selectedItemsState = selectedItems;
    selectedItemsState[selectedItem.dimension] = selectedItem.item;
    setSelectedItems(selectedItemsState);
    updateQuery();
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack sx={styles.pivotPanel} spacing={2.5}>
        <Stack direction="row" alignItems="center" spacing="auto">
          <Stack direction="row" spacing={1} alignItems="center">
            <PivotTableChartIcon sx={styles.pivotIcon} />
            <Typography sx={styles.title}>Pivot Table</Typography>
          </Stack>

          <IconButton onClick={onClose} sx={styles.closeButton}>
            <CloseIcon sx={styles.closeButtonIcon} />
          </IconButton>
        </Stack>

        <Stack sx={{ height: "100%" }}>
          {pivotState[blockId]?.map((list, index) => (
            <PivotList
              key={list.id}
              list={list}
              listIndex={index}
              blockId={blockId}
              onFilterItemSelect={onFilterItemSelect}
            />
          ))}
        </Stack>

        <SvgIcon
          component={Reorder}
          viewBox="0 0 298 276"
          sx={styles.reorderImage}
        />
      </Stack>
    </DragDropContext>
  );
};
