import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Formik, Form } from "formik";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as Headphones } from "../../assets/icons/headphones.svg";

import { styles } from "./styles";
import { apiService } from "../../services";
import { createModelSchema } from "../../validation";
import { Button } from "../UI";
import UploadFiles from "../UploadImage";

export const CreateModelModal = ({
  open,
  onClose,
  modelId,
  setAddModelError,
  addModelError,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [checked, setChecked] = useState(false);
  const [uploadingFiles,setUploadingFiles]=useState(false);

  const { mutate: addModelMutation } = useMutation(
    (data) => apiService.addModel(data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData("models", (oldQueryData) => {
            return {
              ...oldQueryData,
              data: {
                models: [...(oldQueryData.data.models || []), response?.data],
              },
            };
          });
          navigate(`/model/${response?.data?.id}`);
        }
      },
      onError: (response) => {
        if (response.message.includes("status code 400")) {
          setAddModelError({
            isError: true,
            message:
              "The model with the same name already exists. Choose another name",
          });
        }
      },
    }
  );

  const { mutate: copyModelMutation } = useMutation(
    ({ modelId, newModel }) =>
      apiService.addModelFromLibrary(modelId, newModel),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData("models", (oldQueryData) => {
            return {
              ...oldQueryData,
              data: {
                models: [...(oldQueryData.data.models || []), response?.data],
              },
            };
          });
          navigate(`/model/${response?.data?.id}`);
        }
      },
    }
  );

  const initialValues = {
    name: "",
    is_time_granularity_editable: true,
    icon:''
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (values) => {
    const newModel = {
      ...values,
    };

    if (modelId) {
      copyModelMutation({ modelId, newModel });
    } else {
      addModelMutation(newModel);
    }
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Box sx={styles.modal}>
        <Stack direction="row" spacing={5}>
          <Formik
            initialValues={initialValues}
            validationSchema={createModelSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <Stack justifyContent="space-between" height={1} gap={'2rem'}>
                  <IconButton sx={styles.closeButton} onClick={onClose}>
                    <CloseIcon fontSize="large" />
                  </IconButton>

                  <Typography component="h1" sx={styles.modalTitle}>
                    Create a new model
                  </Typography>

                  <Stack spacing={4}>
                    <TextField
                      autoComplete="off"
                      name="name"
                      label="Model name"
                      value={values.name}
                      onChange={(event) =>
                        setFieldValue("name", event.currentTarget.value)
                      }
                      error={
                        (Boolean(errors.name) && touched.name) ||
                        addModelError?.isError
                      }
                      helperText={errors.name}
                      sx={styles.textField(addModelError?.isError)}
                    />
                  </Stack>
                  <Stack> 
                    <UploadFiles setLoading={(e)=>{setUploadingFiles(e)}} uploadedFile={(e)=>{setFieldValue('icon',e)}}/>
                  </Stack>
                  <Stack>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Notify your team" }}
                        />
                      }
                      label="Notify your team"
                      sx={styles.checkbox}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_time_granularity_editable"
                          checked={values.is_time_granularity_editable}
                          onChange={(event) =>
                            setFieldValue(
                              "is_time_granularity_editable",
                              event.target.checked
                            )
                          }
                          inputProps={{
                            "aria-label": "Editable time granularity",
                          }}
                        />
                      }
                      label="Editable time granularity"
                      sx={styles.checkbox}
                    />
                  </Stack>

                  <Stack spacing={5} direction="row">
                    <Button
                      submit
                      isLarge
                      variant="contained"
                      bgcolor="primary.main"
                      disabled={uploadingFiles}
                    >
                      Create
                    </Button>
                    <Button
                      isLarge
                      variant="outlined"
                      color="primary.main"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>

          <SvgIcon
            component={Headphones}
            viewBox="0 0 288 345"
            sx={styles.image}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

CreateModelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
