import {
  Checkbox,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Backdrop
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { SCALE } from "../../../../constants";

export const FormatSettings = ({ dataFormatProperties, indicatorId }) => {
  const queryClient = useQueryClient();
  const [formatProperties, setFormatProperties] =
      useState(dataFormatProperties);

  const { mutate, isLoading } = useMutation(
      ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["indicator", indicatorId]);
        },
      }
  );
  const stateIfFetching = queryClient.getQueryState(["indicator", indicatorId])

  const changeFormatProperties = useCallback((event) => {
    const { value, name, checked } = event.target;

    setFormatProperties((prevState) => ({
      ...prevState,
      [name]:
          name === "thousand_separators" || name === "negative_parentheses"
              ? checked
              : value,
    }));
  }, []);


  useEffect(() => {
      mutate({
      indicatorId,
      data: { data_format_properties: formatProperties },
    });
  }, [formatProperties, indicatorId, mutate]);

  return (
      <Stack spacing={1} position='relative'>
         <Backdrop open={(isLoading || stateIfFetching.isFetching)} sx={{position: 'absolute', zIndex: '1000', backgroundColor: 'rgb(255,255,255,0.75)'}}/>
         <Stack direction="row" spacing="auto" alignItems="center">
          <Typography sx={styles.formatName}>Scaling</Typography>
          <Select
              name="scale"
              labelId="scaling-select-label"
              id="scaling-select"
              value={formatProperties.scale ?? "none"}
              onChange={changeFormatProperties}
              sx={styles.formatField}
          >
            {SCALE.map((format) => (
                <MenuItem key={format.name} value={format.name}>
                  {format.name}
                </MenuItem>
            ))}
          </Select>
        </Stack>

        <Stack direction="row" spacing="auto" alignItems="center">
          <Typography sx={styles.formatName}>Measure</Typography>
          <TextField
              name="measure"
              value={formatProperties.measure}
              onChange={changeFormatProperties}
              autoComplete={false}
              sx={styles.input}
          />
        </Stack>

        <Stack direction="row" spacing="auto" alignItems="center">
          <Typography sx={styles.formatName}>Decimal Points</Typography>
          <TextField
              name="decimal_points"
              value={formatProperties.decimal_points}
              onChange={changeFormatProperties}
              autoComplete={false}
              sx={styles.input}
          />
        </Stack>

        <Stack direction="row" spacing="auto" alignItems="center">
          <Typography sx={styles.formatName}>Missing value display</Typography>
          <TextField
              name="null_value"
              value={formatProperties.null_value}
              onChange={changeFormatProperties}
              autoComplete={false}
              sx={styles.input}
          />
        </Stack>

        <Stack direction="row" spacing="auto" alignItems="center">
          <Typography sx={styles.formatName}>Show thousand separators</Typography>
          <Checkbox
              name="thousand_separators"
              checked={formatProperties.thousand_separators}
              onChange={changeFormatProperties}
              sx={styles.checkboxWithoutLabel}
          />
        </Stack>

        <Stack direction="row" spacing="auto" alignItems="center">
          <Typography sx={styles.formatName}>
            Use parentheses for negative values
          </Typography>
          <Checkbox
              name="negative_parentheses"
              checked={formatProperties.negative_parentheses}
              onChange={changeFormatProperties}
              sx={styles.checkboxWithoutLabel}
          />
        </Stack>
     </Stack>
  );
};
