import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Headphones } from "../../assets/icons/headphones.svg";
import { styles } from "./styles";
import { Button } from "../UI";
import UploadFiles from "../UploadImage";
export const RenameModal = ({ open, onClose, data, onUpdate, title }) => {
  const [uploadingFiles,setUploadingFiles]=useState(false);
  const [values, setValues] = useState({
    name: data.name,
    icon:data.icon,
  });
  const handleSubmit = (e) => {
    onUpdate({ id: data?.id, newName:data.name===e.name?{icon:e.icon}:{name:e.name,icon:e.icon}});
    onClose();
  };
  const handleChangeValues = async(event) => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]:value,
    }));
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Box sx={styles.modal}>
        <Stack direction="row" spacing={5}>
                <Stack justifyContent="space-between" height={1} gap={'2rem'}>
                  <IconButton sx={styles.close} onClick={onClose}>
                    <CloseIcon fontSize="large" />
                  </IconButton>

                  <Typography component="h1" sx={styles.title}>
                    Edit Custom block
                  </Typography>
                  <Stack direction="column" alignItems={'flex-start'} spacing={2}>
                <Typography sx={styles.label}>Block Name:</Typography>
                <TextField
                  name="name"
                  value={values?.name}
                  onChange={handleChangeValues}
                  fullWidth
                  sx={styles.textField}
                />
              </Stack>
                  <Stack direction="column" alignItems={'flex-start'} spacing={2}> 
                  <Typography sx={styles.label}>Upload Thumbnai:</Typography>
                    <UploadFiles data={data} values={values} uploadedFile={(e)=>{handleChangeValues({target:{name:'icon',value:e}})}}
                     deleteImages={(e)=>{handleChangeValues({target:{name:'icon',value:''}})}}
                     updateImages={(e)=>{handleChangeValues({target:{name:'icon',value:e}})}}
                     setLoading={(e)=>{setUploadingFiles(e)}}
                    />
                  </Stack>
                  <Stack spacing={5} direction="row">
                    <Button
                      isLarge
                      variant="outlined"
                      color="primary.main"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={()=>handleSubmit(values)}
                      isLarge
                      variant="contained"
                      bgcolor="primary.main"
                      disabled={uploadingFiles}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
          <SvgIcon
            component={Headphones}
            viewBox="0 0 288 345"
            sx={styles.image}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

RenameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  block: PropTypes.object,
  onUpdate: PropTypes.func,
  title: PropTypes.string.isRequired,
};
