import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  Auth,
  Profile,
  WorkSpace,
  FinancialModels,
  ProductModels,
  BuildModel,
  BlockDetailed,
  Dimensions,
} from "../../pages";
import { RequireAuth } from "../RequireAuth";
import Scenarios from "../../pages/Scenarios";

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route
        path="/user_info"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        path="/workspace"
        element={
          <RequireAuth>
            <WorkSpace />
          </RequireAuth>
        }
      />
      {/* <Route
        path="/library"
        element={
          <RequireAuth>
            <RecommendedModels />
          </RequireAuth>
        }
      /> */}
      
      <Route
        path="/financial-models"
        element={
          <RequireAuth>
            <FinancialModels />
          </RequireAuth>
        }
      />
      <Route
        path="/product-models"
        element={
          <RequireAuth>
            <ProductModels />
          </RequireAuth>
        }
      />
      <Route
        path="/model/:modelId"
        element={
          <RequireAuth>
            <BuildModel />
          </RequireAuth>
        }
      />
      <Route
        path="/model/:modelId/block/:blockId"
        element={
          <RequireAuth>
            <BlockDetailed />
          </RequireAuth>
        }
      />
      <Route
        path="/model/:modelId/dimensions"
        element={
          <RequireAuth>
            <Dimensions />
          </RequireAuth>
        }
      />
        <Route
          path="/model/:modelId/scenario"
          element={
            <RequireAuth>
              <Scenarios />
            </RequireAuth>
          }
        />
    </Routes>
  );
};

export default Main;
