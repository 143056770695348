export const styles = {
  button: {
    width: 50,
    height: 50,
    border: "1px solid",
    borderColor: "secondary.main",
    color: "secondary.main",
    transition: "all 275ms",
    "&:hover": {
      color: "white.main",
      bgcolor: "secondary.main",
    },
  },
  buttonIcon: {
    width: 32,
    height: 32,
  },
  buttonTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.15,
    color: "black.main",
  },
};
