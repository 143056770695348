import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";

export const ActionButton = ({
  tooltipTitle,
  onClick,
  type,
  indicatorId,
  isExpanded,
  isFavourite,
}) => {
  const getButtonIcon = () => {
    switch (type) {
      case "menu":
        return <MoreHorizIcon sx={{ color: "black.main" }} />;

      case "expand":
        return (
          <ExpandMoreIcon
            sx={{
              color: "black.main",
              transform:
                isExpanded.indicatorId === indicatorId &&
                isExpanded.expanded === true
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              transition: "all 275ms",
            }}
          />
        );

      default:
        return (
          <StarIcon
            sx={{
              color: isFavourite ? "black.main" : "grey.main",
            }}
          />
        );
    }
  };

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton aria-label={tooltipTitle} onClick={onClick}>
        {getButtonIcon(type)}
      </IconButton>
    </Tooltip>
  );
};

ActionButton.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  indicatorId: PropTypes.number,
  isExpanded: PropTypes.shape({
    indicatorId: PropTypes.number,
    expanded: PropTypes.bool,
  }),
  isFavourite: PropTypes.bool,
};
