export const USER_ROLE = [
  { value: "1", name: "Manager" },
  { value: "2", name: "CEO" },
  { value: "3", name: "Developer" },
];

export const USER_INDUSTRY = [
  { value: "1", name: "Financial" },
  { value: "2", name: "IT" },
  { value: "3", name: "Building" },
];

export const USER_GOALS = [
  { value: "1", name: "Revenue Growth" },
  { value: "2", name: "Funding" },
];
