export const styles = {
  tabContainer: {
    height: "100%",
    overflow: "hidden",
  },
  addIndicatorButton: {
    width: 50,
    height: 50,
    border: "1px solid",
    borderColor: "secondary.main",
    color: "secondary.main",
    transition: "all 275ms",
    "&:hover": {
      color: "white.main",
      bgcolor: "secondary.main",
    },
  },
  icon: {
    width: 32,
    height: 32,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.15,
    color: "black.main",
  },
  dimensionChip: {
    borderRadius: 2.5,
    borderColor: "grey.border",
    height: 40,
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.15,
    color: "primary.main",
    bgcolor: "grey.border",
  },
  editDimensionsButton: {
    borderRadius: 2.5,
    borderColor: "grey.border",
    height: 40,
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 20,
    color: "grey.main",
    textTransform: "none",
    transition: "all 275ms",
    "&:hover": {
      color: "white.main",
      bgcolor: "secondary.main",
      borderColor: "secondary.main",
    },
  },
  BuildScenario: {
    flexDirection: "row",
    alignItems: "center",
    width: "11%",
    justifyContent: "space-between",
    margin: "0px",
    padding: "0px",
  },
};
