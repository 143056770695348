export const styles = {
  iconWrapper: {
    border: "1px solid",
    borderColor: "primary.main",
    borderRadius: 2.5,
    width: 62,
    height: 62,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    width: 32,
    height: 32,
  },
  blockName: {
    cursor: "pointer",
    transition: "all 275ms",
    "&:hover": {
      textDecoration: "underline",
      textDecorationThickness: 2,
    },
  },
  avatar: {
    bgcolor: "secondary.main",
    width: 44,
    height: 44,
  },
  mainText: {
    fontSize: 32,
    color: "black.main",
  },
  dimensionChip: {
    alignItems: "center",
    height: 44,
    borderRadius: 4,
    px: 0.5,
    fontSize: 20,
    letterSpacing: "-0.015em",
    color: "white.main",
    bgcolor: "primary.main",
  },
};
