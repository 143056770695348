import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Split from "react-split";

import { Button } from "../../components";
import NavigationSidebar from "../../components/NavigationSidebar";
import { indicatorAvailable } from "../../Redux/ScenarioSlice";
import { apiService } from "../../services";
import { MapTab } from "./components";
import BuildTab from "./components/BuildTab";
import ViewTab from "./components/ViewTab";
import { styles } from "./styles";

export const BlockDetailed = () => {
  const { blockId, modelId } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState({});
  const [requiredBlock, setRequiredBlock] = useState({});
  const [selectedModelDimensions, setSelectedModelDimensions] = useState({});
  const [currentTab, setCurrentTab] = useState("build");
  const dispatch = useDispatch();

  const { isLoading: isModelLoading } = useQuery(
    ["model", modelId],
    () => apiService.getModel(modelId),
    {
      onSuccess: (response) => {
        setModel(response?.data);
      },
    }
  );

  useQuery(["block", blockId], () => apiService.getBlock(blockId), {
    onSuccess: (response) => {
      const selectedModelDimensions = response?.data?.dimensions?.reduce(
        (acc, dimension) => {
          acc.dimension_id = [...(acc?.dimension_id || []), dimension?.id];
          return acc;
        },
        {}
      );
      setRequiredBlock(response?.data);
      setSelectedModelDimensions(selectedModelDimensions);
    },
  });
  const isIndicator = requiredBlock?.indicators?.length !== 0;
  useEffect(() => {
    dispatch(indicatorAvailable(isIndicator));
  }, [blockId, selectedModelDimensions]);

  const modelDimensions = model?.dimensions?.filter(
    (dimension) => dimension.name.toLocaleLowerCase() !== "time"
  );

  const selectTab = (tabName) => {
    setCurrentTab(tabName);
  };

  const getTabContent = (currentTab) => {
    switch (currentTab) {
      case "view":
        return <ViewTab requiredBlock={requiredBlock} />;

      case "map":
        return <MapTab />;

      default:
        return (
          <BuildTab
            requiredBlock={requiredBlock}
            modelDimensions={modelDimensions}
            selectedModelDimensions={selectedModelDimensions}
            onSelectModelDimensions={setSelectedModelDimensions}
          />
        );
    }
  };

  const onDragEnd = (sizes) => {
    sessionStorage.setItem("percent", JSON.stringify(sizes[1]));
  };

  return (
    <Stack height="100%" direction="row">
      <NavigationSidebar />

      <Stack sx={styles.pageContent} spacing={6}>
        {isModelLoading ? (
          <Typography sx={{ ...styles.pageTitle(1), color: "primary.main" }}>
            ...Loading
          </Typography>
        ) : (
          <Breadcrumbs separator={<NavigateNextIcon sx={styles.separator} />}>
            <Typography
              sx={styles.pageTitle(1)}
              onClick={() => navigate(`/model/${modelId}`)}
            >
              {model?.name}
            </Typography>
            <Typography sx={styles.pageTitle(2)}>
              {requiredBlock?.name}
            </Typography>
          </Breadcrumbs>
        )}

        <Split
          className="split"
          // sizes={getSplitSizes(performanceOpen)}
          // minSize={performanceOpen ? [0, window.innerWidth * 0.25] : 0}
          maxSize={[Infinity, window.innerWidth * 0.4]}
          gutterSize={15}
          onDragEnd={onDragEnd}
        >
          <Stack spacing={6} sx={styles.tabs}>
            <Stack sx={styles.tabsNavigation}>
              <Button
                sx={styles.tabButton(currentTab === "build")}
                onClick={() => selectTab("build")}
              >
                Build
              </Button>
              <Button
                sx={styles.tabButton(currentTab === "view")}
                onClick={() => selectTab("view")}
              >
                View
              </Button>
              <Button
                sx={styles.tabButton(currentTab === "map")}
                onClick={() => selectTab("map")}
              >
                Map
              </Button>
            </Stack>

            <Stack sx={styles.tabsContent}>{getTabContent(currentTab)}</Stack>
          </Stack>

        </Split>
      </Stack>
    </Stack>
  );
};
