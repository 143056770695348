export const styles = {
  tableCell: {
    width: 350,
    height: 60,
    py: 0,
    borderRight: "1px solid",
    borderColor: "grey.main",
    cursor: "default",
  },
  dimensionName: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "black.main",
  },
  editableTableCell: {
    position: "relative",
    width: 130,
    height: 60,
    p: 0,
    borderRight: "1px solid",
    borderColor: "grey.main",
    "&:last-child": { borderRight: 0 },
    "& > svg": {
      display: "none",
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 100,
    },
    "&:hover > svg": {
      display: "inline-flex",
      width: 14,
      height: 14,
    },
  },
};
