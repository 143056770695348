import { BlockCard } from "../BlockCard";

export const GridView = ({ blocks, onDelete, onUpdate }) => {
  return (
    <>
      {blocks?.map((block) => (
        <BlockCard
          key={block?.id}
          block={block}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      ))}
    </>
  );
};
