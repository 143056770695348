export const formatValue = (defaultValue, formatProperties) => {
  if (formatProperties && defaultValue) {
    const {
      decimal_points: decimalPoints,
      measure,
      negative_parentheses: negativeParentheses,
      scale,
      thousand_separators: thousandSeparators,
    } = formatProperties;

    const value =
      typeof defaultValue === "number"
        ? defaultValue
        : defaultValue.reduce((accumulator, item) => {
            accumulator += Number(item);
            return accumulator;
          }, 0);

    if (measure === '%') {
      return `${value * 100}%`;
    }

    if (value < 0 && negativeParentheses) {
      if (scale === "thousands") {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `(${measure}${(value / 1000).toLocaleString("en-GB", {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              })}k)`;
            }
            return `(${(value / 1000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}k${measure})`;
          }

          // measure === NULL
          return `(${(value / 1000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}k)`;
        }

        // thousandSeparators === false
        return !measure
          ? `(${(value / 1000).toFixed(decimalPoints)}k)`
          : ["$", "£", "€"].includes(measure)
          ? `(${measure}${(value / 1000).toFixed(decimalPoints)}k)`
          : `(${(value / 1000).toFixed(decimalPoints)}k${measure})`;
      }

      if (scale === "millions") {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `(${measure}${(value / 1_000_000).toLocaleString("en-GB", {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              })}mn)`;
            }

            return `(${(value / 1_000_000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}mn${measure})`;
          }

          // measure === NULL
          return `(${(value / 1_000_000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}mn)`;
        }

        // thousandSeparators === false
        return !measure
          ? `(${(value / 1_000_000).toFixed(decimalPoints)}mn)`
          : ["$", "£", "€"].includes(measure)
          ? `(${measure}${(value / 1_000_000).toFixed(decimalPoints)}mn)`
          : `(${(value / 1_000_000).toFixed(decimalPoints)}mn${measure})`;
      }

      if (scale === "billions") {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `(${measure}${(value / 1_000_000_000).toLocaleString(
                "en-GB",
                {
                  minimumFractionDigits: decimalPoints,
                  maximumFractionDigits: decimalPoints,
                }
              )}bn)`;
            }

            return `(${(value / 1_000_000_000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}bn${measure})`;
          }

          // measure === NULL
          return `(${(value / 1_000_000_000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}bn)`;
        }

        // thousandSeparators === false
        return !measure
          ? `(${(value / 1_000_000_000).toFixed(decimalPoints)}bn)`
          : ["$", "£", "€"].includes(measure)
          ? `(${measure}${(value / 1_000_000_000).toFixed(decimalPoints)}bn)`
          : `(${(value / 1_000_000_000).toFixed(decimalPoints)}bn${measure})`;
      }

      if (!scale) {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `(${measure}${value.toLocaleString("en-GB", {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              })})`;
            }

            return `(${value.toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}${measure})`;
          }

          // measure === NULL
          return `(${value.toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })})`;
        }

        // thousandSeparators === false
        return !measure
          ? `(${value.toFixed(decimalPoints)})`
          : ["$", "£", "€"].includes(measure)
          ? `(${measure}${value.toFixed(decimalPoints)})`
          : `(${value.toFixed(decimalPoints)}${measure})`;
      }
    }

    if (value < 0 && !negativeParentheses) {
      if (scale === "thousands") {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `${measure}${(value / 1000).toLocaleString("en-GB", {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              })}k`;
            }

            return `${(value / 1000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}k${measure}`;
          }

          // measure === NULL
          return `${(value / 1000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}k`;
        }

        // thousandSeparators === false
        return !measure
          ? `${(value / 1000).toFixed(decimalPoints)}k`
          : ["$", "£", "€"].includes(measure)
          ? `${measure}${(value / 1000).toFixed(decimalPoints)}k`
          : `${(value / 1000).toFixed(decimalPoints)}k${measure}`;
      }

      if (scale === "millions") {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `${measure}${(value / 1_000_000).toLocaleString("en-GB", {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              })}mn`;
            }

            return `${(value / 1_000_000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}mn${measure}`;
          }

          // measure === NULL
          return `${(value / 1_000_000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}mn`;
        }

        // thousandSeparators === false
        return !measure
          ? `${(value / 1_000_000).toFixed(decimalPoints)}mn`
          : ["$", "£", "€"].includes(measure)
          ? `${measure}${(value / 1_000_000).toFixed(decimalPoints)}mn`
          : `${(value / 1_000_000).toFixed(decimalPoints)}mn${measure}`;
      }

      if (scale === "billions") {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `${measure}${(value / 1_000_000_000).toLocaleString(
                "en-GB",
                {
                  minimumFractionDigits: decimalPoints,
                  maximumFractionDigits: decimalPoints,
                }
              )}bn`;
            }

            return `${(value / 1_000_000_000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}bn${measure}`;
          }

          // measure === NULL
          return `${(value / 1_000_000_000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}bn`;
        }

        // thousandSeparators === false
        return !measure
          ? `${(value / 1_000_000_000).toFixed(decimalPoints)}bn`
          : ["$", "£", "€"].includes(measure)
          ? `${measure}${(value / 1_000_000_000).toFixed(decimalPoints)}bn`
          : `${(value / 1_000_000_000).toFixed(decimalPoints)}bn${measure}`;
      }

      if (!scale) {
        if (thousandSeparators) {
          if (measure) {
            if (["$", "£", "€"].includes(measure)) {
              return `${measure}${value.toLocaleString("en-GB", {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              })}`;
            }

            return `${value.toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}${measure}`;
          }

          // measure === NULL
          return `${value.toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}`;
        }

        // thousandSeparators === false
        return !measure
          ? `${value.toFixed(decimalPoints)}`
          : ["$", "£", "€"].includes(measure)
          ? `${measure}${value.toFixed(decimalPoints)}`
          : `${value.toFixed(decimalPoints)}${measure}`;
      }
    }

    if (scale === "thousands") {
      if (thousandSeparators) {
        if (measure) {
          if (["$", "£", "€"].includes(measure)) {
            return `${measure}${(value / 1000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}k`;
          }

          return `${(value / 1000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}k${measure}`;
        }

        // measure === NULL
        return `${(value / 1000).toLocaleString("en-GB", {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}k`;
      }

      // thousandSeparators === false
      return !measure
        ? `${(value / 1000).toFixed(decimalPoints)}k`
        : ["$", "£", "€"].includes(measure)
        ? `${measure}${(value / 1000).toFixed(decimalPoints)}k`
        : `${(value / 1000).toFixed(decimalPoints)}k${measure}`;
    }

    if (scale === "millions") {
      if (thousandSeparators) {
        if (measure) {
          if (["$", "£", "€"].includes(measure)) {
            return `${measure}${(value / 1_000_000).toLocaleString("en-GB", {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}mn`;
          }

          return `${(value / 1_000_000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}mn${measure}`;
        }

        // measure === NULL
        return `${(value / 1_000_000).toLocaleString("en-GB", {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}mn`;
      }

      // thousandSeparators === false
      return !measure
        ? `${(value / 1_000_000).toFixed(decimalPoints)}mn`
        : ["$", "£", "€"].includes(measure)
        ? `${measure}${(value / 1_000_000).toFixed(decimalPoints)}mn`
        : `${(value / 1_000_000).toFixed(decimalPoints)}mn${measure}`;
    }

    if (scale === "billions") {
      if (thousandSeparators) {
        if (measure) {
          if (["$", "£", "€"].includes(measure)) {
            return `${measure}${(value / 1_000_000_000).toLocaleString(
              "en-GB",
              {
                minimumFractionDigits: decimalPoints,
                maximumFractionDigits: decimalPoints,
              }
            )}bn`;
          }

          return `${(value / 1_000_000_000).toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}bn${measure}`;
        }

        // measure === NULL
        return `${(value / 1_000_000_000).toLocaleString("en-GB", {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}bn`;
      }

      // thousandSeparators === false
      return !measure
        ? `${(value / 1_000_000_000).toFixed(decimalPoints)}bn`
        : ["$", "£", "€"].includes(measure)
        ? `${measure}${(value / 1_000_000_000).toFixed(decimalPoints)}bn`
        : `${(value / 1_000_000_000).toFixed(decimalPoints)}bn${measure}`;
    }

    if (thousandSeparators) {
      if (measure) {
        if (["$", "£", "€"].includes(measure)) {
          return `${measure}${value.toLocaleString("en-GB", {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}`;
        }

        return `${value.toLocaleString("en-GB", {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}${measure}`;
      }

      // measure === NULL
      return value.toLocaleString("en-GB", {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      });
    }

    // thousandSeparators === false
    return !measure
      ? value.toFixed(decimalPoints)
      : ["$", "£", "€"].includes(measure)
      ? `${measure}${value.toFixed(decimalPoints)}`
      : `${value.toFixed(decimalPoints)}${measure}`;
  }

  return "";
};
