import React from "react";
import { List, ListItemButton, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router";

import { styles } from "./styles";

export const ModelLibrarySidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <List sx={{ p: 0 }}>
      <ListItemButton sx={[styles.button, styles.nonClickable]}>
        <Typography sx={styles.listTitle}>All models</Typography>
      </ListItemButton>
      <ListItemButton sx={styles.button} onClick={() => navigate("/library")}>
        <Typography
          sx={[styles.listTitle, styles.listItem(pathname === "/library")]}
        >
          Recommended
        </Typography>
      </ListItemButton>
      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/featured-models")}
        disabled
      >
        <Typography
          sx={[
            styles.listTitle,
            styles.listItem(pathname === "/featured-models"),
          ]}
        >
          Featured
        </Typography>
      </ListItemButton>
      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/recent-models")}
        disabled
      >
        <Typography
          sx={[styles.listTitle, styles.listItem(pathname === "recent-models")]}
        >
          Recent
        </Typography>
      </ListItemButton>

      <ListItemButton sx={[styles.button, styles.nonClickable]}>
        <Typography sx={styles.listTitle}>Browse by Function</Typography>
      </ListItemButton>

      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/financial-models")}
      >
        <Typography
          sx={[
            styles.listTitle,
            styles.listItem(pathname === "/financial-models"),
          ]}
        >
          Finance
        </Typography>
      </ListItemButton>
      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/product-models")}
      >
        <Typography
          sx={[
            styles.listTitle,
            styles.listItem(pathname === "/product-models"),
          ]}
        >
          Product
        </Typography>
      </ListItemButton>
      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/marketing-models")}
        disabled
      >
        <Typography
          sx={[
            styles.listTitle,
            styles.listItem(pathname === "/marketing-models"),
          ]}
        >
          Marketing
        </Typography>
      </ListItemButton>
      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/operational-models")}
        disabled
      >
        <Typography
          sx={[
            styles.listTitle,
            styles.listItem(pathname === "/operational-models"),
          ]}
        >
          Operations
        </Typography>
      </ListItemButton>
      <ListItemButton
        sx={styles.button}
        onClick={() => navigate("/rd-models")}
        disabled
      >
        <Typography
          sx={[styles.listTitle, styles.listItem(pathname === "/rd-models")]}
        >
          R&D
        </Typography>
      </ListItemButton>
    </List>
  );
};
