export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "85%",
    py: 5,
    px: 7,
    borderRadius: 5,
    bgcolor: "white.main",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: "red.error",
    },
  },
  breadcrumbs: {
    "& .MuiBreadcrumbs-ol": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .MuiBreadcrumbs-separator": {
      bottom: "50%",
    },
  },
  separator: {
    width: 32,
    height: 32,
    color: "primary.main",
  },
  modalName: {
    fontSize: 50,
    lineHeight: 0.7,
    color: "primary.main",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      textDecorationThickness: 2,
    },
  },
  blockName: {
    fontSize: 50,
    lineHeight: 0.7,
    color: "primary.main",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& input": {
        width: "100%",
        fontSize: 18,
        color: "black.main",
      },
      "& fieldset": {
        borderColor: "primary.main",
        boxShadow: 0,
      },
      "&:hover fieldset": {
        borderColor: "primary.main",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main",
      },
    },
  },
  contentSection: {
    display: "grid",
    gridTemplateColumns: "65% 1fr",
    height: "100%",
    columnGap: 1,
    overflow: "hidden",
  },
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 250px)",
    gridTemplateRows: 115,
    gap: 4,
    height: "100%",
    bgcolor: "white.main",
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: "5px",
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: "5px",
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: "5px",
    },
  },
  previewContainer: {
    position: "relative",
    height: "100%",
    border: "1px solid",
    borderRadius: "20px",
    borderColor: "grey.border",
    bgcolor: "white.main",
  },
  loader: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};
