export const styles = {
  content: {
    height: "100%",
    width: "100%",
    pl: 9,
    pt: 3,
    bgcolor: "white.main",
  },
  pageTitle: (breadcrumbs) => ({
    fontWeight: breadcrumbs === 1 ? 400 : 600,
    fontSize: 48,
    lineHeight: 1.125,
    color: "primary.main",
    cursor: breadcrumbs === 1 ? "pointer" : "initial",
    "&:hover": {
      textDecoration: breadcrumbs === 1 ? "underline" : "none",
      textDecorationThickness: 2,
    },
  }),
  separator: {
    width: 32,
    height: 32,
    color: "primary.main",
  },
  pageGrid: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "400px 1fr",
    columnGap: 7,
    overflow: "hidden",
  },
};
