import React from "react";
import PropTypes from "prop-types";
import { Box, Card, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";

import { ReactComponent as GraphSmall } from "../../assets/icons/3-year-chart.svg";
import { ReactComponent as GraphMedium } from "../../assets/icons/5-year-chart.svg";
import { ReactComponent as GraphLarge } from "../../assets/icons/line-chart.svg";

import { styles } from "./styles";

export const ModelLibraryCard = ({ model, index, onClick }) => {
  const getCardImage = (index) => {
    switch (index) {
      case 0:
        return (
          <SvgIcon
            component={GraphSmall}
            viewBox="0 0 53 71"
            sx={{ width: 53, height: 71, fill: "transparent" }}
          />
        );

      case 1:
        return (
          <SvgIcon
            component={GraphMedium}
            viewBox="0 0 86 84"
            sx={{ width: 86, height: 84, fill: "transparent" }}
          />
        );

      default:
        return (
          <SvgIcon
            component={GraphLarge}
            viewBox="0 0 108 68"
            sx={{ width: 108, height: 68, fill: "transparent" }}
          />
        );
    }
  };

  return (
    <Card sx={styles.card} onClick={onClick}>
      <Tooltip title={model?.name}>
        <Typography noWrap sx={styles.cardTitle}>
          {model?.name}
        </Typography>
      </Tooltip>

      <Stack direction="row" spacing={1}>
        <Box flex={1} alignSelf="center">
          <Typography sx={styles.cardSubtitle}>Description</Typography>
          <Typography sx={styles.cardDesc}>{model?.description}</Typography>
        </Box>

        {getCardImage(index)}
      </Stack>
    </Card>
  );
};

ModelLibraryCard.propTypes = {
  model: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};
