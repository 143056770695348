export const SCALE = [
  {
    name: "none",
  },
  {
    name: "thousands",
  },
  {
    name: "millions",
  },
  {
    name: "billions",
  },
];
