import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import ModeIcon from "@mui/icons-material/Mode";

import { styles } from "./styles";
import { apiService } from "../../../../services";

export const Description = ({ description, indicatorId }) => {
  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const [newDescription, setNewDescription] = useState(
    description ? description : ""
  );

  const { mutate } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["indicator", indicatorId]);
      },
    }
  );

  const switchEditModeOn = () => {
    setIsEditMode(true);
  };

  const changeDescription = (event) => {
    setNewDescription(event.currentTarget.value);
  };

  const submitNewDescription = (event) => {
    if (event.charCode === 13 && !!newDescription) {
      event.preventDefault();
      mutate({
        indicatorId,
        data: { help: newDescription },
      });
      setIsEditMode(false);
    }
  };

  const onBlur = () => {
    mutate({
      indicatorId,
      data: { help: newDescription },
    });
    setIsEditMode(false);
  };

  return (
    <Stack sx={styles.description}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography sx={styles.sectionTitle}>Description</Typography>
        <IconButton
          sx={styles.editIcon}
          onClick={switchEditModeOn}
          disabled={isEditMode}
        >
          <ModeIcon />
        </IconButton>
      </Stack>

      {isEditMode ? (
        <TextField
          value={newDescription}
          onChange={changeDescription}
          onKeyPress={submitNewDescription}
          onBlur={onBlur}
          onFocus={(event) => event.target.select()}
          fullWidth
          autoFocus={true}
          sx={styles.editInput}
        />
      ) : (
        <Typography sx={styles.descriptionText}>
          {description
            ? description
            : "No description. Please click 'Edit' icon and type your text"}
        </Typography>
      )}
    </Stack>
  );
};
