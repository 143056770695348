import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";

import { Help } from "./Help";
import { PropertiesSettings } from "./PropertiesSettings";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ChevronRightIcon />} {...props} />
))(({ theme }) => ({
  paddingInline: "0px",
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0.5),
    "& > p": {
      fontFamily: "Comfortaa",
      fontSize: "14px",
      color: "black",
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(0),
}));

export const CustomAccordion = ({ properties, dimensionsList }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      {properties?.map((property) => (
        <StyledAccordion
          key={property.id}
          expanded={expanded === property.id}
          onChange={handleChange(property.id)}
          disableGutters
          elevation={0}
          TransitionProps={{ unmountOnExit: true }}
        >
          <StyledAccordionSummary>
            <Typography>{property.name}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Stack spacing={2}>
              <Help property={property} />
              <PropertiesSettings
                property={property}
                dimensionsList={dimensionsList}
              />
            </Stack>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};
