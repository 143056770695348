import { InputAdornment, Modal, Stack, TextField } from "@mui/material";
import React, { useState, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { Button } from "../../../../components";
import { Switch } from "../Switch";

export const AddDimensionsModal = ({
  open,
  onClose,
  modelDimensions,
  selectedModelDimensions,
  onSelectModelDimensions,
}) => {
  const { blockId } = useParams();
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState("");

  const { mutate: addDimensionsToBlockMutation } = useMutation(
    ({ blockId, selectedModelDimensions }) =>
      apiService.addDimensionsToBlock(blockId, selectedModelDimensions),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["block", blockId]);
        onClose();
      },
    }
  );

  const filteredModelDimensions = useMemo(
    () =>
      modelDimensions?.filter((dimension) =>
        dimension?.name?.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [modelDimensions, searchValue]
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Stack sx={styles.modal}>
        <TextField
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={styles.searchIcon} />
              </InputAdornment>
            ),
          }}
          variant="standard"
          placeholder="Search dimensions"
        />

        <Stack sx={styles.switchBlock} spacing={1}>
          {filteredModelDimensions?.map((modelDimension) => (
            <Stack key={modelDimension?.id} direction="row" alignItems="center">
              <Switch
                key={modelDimension?.id}
                data={modelDimension}
                selectedDimensions={selectedModelDimensions?.dimension_id}
                onSelect={onSelectModelDimensions}
                isDimensionsModal
              />
            </Stack>
          ))}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          height={37}
        >
          <Button sx={styles.actionButton("cancel")} onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={styles.actionButton("apply")}
            onClick={() =>
              addDimensionsToBlockMutation({
                blockId,
                selectedModelDimensions,
              })
            }
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
