export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 891,
    p: "60px 80px",
    borderRadius: 5,
    bgcolor: "white.main",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
    mb: 4,
  },
  title: {
    fontSize: 30,
    lineHeight: 1.1,
    color: "primary.main",
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: "red.error",
    },
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 1.1,
    color: "primary.main",
  },
  desc: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 1.32,
  },
};
