import { TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { ToastMessage } from "../../../../components";
import { apiService } from "../../../../services";
import { formatValue } from "../../../../utils";
import { CollapseCalculationType } from "../CollapseCalculationType";
import { CollapseInputType } from "../CollapseInputType";
import { IndicatorNameCell } from "../IndicatorNameCell";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";
import { useDispatch } from "react-redux";
import { handleAddIndicatorLoader } from "../../../../Redux/IndicatorSlice";
export const IndicatorRow = ({
  indicator,
  index,
  indicatorDimensions,
  timeDimensionId,
  columns,
  isExpanded,
  onExpand,
  indicatorsList,
}) => {
  const { modelId, blockId } = useParams();
  const queryClient = useQueryClient();
  const [formatProps, setFormatProps] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setError] = useState({ isBlockError: false, message: "" });
  const [successMessage, setSuccessMessage] = useState([]);
  const scenarioId = useSelector(getSelectedScenario);
  const dispatch = useDispatch();

  useQuery(
    ["indicator", indicator.id],
    () => apiService.getIndicator(indicator.id),
    {
      enabled: !!indicator.id,
      onSuccess: (response) => {
        setFormatProps(response.data.data_format_properties);
         dispatch(handleAddIndicatorLoader(false));
        },
        onError: (error)=>{
        dispatch(handleAddIndicatorLoader(false));
      }
    }
  );

  const { data } = useQuery(
    ["blockValues", blockId, scenarioId],
    () =>
      apiService.getBlockValues(blockId, {
        params: { scenario_id: scenarioId },
      }),
    {
      onError: (error) => {
        setError({ isBlockError: true, message: error.response.data.message });
      },
      onSuccess: (response) => {
        queryClient.invalidateQueries(["favorites", modelId]);
        setSuccessMessage(response?.data?.warnings)
      },
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries(["blockValues", blockId, scenarioId]);
  }, [scenarioId, blockId]);


  const blockValues = data?.data?.data_values ?? {};

  return (
    <>
      <Draggable index={index} draggableId={String(indicator?.id)}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TableCell colSpan={columns.length + 1} sx={{ p: 0, border: 0 }}>
              <TableRow
                sx={styles.upperRow(
                  isExpanded,
                  indicator?.id,
                  snapshot.isDragging
                )}
              >
                <IndicatorNameCell
                  indicator={indicator}
                  isExpanded={isExpanded}
                  onExpand={onExpand}
                  setIsEdit={setIsEdit}
                />

                {new Array(columns.length).fill("").map((_, index) => (
                  <TableCell
                    key={index}
                    sx={styles.tableCell}
                    onClick={() => onExpand(indicator?.id, true)}
                  >
                    <Typography sx={styles.cellValue}>
                      {formatValue(
                        blockValues?.[indicator?.id]?.[columns[index]],
                        formatProps
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>

              <TableRow sx={styles.collapsedRow}>
                <TableCell colSpan="100%" sx={styles.collapseCell}>
                  {indicator?.type?.toLocaleLowerCase() === "input" ? (
                    <CollapseInputType
                      indicator={indicator}
                      isExpanded={isExpanded}
                      indicatorDimensions={indicatorDimensions}
                      timeDimensionId={timeDimensionId}
                      columns={columns}
                      formatProps={formatProps}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  ) : (
                    <CollapseCalculationType
                      indicator={indicator}
                      isExpanded={isExpanded}
                      indicatorsList={indicatorsList}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
      <ToastMessage
        open={isError.isBlockError}
        onClose={() => setError({ isBlockError: false, message: "" })}
        message={isError.message}
        alertTitle="Error"
        type="error"
      />
      {successMessage?.length>0 && 
      <ToastMessage
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        alertTitle="Warning"
        type="warning"
      />
}
    </>
  );
};
