import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Stack,
  SvgIcon,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { ReactComponent as DataIcon } from "../../assets/icons/dataIcon.svg";

import { styles } from "./styles";
import { useAuthContext, useThemeContext } from "../../context";
import { AddDataModal } from "../AddDataModal";
import { Logo } from "../../icons";

export const Header = () => {
  const { isAuth, onLogout } = useAuthContext();
  const { theme, onSelectTheme } = useThemeContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);

  const isDataIconShown =
    location.pathname !== "/" &&
    location.pathname !== "/user_info" &&
    location.pathname !== "/workspace" &&
    location.pathname !== "/planner";

  const changeTheme = () => {
    onSelectTheme(theme === "light" ? "dark" : "light");
  };

  const handleLogout = () => {
    onLogout();
  };

  const handleDataModalOpen = () => {
    setIsDataModalOpen(true);
  };

  const handleDataModalClose = () => {
    setIsDataModalOpen(false);
  };

  return (
    <>
      <AppBar position="fixed" sx={styles.appbar}>
        <NavLink to="/workspace">
          <Logo isSmall />
        </NavLink>

        {isAuth && (
          <Stack sx={styles.iconsContainer}>
            {/* {isDataIconShown ? (
              <IconButton onClick={handleDataModalOpen} sx={styles.iconButton}>
                <SvgIcon
                  component={DataIcon}
                  viewBox="0 0 83 78"
                  sx={styles.icon}
                />
              </IconButton>
            ) : null} */}

            {/* <Button
              startIcon={<GroupIcon />}
              variant="contained"
              sx={styles.shareIcon}
            >
              Share
            </Button> */}

            {/* <IconButton onClick={changeTheme} sx={styles.iconButton}>
              {theme === "light" ? (
                <Brightness7Icon sx={styles.icon} />
              ) : (
                <Brightness4Icon sx={styles.icon} />
              )}
            </IconButton> */}

            {/* <IconButton
              onClick={() => navigate("/user_info")}
              sx={styles.iconButton}
            >
              <Avatar sx={[styles.avatar, styles.icon]} />
            </IconButton> */}

            <IconButton onClick={handleLogout} sx={styles.iconButton}>
              <LogoutIcon sx={styles.icon} />
            </IconButton>
          </Stack>
        )}
      </AppBar>

      <AddDataModal open={isDataModalOpen} onClose={handleDataModalClose} />
    </>
  );
};
