export const styles = {
  switch: {
    width: 42,
    height: 26,
    padding: 0,
    mr: 1,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 0.25,
      transitionDuration: "275ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "white.main",
        "& + .MuiSwitch-track": {
          backgroundColor: "primary.main",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "primary.main",
        border: "6px solid",
        borderColor: "white.main",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "grey.main",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "grey.main",
      opacity: 1,
      transition: "all 275ms",
    },
    "& + .MuiFormControlLabel-label": {
      fontFamily: "Roboto",
      color: "black.main",
    },
  },
};
