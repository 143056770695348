export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 890,
    py: 7,
    px: 6,
    borderRadius: 5,
    bgcolor: "white.main",
  },
  modalTitle: {
    fontWeight: 300,
    fontSize: 40,
    lineHeight: 1.1,
    color: "primary.main",
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: "red.error",
    },
  },
  textField: (isError) => ({
    "& .MuiOutlinedInput-root": {
      // width: 420,
      height: 52,

      "& + .MuiFormHelperText-root": {
        position: "absolute",
        top: "100%",
      },

      "&:hover fieldset": {
        borderColor: isError ? "red.error" : "primary.main",
      },

      "& input": {
        fontSize: 18,
        lineHeight: 1.2,
        color: isError ? "red.error" : "black.main",
      },
    },
  }),
  checkbox: {
    alignSelf: "flex-start",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Roboto",
      fontSize: 22,
      lineHeight: 2.59,
      color: "black.main",
    },
    "& .MuiCheckbox-root": {
      color: "primary.main",
    },
    "& svg": {
      width: 40,
      height: 40,
    },
  },
  image: {
    width: 288,
    height: 345,
    fill: "transparent",
  },
};
