export const styles = {
  pivotItem: (listIndex, isDragging, draggableStyle) => ({
    height: 60,
    width: listIndex > 0 ? "auto" : 230,
    py: 2,
    px: 1,
    backgroundColor: isDragging ? "secondary.main" : "white.main",
    userSelect: "none",
    ...draggableStyle,
  }),
  dragIcon: (isDragging) => ({
    width: 32,
    height: 32,
    color: isDragging ? "white.main" : "grey.main",
  }),
  pivotItemName: (isDragging) => ({
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.22,
    color: isDragging ? "white.main" : "black.main",
  }),
  selectLabel: {
    "&.MuiFormLabel-root": {
      left: 0,
      top: 0,
      transform: "translate(14px, 9px) scale(1)",
    },
    "&.MuiFormLabel-root.Mui-focused": {
      left: 0,
      top: 0,
      transform: "translate(14px, -9px) scale(0.75)",
    },
    "&.MuiFormLabel-root.MuiFormLabel-filled": {
      left: 0,
      top: 0,
      transform: "translate(14px, -9px) scale(0.75)",
    },
  },
};
