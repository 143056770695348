import { useState } from "react";
import { Popover, List, ListItem, ListItemText } from "@mui/material";
import { connect } from "react-redux";
import {
  deleteScenarios,
  duplicateScenarios,
  fetchScenarios,
} from "../../../../../Redux/ScenarioSlice";
import { useParams } from "react-router-dom";
import { DeleteAlert, ToastMessage } from "../../../../../components";

const InfoPopover = ({
  anchorEl,
  onClose,
  onRename,
  menuScenerio,
  deleteScenario,
  getScenarios,
  duplicateScenarios,
  handleDeleteScenario,
}) => {
  const { modelId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const open = Boolean(anchorEl);
  const popoverId = open ? "info-popover" : undefined;
  const itemId = menuScenerio?.id;
  const deleteScenarioItem = async () => {
    // e.stopPropagation();
    await deleteScenario(itemId);
    await getScenarios(modelId);
    onClose();
  };
  const toggleDeleteAlert = () => {
    onClose();
    handleDeleteScenario();
    setIsDeleteAlertOpen((prevState) => !prevState);
  };
  const duplicateScenario = async (e) => {
    const requestBody = {
      scenarioId: menuScenerio?.id,
      data: {
        name: `${menuScenerio?.name} - Copy`,
      },
    };
    onClose();
    e.stopPropagation();
    await duplicateScenarios(requestBody);
    setSuccessMessage("Scenario have been Duplicated successfully");
    await getScenarios(modelId);
  };

  return (
    <>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "right",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            borderRadius: "11px",
          },
        }}
      >
        <div>
          <List>
            <ListItem button>
              <ListItemText
                primary="Rename"
                onClick={(e) => onRename(e, itemId)}
              />
            </ListItem>
            {!menuScenerio?.is_base && (
              <ListItem button>
                <ListItemText
                  primary="Delete"
                  // onClick={(e) => deleteScenarioItem(e, itemId)}
                  onClick={toggleDeleteAlert}
                />
              </ListItem>
            )}
            <ListItem button>
              <ListItemText
                onClick={(e) => duplicateScenario(e, itemId)}
                primary="Duplicate"
              />
            </ListItem>
          </List>
        </div>
      </Popover>
      <ToastMessage
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        alertTitle="Success"
        type="success"
      />
      <DeleteAlert
        type="model"
        data={menuScenerio}
        open={isDeleteAlertOpen}
        onClose={toggleDeleteAlert}
        onDelete={deleteScenarioItem}
      />
    </>
  );
};
const stateToProperty = (state) => {
  return {
    scenarioList: state.scenarioSlice?.scenarioList,
    scenarioListStatus: state.scenarioSlice?.scenarioListStatus,
  };
};
const mapDispatchToProperties = (dispatch) => {
  return {
    deleteScenario: (requestBody) => dispatch(deleteScenarios(requestBody)),
    getScenarios: (modelId) => dispatch(fetchScenarios(modelId)),
    duplicateScenarios: (data) => dispatch(duplicateScenarios(data)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(InfoPopover);
