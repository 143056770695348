import React from "react";
import { Formik, Form } from "formik";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Stack } from "@mui/material";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { USER_GOALS, USER_INDUSTRY, USER_ROLE } from "../../../../constants";
import { Button, Select } from "../../../../components";
import { userInfoSchema } from "../../../../validation";
import { apiService } from "../../../../services";

export const ProfileForm = () => {
  const navigate = useNavigate();
  const { mutate } = useMutation((data) => apiService.userInfo(data), {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        navigate("/workspace");
      }
    },
  });

  const initialValues = {
    role: "",
    industry: "",
    goals: "",
  };

  const handleSubmit = (values) => {
    mutate(values);
  };

  return (
    <Box alignSelf="flex-end" mr={5} justifySelf="center">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={userInfoSchema}
      >
        {({ values }) => (
          <Form>
            <Stack mb={10} spacing={3.5}>
              <Box height="70px" pb="15px">
                <Select
                  name="role"
                  label="Role"
                  value={values.role}
                  items={USER_ROLE}
                  required
                />
              </Box>
              <Box height="70px" pb="15px">
                <Select
                  name="industry"
                  label="Industry"
                  value={values.industry}
                  items={USER_INDUSTRY}
                  required
                />
              </Box>
              <Box height="70px" pb="15px">
                <Select
                  name="goals"
                  label="Goal"
                  value={values.goals}
                  items={USER_GOALS}
                  required
                />
              </Box>
            </Stack>
            <Button
              submit
              isLarge
              endIcon={<ChevronRightIcon />}
              variant="contained"
              bgcolor="secondary.main"
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
