import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { styles } from "./styles";

export const Header = ({ onClose }) => {
  return (
    <Stack direction="row" alignItems="center" spacing="auto">
      <Stack direction="row" spacing={1} alignItems="center">
        <PlaylistAddCheckIcon sx={styles.propertiesIcon} />
        <Typography sx={styles.headerTitle}>Indicator Properties</Typography>
      </Stack>

      <IconButton onClick={onClose} sx={styles.closeButton}>
        <CloseIcon sx={styles.closeButtonIcon} />
      </IconButton>
    </Stack>
  );
};
