import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import DehazeIcon from "@mui/icons-material/Dehaze";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import { styles } from "./styles";

export const ToggleViewButtons = ({ viewMode, onSelectView }) => {
  return (
    <ToggleButtonGroup
      exclusive
      value={viewMode}
      onChange={onSelectView}
      aria-label="view mode"
    >
      <ToggleButton
        value="grid"
        aria-label="block view"
        onClick={onSelectView}
        sx={styles.viewButton(viewMode === "grid")}
      >
        <GridViewIcon />
      </ToggleButton>
      <ToggleButton
        value="list"
        aria-label="list view"
        onClick={onSelectView}
        sx={styles.viewButton(viewMode === "list")}
      >
        <DehazeIcon />
      </ToggleButton>
      <ToggleButton
        value="tree"
        aria-label="tree view"
        onClick={onSelectView}
        sx={{
          ...styles.viewButton(viewMode === "tree"),
          pointerEvents: "none",
          opacity: 0.2,
        }}
      >
        <AccountTreeIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
