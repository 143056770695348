import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { styles } from "./styles";

export const Select = ({
  name,
  label,
  items,
  nativeLabel,
  handleSelectChange,
  value,
  ...props
}) => {
  if (!name) {
    return (
      <NativeSelect
        label={label}
        items={items}
        onChange={handleSelectChange}
        value={value}
        {...props}
      />
    );
  }
  return (
    <StyledSelect
      name={name}
      label={label}
      items={items}
      isValue={value}
      nativeLabel={nativeLabel}
      {...props}
    />
  );
};

const StyledSelect = ({
  name,
  label,
  items,
  nativeLabel,
  required,
  isValue,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();

  const [value, setValue] = useState("");
  if (isValue) {
    field.value = isValue;
  }

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const handleSelectChange = (event) => {
    setValue(event.target.value);
    handleChange(name);
  };

  const hasError = Boolean(meta.error && meta.touched);

  return (
    <Box sx={styles.root}>
      {!nativeLabel && label ? (
        <label htmlFor={name}>
          <Typography sx={styles.label}>
            {label}{" "}
            {required ? (
              <Typography component="span" sx={styles.required}>
                *
              </Typography>
            ) : null}
          </Typography>
        </label>
      ) : null}

      <FormControl id={name} variant="outlined" sx={styles.formControl}>
        {nativeLabel ? <InputLabel>{nativeLabel}</InputLabel> : null}
        <MuiSelect
          value={value}
          onChange={handleSelectChange}
          onBlur={handleChange(name)}
          size="small"
          sx={styles.select}
          {...props}
          IconComponent={() => <KeyboardArrowDownIcon sx={styles.arrowBtn} />}
          MenuProps={{ PaperProps: { sx: styles.dropdownStyle } }}
        >
          {items?.map(({ name }, key) => {
            return (
              <MenuItem key={key} value={name}>
                {name}
              </MenuItem>
            );
          })}
        </MuiSelect>
        {hasError ? <FormHelperText error>{meta.error}</FormHelperText> : null}
      </FormControl>
    </Box>
  );
};

const NativeSelect = ({
  label,
  items,
  handleSelectChange,
  value,
  ...props
}) => {
  return (
    <Box sx={styles.root}>
      <FormControl variant="outlined" sx={styles.formControl}>
        <InputLabel id="select-label" sx={{ ...styles.formControl, mb: 2 }}>
          {label}
        </InputLabel>
        <MuiSelect
          labelId="select-label"
          value={value}
          onChange={handleSelectChange}
          renderValue={(value) => `${value}`}
          {...props}
          IconComponent={() => <KeyboardArrowDownIcon sx={styles.arrowBtn} />}
          MenuProps={{ PaperProps: { sx: styles.dropdownStyle } }}
        >
          {items?.map(({ name }, key) => {
            return (
              <MenuItem key={key} value={name}>
                {name}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

Select.defaultProps = {
  items: [{ name: "None", value: "" }],
  value: "",
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  nativeLabel: PropTypes.bool,
  items: PropTypes.array,
  value: PropTypes.string,
  handleSelectChange: PropTypes.func,
};

NativeSelect.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  value: PropTypes.string,
  handleSelectChange: PropTypes.func,
};

StyledSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  nativeLabel: PropTypes.string,
  items: PropTypes.array,
  isValue: PropTypes.string,
  required: PropTypes.bool,
};
