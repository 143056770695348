export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    px: 2,
    pt: 6,
    pb: 4,
    borderRadius: 5,
    bgcolor: "white.main",
  },
  close: {
    position: "absolute",
    top: 5,
    right: 5,
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: "red.error",
    },
  },
  textField: {
    mb: 3,
    "& .MuiOutlinedInput-root": {
      borderRadius: 1,
      "& input": {
        fontFamily: "Roboto",
        fontSize: 18,
        letterSpacing: "-0.015em",
        color: "black.main",
      },
      "& fieldset": {
        borderColor: "primary.main",
        boxShadow: 0,
      },
      "&:hover fieldset": {
        borderColor: "primary.main",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main",
      },
    },
  },
  checkboxWithLabel: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Roboto",
      fontSize: 18,
      lineHeight: 1.4,
      letterSpacing: "-0.015em",
      color: "black.main",
    },
    "& .MuiCheckbox-root": {
      color: "primary.main",
    },
    "& svg": {
      width: 24,
      height: 24,
    },
  },
  submit: {
    mt: 2.5,
    color: "primary.main",
  },
};
