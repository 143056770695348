import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { FORMATS } from "../../../../constants";
import { FormatSettings } from "./FormatSettings";

export const FormatType = ({
  dataFormat,
  dataFormatProperties,
  indicatorId,
}) => {
  const queryClient = useQueryClient();
  const [isUseDefaultFormatting, setIsUseDefaultFormatting] = useState(true);

  const { mutate } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["indicator", indicatorId]);
      },
    }
  );

  const onChangeFormat = useCallback(
    (event) => {
      mutate({
        indicatorId,
        data: { data_format: event.target.value },
      });
    },
    [indicatorId, mutate]
  );

  const toggleDefaultFormatting = (event) => {
    setIsUseDefaultFormatting(event.target.checked);
  };

  return (
    <Stack mt={2.5} spacing={1}>
      <Typography sx={styles.sectionTitle}>Format</Typography>

      <Select
        labelId="format-select-label"
        id="format-select"
        value={dataFormat.toLowerCase()}
        onChange={onChangeFormat}
        sx={styles.select}
      >
        {FORMATS.map((format) => (
          <MenuItem key={format.name} value={format.value}>
            {format.name}
          </MenuItem>
        ))}
      </Select>

      <FormControlLabel
        control={
          <Checkbox
            checked={isUseDefaultFormatting}
            onChange={toggleDefaultFormatting}
            inputProps={{
              "aria-label": "toggle of default formatting properties",
            }}
          />
        }
        label="Use default formatting properties"
        sx={styles.checkboxWithLabel}
      />

      {!isUseDefaultFormatting && (
        <FormatSettings
          dataFormatProperties={dataFormatProperties}
          indicatorId={indicatorId}
        />
      )}
    </Stack>
  );
};
