export const styles = {
  card: {
    width: 380,
    height: 230,
    p: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: 50,
    height: 50,
    transition: "all 275ms",
    color: "primary.main",
    "&:hover": { transform: "scale(1.2)" },
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 22,
    lineHeight: 1.04,
    color: "primary.main",
  },
};
