import { lightTheme } from "../theme/light";
import { darkTheme } from "../theme/dark";

const themeMap = {
  light: lightTheme,
  dark: darkTheme,
};

export const getTheme = (theme) => {
  return themeMap[theme];
};
