export const styles = {
  emptyCell: {
    width: 350,
    height: 60,
    p: 0,
    borderRight: "1px solid",
    borderColor: "grey.main",
    cursor: "default",
  },
  nameCell: {
    width: 130,
    height: 60,
    p: 0,
    borderRight: "1px solid",
    borderColor: "grey.main",
    textAlign: "center",
    cursor: "default",
  },
  headerName: {
    fontFamily: "Comfortaa",
    color: "black.main",
  },
};
