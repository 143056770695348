export const FORMATS = [
  {
    name: "Number",
    value: "number",
  },
  {
    name: "Integer",
    value: "integer",
  },
  {
    name: "Percentage",
    value: "percentage",
  },
  {
    name: "Currency",
    value: "currency",
  },
];
