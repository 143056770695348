import { Stack, TableCell, Typography } from "@mui/material";
import React, { useCallback } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./styles";
import { TableCellInput } from "../TableCellInput";

export const TableBodyRawType = ({
  dimensionItem,
  selectedDimensionsQuantity,
  tableValues,
  onChangeTableValues,
  columns,
  indicator,
  formatProps,
  isEdit,
  setIsEdit,
}) => {
  const formattedValue = useCallback(
    (index) => {
      if (isEdit) {
        return null;
      }

      return (
        Number(tableValues[`${indicator?.id}-${columns[index]}`]?.value) || null
      );
    },
    [isEdit, tableValues, formatProps, indicator?.id, columns]
  );

  const handleEdit = () => {
    setIsEdit(true);
  };

  if (!selectedDimensionsQuantity) {
    return (
      <>
        <TableCell component="th" sx={styles.tableCell}>
          <Typography sx={styles.dimensionName}>{indicator?.name}</Typography>
        </TableCell>

        {new Array(columns?.length).fill("").map((_, index) => {
          return (
            <TableCell
              sx={styles.editableTableCell}
              key={index}
              onClick={handleEdit}
            >
              <EditIcon />
              <TableCellInput
                name={`value-${index}`}
                value={formattedValue(index)}
                onChange={onChangeTableValues(
                  indicator,
                  selectedDimensionsQuantity,
                  index
                )}
              />
            </TableCell>
          );
        })}
      </>
    );
  }

  return (
    <>
      <TableCell component="th" sx={styles.tableCell}>
        {selectedDimensionsQuantity === 1 ? (
          <Typography sx={styles.dimensionName}>
            {dimensionItem?.name}
          </Typography>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {dimensionItem?.map((item) => (
              <Typography key={item} sx={styles.dimensionName}>
                {item}
              </Typography>
            ))}
          </Stack>
        )}
      </TableCell>

      {new Array(columns?.length).fill("").map((_, index) => (
        <TableCell sx={styles.editableTableCell} key={index}>
          <EditIcon />
          <TableCellInput
            name={`value-${index}`}
            value={
              selectedDimensionsQuantity === 1
                ? tableValues[`${dimensionItem?.name}-${columns[index]}`]?.value
                : tableValues[`${dimensionItem?.join("-")}-${columns[index]}`]
                    ?.value
            }
            onChange={onChangeTableValues(
              dimensionItem,
              selectedDimensionsQuantity,
              index
            )}
          />
        </TableCell>
      ))}
    </>
  );
};
