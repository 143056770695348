import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";

import { styles } from "./styles";
import { ViewTabTable } from "../ViewTabTable";
import { PivotPanel } from "../PivotPanel";
import ScenarioBox from "./ScenarioComponent";

const defaultQuery = `{"rows": ["Indicators"],"columns": ["Time"],"filters":[]}`;

const ViewTab = ({ requiredBlock }) => {
  const { blockId } = useParams();
  const [queryString, setQueryString] = useState(defaultQuery);
  const [isPivotOpen, setIsPivotOpen] = useState(false);

  const toggleMenu = () => {
    setIsPivotOpen((prevState) => !prevState);
  };

  const updateQueryString = (newQueryString) => {
    setQueryString(newQueryString);
  };

  return (
    <Stack sx={{ height: "100%" }}>
        <Stack sx={styles.scenarioContainer}>
          <ScenarioBox />
        </Stack>
      <Stack sx={styles.pivotButtonContainer}>
        <IconButton onClick={toggleMenu} sx={styles.pivotButton}>
          <PivotTableChartIcon sx={styles.pivotButtonIcon} />
        </IconButton>
        <Typography sx={styles.pivotButtonText}>Pivot Table</Typography>
      </Stack>
      <ViewTabTable blockId={blockId} queryString={queryString} />
      <Drawer anchor="right" open={isPivotOpen} onClose={toggleMenu}>
        <PivotPanel
          blockId={blockId}
          onUpdateQuery={updateQueryString}
          blockDimensions={requiredBlock?.dimensions}
          onClose={toggleMenu}
        />
      </Drawer>
    </Stack>
  );
};
export default ViewTab;
