import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { Handle } from "react-flow-renderer";

import { styles } from "./styles";

export const PreviewNode = ({ data }) => {
  const inputHandlePos = "top";
  const outputHandlePos = "bottom";

  return (
    <div style={styles.indicatorNode}>
      <Handle
        type="target"
        position={inputHandlePos}
      />

      <Tooltip title={data.label}>
        <Typography noWrap sx={styles.indicatorLabel}>
          {data.label}
        </Typography>
      </Tooltip>

      <Tooltip title={data.block_name}>
        <Typography noWrap sx={styles.indicatorChip}>
          {data.block_name}
        </Typography>
      </Tooltip>

      <Handle
        type="source"
        position={outputHandlePos}
      />
    </div>
  );
};
