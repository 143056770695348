import { Box, Drawer, Typography } from "@mui/material";
import { useQuery } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { Loader } from "../../../../components";
import { Header } from "./Header";
import { Settings } from "./Settings";
import { DimensionProperties } from "./DimensionProperties";
import { useState } from "react";

export const DimensionPropertiesSidebar = ({
  open,
  onClose,
  dimension,
  dimensionsList,
}) => {
  let [isDimensionsFetched, setDimensionFetched] = useState(false);
  const { name, id, enable_start_end, driver_page, group_property_id } =
    dimension;
  const { data } = useQuery(
    ["dimension_properties", id],
    () => apiService.getDimensionProperties(id),
    {
      enabled: name.toLowerCase() !== "time",
      onSuccess: (response) => {
        setDimensionFetched(true);
      },
      onError: (response) => {
        setDimensionFetched(true);
      },
      onSettled: () => {
        setDimensionFetched(true);
      },
    }
  );
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={styles.sidebar}>
        {!isDimensionsFetched ? (
          <Loader sx={{ justifyContent: "center" }} />
        ) : !data ? (
          <>
            <Header onClose={onClose} />
            <Typography sx={styles.indicatorName}>{name}</Typography>
            <Settings
              isEnableStartEndDates={enable_start_end}
              isEnableDimensionAsDriverPage={driver_page}
              dimensionId={id}
              properties={data?.data.properties ? data?.data.properties : []}
              selectedGroupedByPropertyId={group_property_id}
            />
          </>
        ) : (
          <>
            <Header onClose={onClose} />
            <Typography sx={styles.indicatorName}>{name}</Typography>
            <Settings
              isEnableStartEndDates={enable_start_end}
              isEnableDimensionAsDriverPage={driver_page}
              dimensionId={id}
              properties={data?.data.properties}
              selectedGroupedByPropertyId={group_property_id}
            />
            <DimensionProperties
              properties={data?.data.properties}
              dimensionsList={dimensionsList}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};
