import AWS from 'aws-sdk'
AWS.config.region='us-east-1';
AWS.config.credentials=new AWS.CognitoIdentityCredentials({
  IdentityPoolId:'us-east-1:a327c876-9da3-4697-a766-64f19b7ec8db'
})
const s3=new AWS.S3({
  apiVersion:'2006-03-01',
  params:{Bucket:'blox-user-assets'}
})
 async function handleFileUpload(file) {
   const promise = new Promise((resolve, reject) => {
     const params = {
       Key: new Date().getTime() + "_" + file.name, // Name of the file
       Body: file, // File object
       ContentType: file.type, // MIME type of the file
       ACL: "public-read", // Permissions for the file
     };
     s3.upload(params, (err, data) => {
       if (err) {
         reject(err);
       } else {
         resolve(data.Location);
       }
     });
   });
   return promise;
 }
 function handleFileDelete(fileKey) {
   const promise = new Promise((resolve, reject) => {
     const params = {
       Key: fileKey, // Key of the file to delete
     };
     s3.deleteObject(params, (err, data) => {
       if (err) {
         reject(err);
       } else {
         resolve();
       }
     });
   });
   return promise;
 }
 async function replaceImage(existingKey, newFile) {
   await handleFileDelete(existingKey);
   const newUrl = await handleFileUpload(newFile);
   return newUrl;
 }
export {handleFileUpload,handleFileDelete,replaceImage}