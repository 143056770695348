import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ModeIcon from "@mui/icons-material/Mode";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";

export const Help = ({ property }) => {
  const { help, id, dimension_id } = property;

  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const [newDescription, setNewDescription] = useState(help ? help : "");

  const { mutate } = useMutation(
    ({ propertyId, data }) =>
      apiService.updateDimensionProperty(propertyId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dimension_properties", dimension_id]);
      },
    }
  );

  const switchEditModeOn = () => {
    setIsEditMode(true);
  };

  const changeDescription = (event) => {
    setNewDescription(event.currentTarget.value);
  };

  const submitNewDescription = (event) => {
    if (event.charCode === 13 && !!newDescription) {
      event.preventDefault();

      mutate({
        propertyId: id,
        data: { help: newDescription },
      });

      setIsEditMode(false);
    }
  };

  const onBlur = () => {
    mutate({
      propertyId: id,
      data: { help: newDescription },
    });

    setIsEditMode(false);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography sx={styles.sectionSubtitle}>Help</Typography>
        <IconButton
          sx={styles.editIcon}
          onClick={switchEditModeOn}
          disabled={isEditMode}
        >
          <ModeIcon />
        </IconButton>
      </Stack>

      {isEditMode ? (
        <TextField
          value={newDescription}
          onChange={changeDescription}
          onKeyPress={submitNewDescription}
          onBlur={onBlur}
          onFocus={(event) => event.target.select()}
          fullWidth
          autoFocus={true}
          sx={styles.editInput}
          multiline={true}
        />
      ) : (
        <Typography sx={styles.descriptionText}>
          {help
            ? help
            : "No description. Please click 'Edit' icon and type your text"}
        </Typography>
      )}
    </Stack>
  );
};
