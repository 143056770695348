import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import jigsaw from "../../../../assets/icons/jigsaw-lady.svg";

import { styles } from "./styles";

const Img = styled("img")({
  display: "block",
  height: "auto",
  maxWidth: "100%",
});

export const ProfileMedia = () => {
  return (
    <Box sx={styles.mediaContainer}>
      <Img alt="girl with puzzle" src={jigsaw} width={429} height={260} />
      <Typography sx={styles.text}>
        &bull; Timescales enable forecasting
      </Typography>
      <Typography sx={styles.text}>
        &bull; Specifying your goals streamlines you journey
      </Typography>
    </Box>
  );
};
