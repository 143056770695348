import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardActions,
  CardContent,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";

import { ReactComponent as BlockIcon } from "../../../../assets/icons/block-aux.svg";

import { styles } from "./styles";
import { Button } from "../../../../components";

export const LibrarySecondModalCard = ({
  block,
  onImport,
  onPreview,
  previewedBlockId,
}) => (
  <Card sx={styles.card}>
    <CardContent sx={{ p: 0 }}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <SvgIcon
          component={BlockIcon}
          viewBox="0 0 79 81"
          sx={{ width: 19, height: 19 }}
        />
        <Tooltip title={block?.name}>
          <Typography noWrap sx={styles.cardTitle}>
            {block?.name}
          </Typography>
        </Tooltip>
      </Stack>

      <Typography noWrap sx={styles.cardDescription}>
        {block?.description}
      </Typography>
    </CardContent>

    <CardActions sx={{ p: 0 }}>
      <Stack direction="row" spacing={1} height={25} ml="auto">
        <Button
          variant="outlined"
          onClick={onPreview}
          sx={[
            styles.button,
            styles.previewButton(previewedBlockId, block?.id),
          ]}
        >
          Preview
        </Button>
        <Button
          variant="outlined"
          onClick={onImport}
          sx={[styles.button, styles.importButton]}
        >
          Import
        </Button>
      </Stack>
    </CardActions>
  </Card>
);

LibrarySecondModalCard.propTypes = {
  block: PropTypes.object.isRequired,
  onImport: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  previewedBlockId: PropTypes.number,
};
