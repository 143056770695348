import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import NavigationSidebar from "../../components/NavigationSidebar";
import { apiService } from "../../services";
import { DimensionsList } from "./components";
import ItemList from "./components/ItemList";
import { styles } from "./styles";

export const Dimensions = () => {
  const navigate = useNavigate();
  const { modelId } = useParams();
  const [selectedId, setSelectedId] = useState(
    Number(localStorage.getItem("selectedId"))
  );

  const {
    data: model,
    isLoading: isModelLoading,
    refetch: refetchModel,
  } = useQuery(["model", modelId], () => apiService.getModel(modelId));

  const { data: dimensions, isLoading: isDimensionsLoading } = useQuery(
    ["dimensions", modelId],
    () => apiService.getDimensions(modelId)
  );

  return (
    <Stack height="100%" direction="row">
      <NavigationSidebar />

      <Stack sx={styles.content} spacing={8}>
        <Stack>
          {isModelLoading ? (
            <Typography sx={{ ...styles.pageTitle(1), color: "primary.main" }}>
              ...Loading
            </Typography>
          ) : (
            <Breadcrumbs separator={<NavigateNextIcon sx={styles.separator} />}>
              <Typography
                sx={styles.pageTitle(1)}
                onClick={() => navigate(`/model/${modelId}`)}
              >
                {model?.data?.name}
              </Typography>
              <Typography sx={styles.pageTitle(2)}>Dimensions</Typography>
            </Breadcrumbs>
          )}
        </Stack>
        <Box sx={styles.pageGrid}>
          <DimensionsList
            data={dimensions}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            isLoading={isDimensionsLoading}
            refetchModel={refetchModel}
          />
          <ItemList
            selectedId={selectedId}
            dimensions={dimensions?.data?.dimensions}
            model={model?.data}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
