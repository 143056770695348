import { Divider, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IndicatorPropertiesSidebar } from "../../pages/BlockDetailed/components/IndicatorPropertiesSidebar";

import { DeleteAlert } from "../DeleteAlert";
import { RenameModal } from "../RenameModal";
import { ModelProperiesModal } from "../ModelProperiesModal";

export const SettingsMenu = ({
  anchorEl,
  data,
  target,
  open,
  onClose,
  onDelete,
  onUpdate,
  onDuplicate,
}) => {
  const navigate = useNavigate();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isModelPropsModalOpen, setIsModelPropsModalOpen] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isPropertiesOpen, setIsPropertiesOpen] = useState(false);

  const toggleRenameModal = () => {
    setIsRenameModalOpen((prevState) => !prevState);
    onClose();
  };

  const toggleModelPropsModal = () => {
    setIsModelPropsModalOpen((prevState) => !prevState);
    onClose();
  };

  const toggleDeleteAlert = () => {
    setIsDeleteAlertOpen((prevState) => !prevState);
    onClose();
  };

  const togglePropertiesSidebar = () => {
    setIsPropertiesOpen((prevState) => !prevState);
    onClose();
  };

  const deleteIndicator = () => {
    onDelete(data?.id);
    onClose();
  };

  if (target === "indicator") {
    return (
      <>
        <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
          <MenuItem onClick={togglePropertiesSidebar}>Properties</MenuItem>
          <MenuItem onClick={deleteIndicator}>Delete</MenuItem>
        </Menu>

        <IndicatorPropertiesSidebar
          open={isPropertiesOpen}
          onClose={togglePropertiesSidebar}
          indicatorId={data.id}
        />
      </>
    );
  }

  if (target === "block") {
    return (
      <>
        <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
          <MenuItem
            onClick={() =>
              navigate(`/model/${data?.model_id}/block/${data?.id}`)
            }
          >
            Open
          </MenuItem>
          <MenuItem onClick={toggleRenameModal}>Edit</MenuItem>
          <MenuItem onClick={toggleDeleteAlert}>Delete</MenuItem>
        </Menu>

        <RenameModal
          title="Block"
          open={isRenameModalOpen}
          onClose={toggleRenameModal}
          data={data}
          onUpdate={onUpdate}
        />

        <DeleteAlert
          type="block"
          data={data}
          open={isDeleteAlertOpen}
          onClose={toggleDeleteAlert}
          onDelete={onDelete}
        />
      </>
    );
  }

  return (
    <>
      <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
        <MenuItem onClick={() => navigate(`/model/${data?.id}`)}>Open</MenuItem>
        <Divider sx={{ opacity: 0.8 }} />
        <MenuItem
          onClick={() =>
            onDuplicate({
              modelId: data?.id,
              modelName: { name: `${data?.name} - Copy` },
            })
          }
        >
          Duplicate
        </MenuItem>
        <Divider sx={{ opacity: 0.8 }} />
        <MenuItem onClick={toggleRenameModal}>Rename</MenuItem>
        <MenuItem onClick={toggleDeleteAlert}>Delete</MenuItem>
        <MenuItem onClick={toggleModelPropsModal}>Properties</MenuItem>
      </Menu>

      <RenameModal
        title="Model"
        data={data}
        open={isRenameModalOpen}
        onClose={toggleRenameModal}
        onUpdate={onUpdate}
      />

      <DeleteAlert
        type="model"
        data={data}
        open={isDeleteAlertOpen}
        onClose={toggleDeleteAlert}
        onDelete={onDelete}
      />

      <ModelProperiesModal
        data={data}
        open={isModelPropsModalOpen}
        onClose={toggleModelPropsModal}
      />
    </>
  );
};
