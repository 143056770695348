import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ConstructionIcon from "@mui/icons-material/Construction";
import WidgetsIcon from "@mui/icons-material/Widgets";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import { styles } from "./styles";
import { SidebarIcon } from "./SidebarIcon";

const NavigationSidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { modelId, blockId } = useParams();

  const goToHome = () => navigate("/workspace");
  // const goToLibrary = () => navigate("/library");
  const goToBuild = () => navigate(`/model/${modelId}`);
  const goToDimensions = () => navigate(`/model/${modelId}/dimensions`);
  const goToScenarios = () => navigate(`/model/${modelId}/scenario`);

  const isHomePage = pathname === "/workspace";
  // const isLibraryAllModelsPage = pathname === "/library";
  const isLibraryFinancialModelsPage = pathname === "/financial-models";
  const isLibraryProductModelsPage = pathname === "/product-models";
  const isModelPage = pathname === `/model/${modelId}`;
  const isBlockPage = pathname === `/model/${modelId}/block/${blockId}`;
  const isDimensionsPage = pathname === `/model/${modelId}/dimensions`;
  const isScenarioPage = pathname === `/model/${modelId}/scenario`;

  const isLibraryPage =
    // isLibraryAllModelsPage ||
    isLibraryFinancialModelsPage ||
    isLibraryProductModelsPage;

  const isModelOrBlockPage = isModelPage || isBlockPage;

  const isNotModelPages =
    !isModelPage && !isBlockPage && !isDimensionsPage && !isScenarioPage;

  const isNotHomeOrLibraryPages =
    !isHomePage &&
    // !isLibraryAllModelsPage &&
    !isLibraryFinancialModelsPage &&
    !isLibraryProductModelsPage;

  return (
    <Stack sx={styles.sidebar}>
      <SidebarIcon
        onClick={goToHome}
        isCurentPage={isHomePage}
        icon={<HomeIcon fontSize="large" />}
        helper="Home"
      />

      {/* {isNotModelPages ? (
        <SidebarIcon
          onClick={goToLibrary}
          isCurentPage={isLibraryPage}
          icon={<MenuBookIcon fontSize="large" />}
          helper="Library"
        />
      ) : null} */}

      {isNotHomeOrLibraryPages ? (
        <SidebarIcon
          onClick={goToBuild}
          isCurentPage={isModelOrBlockPage}
          icon={<ConstructionIcon fontSize="large" />}
          helper="Blocks"
        />
      ) : null}

      {isNotHomeOrLibraryPages ? (
        <SidebarIcon
          onClick={goToDimensions}
          isCurentPage={isDimensionsPage}
          icon={<WidgetsIcon fontSize="large" />}
          helper="Dimensions"
        />
      ) : null}
      {isNotHomeOrLibraryPages ? (
        <SidebarIcon
          onClick={goToScenarios}
          isCurentPage={isScenarioPage}
          icon={<QueryStatsIcon fontSize="large" />}
          helper="Scenarios"
        />
      ) : null}
    </Stack>
  );
};

export default NavigationSidebar;
