import React from "react";
import {  Stack, Typography } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styles } from "./styles";
import { LoginForm } from "./components";

export const Auth = () => {
  const isMobile = useMediaQuery("(max-width:810px)");

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography
        component="h1"
        sx={styles.title}
        paddingTop={isMobile ? "15px" : "0px"}
      >
        BloX
      </Typography>
      <Typography sx={styles.desc}>
        Business modelling for ambitious leaders
      </Typography>
      <Stack direction={isMobile ? "column" : "row"}>
        <LoginForm isMobile={isMobile} />
      </Stack>
    </Stack>
  );
};
