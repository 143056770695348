import { Stack, Typography } from "@mui/material";
import { CustomAccordion } from "./CustomAccordion";

import { styles } from "./styles";

export const DimensionProperties = ({ properties, dimensionsList }) => {
  return (
    <Stack mt={2.5} spacing={1}>
      <Typography sx={styles.sectionTitle}>Dimension Properties</Typography>

      <CustomAccordion
        properties={properties}
        dimensionsList={dimensionsList}
      />
    </Stack>
  );
};
