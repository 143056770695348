import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  useNodesState,
  useEdgesState,
} from "react-flow-renderer";
import dagre from "dagre";

import { styles } from "./styles";
import { palette } from "../../../../theme/palette";
import { MapIndicatorNode } from "../MapIndicatorNode";
import { MapControls } from "../MapControls";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 100;
const nodeHeight = 35;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  const layoutDirection = dagreGraph._label.rankdir;

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x + nodeWidth / 2,
      y: nodeWithPosition.y + nodeHeight,
    };

    return node;
  });

  return { nodes, edges, layoutDirection };
};

const nodeTypes = {
  indicator: MapIndicatorNode,
};

const nodeStrokeColor = (node) => {
  switch (node.type) {
    case "indicator":
      return palette.primary.main;
    default:
      return palette.secondary.main;
  }
};

export const Map = ({ initialNodes, initialEdges }) => {
  const [isDraggable, setIsDraggable] = useState(true);
  const [layoutDirection, setLayoutDirection] = useState("LR");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      initialNodes,
      initialEdges
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [initialEdges, initialNodes, setEdges, setNodes]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: "smoothstep", animated: false }, eds)
      ),
    [setEdges]
  );

  const onLayout = useCallback(
    (direction) => {
      const {
        nodes: layoutedNodes,
        edges: layoutedEdges,
        layoutDirection,
      } = getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      setLayoutDirection(layoutDirection);
    },
    [nodes, edges, setNodes, setEdges]
  );

  return (
    <ReactFlowProvider>
      <Stack sx={styles.mapContainer}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={isDraggable}
          nodeTypes={nodeTypes}
          defaultZoom={1.5}
        />
        <MiniMap nodeStrokeColor={nodeStrokeColor} nodeStrokeWidth={5} />
        <MapControls
          layoutDirection={layoutDirection}
          onLayout={onLayout}
          isDraggable={isDraggable}
          setIsDraggable={setIsDraggable}
        />
      </Stack>
    </ReactFlowProvider>
  );
};
