import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { CircleAddButton, ToastMessage } from "../../../../components";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";
import { apiService } from "../../../../services";
import ScenarioBox from "../../../BlockDetailed/components/ViewTab/ScenarioComponent";
import { Input } from "../Input";
import { PropertyElement } from "../PropertyElement";
import { styles } from "./styles";

const ItemList = ({ selectedId, dimensions, model }) => {
  const queryClient = useQueryClient();
  const [addItemClicked, setAddItemClicked] = useState(false);
  const [addPropertyClicked, setAddPropertyClicked] = useState(false);
  const [itemName, setItemName] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [itemId, setItemId] = useState(null);
  const scenarioId = useSelector(getSelectedScenario);
  const [errorMessage, setErrorMessage] = useState("");

  // DIMENSION ITEMS WITH PROPERTIES
  const { data: dimensionItemsWithProperties, refetch } = useQuery(
    ["dimension_items_with_properties", selectedId, scenarioId],
    () =>
      apiService.getDimensionItemsWithProperties(selectedId, {
        params: { scenario_id: scenarioId },
      })
  );

  // DIMENSION ITEMS
  const { mutate: addDimensionItemMutation } = useMutation(
    ({ dimensionId, newDimensionItem }) =>
      apiService.addDimensionItem(dimensionId, newDimensionItem),
    {
      onSuccess: (response) => {
        if (response?.status === 201) {
          refetch();
        }
      },
      onError: (error) => refetch(),
    }
  );

  const { mutate: updateDimensionItemMutation } = useMutation(
    ({ itemId, data }) => apiService.updateDimensionItem(itemId, data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          refetch();
        }
      },
    }
  );

  const { mutate: deleteDimensionItemMutation } = useMutation(
    (itemId) =>
      apiService.deleteDimensionItem(itemId, {
        params: { scenario_id: scenarioId },
      }),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          refetch();
        }
      },
    }
  );

  // DIMENSION ITEMS REORDER
  const { mutate: reorderDimensionItems } = useMutation(
    ({ id, data }) => apiService.dimensionReorderData(id, data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          refetch();
        }
      },
    }
  );

  const { mutate: reorderDimensionColumn } = useMutation(
    ({ id, data }) => apiService.dimensionReorderColumn(id, data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          refetch();
        }
      },
    }
  );

  const { mutate: reorderDimensionProperty } = useMutation(
    ({ id, data }) => apiService.dimensionPropertyReorderData(id, data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          refetch();
        }
      },
    }
  );

  // DIMENSION PROPERTIES
  const { data: dimensionProperties } = useQuery(
    ["dimension_properties", selectedId],
    () => apiService.getDimensionProperties(selectedId)
  );

  const { mutate: addDimensionPropertyMutation } = useMutation(
    ({ dimensionId, newDimensionProperty }) =>
      apiService.addDimensionProperty(dimensionId, newDimensionProperty),
    {
      onSuccess: (response) => {
        if (response?.status === 201) {
          queryClient.setQueryData(
            ["dimension_properties", selectedId],
            (oldQueryData) => {
              return {
                ...oldQueryData,
                data: {
                  properties: [
                    ...(oldQueryData?.data?.properties || []),
                    response?.data,
                  ],
                },
              };
            }
          );
        }
      },
    }
  );

  const { mutate: updateDimensionPropertyMutation } = useMutation(
    ({ propertyId, data }) =>
      apiService.updateDimensionProperty(propertyId, data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData(
            ["dimension_properties", selectedId],
            (oldQueryData) => {
              const updatedDimensionProperties =
                oldQueryData?.data?.properties?.map((property) => {
                  if (property?.id === response?.data?.id) {
                    return {
                      ...property,
                      name: response?.data?.name,
                    };
                  }
                  return property;
                });

              return {
                ...oldQueryData,
                data: {
                  properties: updatedDimensionProperties,
                },
              };
            }
          );
        }
      },
    }
  );

  const { mutate: deleteDimensionPropertyMutation } = useMutation(
    (propertyId) => apiService.deleteDimensionProperty(propertyId),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData(
            ["dimension_properties", selectedId],
            (oldQueryData) => {
              const filteredDimensionProperties =
                oldQueryData?.data?.properties?.filter(
                  (property) => property?.id !== response?.data?.id
                );

              return {
                ...oldQueryData,
                data: {
                  properties: filteredDimensionProperties,
                },
              };
            }
          );
        }
      },
    }
  );

  // DIMENSION ITEM PROPERTIES
  const { mutate: updateItemPropertyMutation } = useMutation(
    ({ itemId, data }) => {
      Object.assign(data, { scenario_id: scenarioId });
      return apiService
        .updateDimensionItemProperty(itemId, data)
        .then((response) => {
          if (response.status === 201) {
            refetch();
            queryClient.invalidateQueries(["item_properties", itemId]);
          }
        })
        .catch((error) => {
          setErrorMessage("Invalid data type.");
          refetch();
        });
    }
  );

  // Functions to ADD table elements
  const handleAddNewDimensionItem = (event) => {
    if (event.charCode === 13 && itemName?.trim().length !== 0) {
      const newDimensionItem = {
        name: itemName,
        scenario_id: scenarioId,
      };
      addDimensionItemMutation({ dimensionId: selectedId, newDimensionItem });
      setAddItemClicked(false);
      setItemName("");
    }
  };

  const handleAddNewDimensionProperty = (event) => {
    if (event.charCode === 13 && propertyName?.trim().length !== 0) {
      const newDimensionProperty = {
        name: propertyName,
      };
      addDimensionPropertyMutation({
        dimensionId: selectedId,
        newDimensionProperty,
      });
      setAddPropertyClicked(false);
      setPropertyName("");
    }
  };

  // AG GRID TABLE
  const DimensionItem = useCallback(
    (params) => {
      setItemId(params?.data?.id);
      return (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={styles.itemName}>{params?.value}</Typography>
          <IconButton
            aria-label="delete item"
            sx={styles.deleteButton}
            onClick={() => deleteDimensionItemMutation(params?.data?.id)}
          >
            <DeleteIcon fontSize="medium" />
          </IconButton>
        </Stack>
      );
    },
    [deleteDimensionItemMutation]
  );

  const DimensionProperty = useCallback(
    (params) => {
      return (
        <PropertyElement
          data={{
            name: params?.displayName,
            id: Number(params?.column?.colId),
          }}
          onDelete={deleteDimensionPropertyMutation}
          onUpdate={updateDimensionPropertyMutation}
        />
      );
    },
    [deleteDimensionPropertyMutation, updateDimensionPropertyMutation]
  );

  const onRowDragEnd = useCallback(
    (e) => {
      const reorderedItemIds = e?.api.rowModel.rowsToDisplay.map(
        ({ data: { id } }) => id
      );
      if (reorderedItemIds) {
        reorderDimensionItems({
          id: selectedId,
          data: {
            items: reorderedItemIds,
            scenario_id: scenarioId,
          },
        });
      }
    },
    [reorderDimensionItems, selectedId, scenarioId]
  );

  const handleColumnMoved = useCallback(
    (e) => {
      const reorderedPropertyIds = [];
      e.columnApi.getColumnState().forEach((c) => {
        if (c?.colId !== "name") {
          reorderedPropertyIds.push(Number(c.colId));
        }
      });
      if (reorderedPropertyIds) {
        reorderDimensionColumn({
          id: selectedId,
          data: {
            dim_item_prop: reorderedPropertyIds,
          },
        });
      }
    },
    [reorderDimensionColumn, selectedId]
  );

  const rowData =
    dimensionItemsWithProperties?.data &&
    Object?.entries(dimensionItemsWithProperties?.data)?.reduce((acc, item) => {
      const row = { id: Number(item[0]), ...item[1] };
      acc.push(row);
      return acc;
    }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      editable: true,
      onCellValueChanged: (params) => {
        params?.colDef?.headerName === "Name"
          ? updateDimensionItemMutation({
              itemId: params?.data?.id,
              data: { name: params?.newValue },
            })
          : updateItemPropertyMutation({
              itemId: params?.data?.id,
              data: {
                value: params?.newValue,
                property_id: params?.colDef?.id,
              },
            });
      },
    }),
    [updateDimensionItemMutation, updateItemPropertyMutation]
  );

  const defaultColumns = useMemo(
    () => [
      {
        width: 200,
        id: 0,
        headerName: "Name",
        field: "name",
        cellRendererFramework: DimensionItem,
        rowDrag: true,
        suppressMovable: true,
      },
    ],
    [DimensionItem]
  );

  const columns = useMemo(
    () =>
      dimensionProperties?.data?.properties?.reduce(
        (acc, property) => {
          const column = {
            width: 175,
            id: property?.id,
            headerName: property?.name,
            headerComponentFramework: DimensionProperty,
            field: `${property?.id}`,
            rowDrag: property?.name?.toLowerCase() === "name",
          };
          acc.push(column);
          return acc;
        },
        [...defaultColumns]
      ),
    [DimensionProperty, defaultColumns, dimensionProperties?.data?.properties]
  );

  const isRequiredModel = model?.dimensions?.some(
    (dimension) => dimension?.id === selectedId
  );

  return (
    <>
      <Stack sx={styles.itemSection}>
        <Typography sx={styles.sectionTitle}>Items</Typography>

        {isRequiredModel && selectedId && !!dimensions?.length ? (
          <>
            <Stack direction="row" mt={5} mb={3}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={5}
                width="50%"
              >
                <CircleAddButton
                  label="Add item"
                  icon="plus"
                  onClick={() => setAddItemClicked(true)}
                />
                {addItemClicked ? (
                  <Input
                    value={itemName}
                    placeholder="New item"
                    onChange={(e) => setItemName(e.currentTarget.value)}
                    onBlur={() => setAddItemClicked(false)}
                    onKeyPress={handleAddNewDimensionItem}
                  />
                ) : null}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                spacing={5}
                width="50%"
              >
                <CircleAddButton
                  label="Add property"
                  icon="plus"
                  onClick={() => setAddPropertyClicked(true)}
                />
                {addPropertyClicked ? (
                  <Input
                    value={propertyName}
                    placeholder="New property"
                    onChange={(e) => setPropertyName(e.currentTarget.value)}
                    onBlur={() => setAddPropertyClicked(false)}
                    onKeyPress={handleAddNewDimensionProperty}
                  />
                ) : null}
              </Stack>
            </Stack>
              <Stack sx={styles.scenarioContainer}>
                <ScenarioBox />
              </Stack>
            <Box className="ag-theme-alpine" sx={styles.tableContainer}>
              <AgGridReact
                reactUi="true"
                rowData={rowData}
                defaultColDef={defaultColDef}
                columnDefs={
                  dimensionProperties?.data?.properties
                    ? columns
                    : defaultColumns
                }
                rowDragManaged={true}
                enableColumnMoving={true}
                animateRows
                onRowDragEnd={onRowDragEnd}
                onColumnMoved={handleColumnMoved}
              />
            </Box>
          </>
        ) : null}
      </Stack>
      <ToastMessage
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        alertTitle="Error"
        type="error"
      />
    </>
  );
};
export default ItemList;
