import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Select,
  Stack,
  MenuItem,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { useParams } from "react-router-dom";

export const Settings = ({
  isEnableStartEndDates,
  isEnableDimensionAsDriverPage,
  dimensionId,
  properties,
  selectedGroupedByPropertyId,
}) => {
  const { modelId } = useParams();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    ({ dimensionId, data }) => apiService.updateDimension(dimensionId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dimensions", modelId]);
      },
    }
  );

  const toggleEnableStartEndDates = (event) => {
    mutate({
      dimensionId,
      data: { enable_start_end: event.target.checked.toString() },
    });
  };

  const toggleEnableDimensionAsDriverPage = (event) => {
    mutate({
      dimensionId,
      data: { driver_page: event.target.checked.toString() },
    });
  };

  const onChangeGroupBy = (event) => {
    mutate({
      dimensionId,
      data: { group_property_id: event.target.value },
    });
  };

  return (
    <Box mt={2.5}>
      <Typography sx={styles.sectionTitle}>Settings</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={isEnableStartEndDates}
            onChange={toggleEnableStartEndDates}
            inputProps={{
              "aria-label": "toggle of enable start and end dates",
            }}
          />
        }
        label="Enable start and end dates"
        sx={styles.checkboxWithLabel}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isEnableDimensionAsDriverPage}
            onChange={toggleEnableDimensionAsDriverPage}
            inputProps={{
              "aria-label": "toggle to show dimension as driver page",
            }}
          />
        }
        label="Display dimension as driver page?"
        sx={styles.checkboxWithLabel}
      />
      <Stack direction="row" spacing="24px" alignItems="center">
        <Typography sx={styles.secondryFormLabel}>Grouped by:</Typography>
        <Select
          labelId="type-select-label"
          id="type-select"
          value={selectedGroupedByPropertyId}
          onChange={onChangeGroupBy}
          sx={styles.formatField}
          placeholder="Select property"
        >
          {properties.map((property) => {
            if (property?.type === "dimension") {
              return (
                <MenuItem key={property.id} value={property.id}>
                  {property.name}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </Stack>
    </Box>
  );
};
