
export const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    width: 296,
    height: 402,
    border: "1px solid",
    borderRadius: 6,
    borderColor: "primary.main",
  },
  iconWrapper: {
    justifyContent: "center",
    alignItems: "center",
    height: 155,
    cursor: "pointer",
    backgroundImage: "linear-gradient(180deg, #7163d0 0%, #936bf6 100%)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  blockIcon: { width: 102, height: 96, fill: "transparent" },
  cardContent: { flex: 1, p: 3, justifyContent: "space-between" },
  mainText: {
    fontSize: 23,
    lineHeight: 1.13,
    letterSpacing: "-0.03em",
    color: "black.main",
  },
  auxText: {
    fontSize: 21,
    lineHeight: 1.13,
    letterSpacing: "-0.03em",
    color: "black.main",
  },
  avatar: {
    bgcolor: "secondary.main",
    width: 34,
    height: 34,
  },
  dimensionChip: {
    height: 20,
    alignItems: "center",
    borderRadius: "8px",
    fontSize: 11,
    color: "white.main",
    bgcolor: "primary.main",
  },
};
