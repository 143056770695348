import { TableCell, Typography } from "@mui/material";
import React from "react";

import { styles } from "./styles";

export const CollapseTableHeader = ({ selectedInputType }) => {
  if (selectedInputType === "growth") {
    return (
      <>
        <TableCell sx={styles.emptyCell} />
        <TableCell sx={styles.nameCell}>
          <Typography>Start value</Typography>
        </TableCell>
        <TableCell sx={styles.nameCell}>
          <Typography sx={styles.headerName}>Growth rate %</Typography>
        </TableCell>
      </>
    );
  }

  if (selectedInputType === "constant") {
    return (
      <>
        <TableCell sx={styles.emptyCell} />
        <TableCell sx={styles.nameCell}>
          <Typography x={styles.headerName}>Value</Typography>
        </TableCell>
      </>
    );
  }

  return null;
};
