import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";

import { styles } from "./styles";
import { Switch } from "../Switch";
import { EntryTypeSelector } from "../EntryTypeSelector";
import { Slider } from "../../../../components";

export const CollapseControlRow = ({
  indicatorDimensions,
  onSelectIndicatorDimension,
  selectedIndicatorDimensions,
  columns,
  entryType,
  onSelectEntryType,
  onSubmitTableValues,
}) => (
  <TableRow sx={{ height: 60 }}>
    <TableCell sx={styles.tableCell("switch")}>
      <Slider slidesToShow={2} slidesToScroll={2} isIndicatorDimensions>
        {indicatorDimensions?.map((indicatorDimension) => (
          <Switch
            key={indicatorDimension?.id}
            data={indicatorDimension}
            selectedDimensions={selectedIndicatorDimensions}
            onSelect={onSelectIndicatorDimension}
          />
        ))}
      </Slider>
    </TableCell>

    <TableCell sx={styles.tableCell("save", columns?.length)}>
      <Stack direction="row" spacing={4} height={40}>
        <EntryTypeSelector
          entryType={entryType}
          onSelectEntryType={onSelectEntryType}
        />

        <Tooltip title="Save data">
          <IconButton onClick={onSubmitTableValues} sx={styles.saveButton}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </TableCell>
  </TableRow>
);
