import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../services";

export const FetchModel = createAsyncThunk(
  "fetchModel/get",
  async ({ modelId }, thunkAPI) => {
    try {
      const response = await apiService.getModel(modelId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);
export const SyncModel = createAsyncThunk(
  "syncModel/get",
  async ({ modelId }, thunkAPI) => {
    try {
      const response = await apiService.getSyncModels(modelId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const FetchModelBlocks = createAsyncThunk(
  "fetchModelBlocks/get",
  async ({ modelId }, thunkAPI) => {
    try {
      const response = await apiService.getBlocks(modelId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const DeleteModelBlocks = createAsyncThunk(
  "deleteModelBlocks/get",
  async ({ blockId }, thunkAPI) => {
    try {
      const response = await apiService.deleteBlock(blockId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const AddModelBlocks = createAsyncThunk(
  "addModelBlocks/get",
  async ({ modelId, newCreatedBlock }, thunkAPI) => {
    try {
      const response = await apiService.addBlock(modelId, newCreatedBlock);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const EditModelBlocks = createAsyncThunk(
  "editModelBlocks/get",
  async ({ id, newName }, thunkAPI) => {
    try {
      const response = await apiService.updateBlock(id, newName);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  models: "",
  blocks: "",
};
const ModelsSlice = createSlice({
  name: "modelsSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(FetchModel.fulfilled, (state, action) => {
      state.models = action.payload;
    });
    builder.addCase(FetchModelBlocks.fulfilled, (state, action) => {
      state.blocks = action.payload;
    });
    builder.addCase(AddModelBlocks.fulfilled, (state, action) => {
      state.blocks = action.payload;
    });
  },
});

export const getBlocks = (state) => state.modelsSlice.blocks;
export const getModels = (state) => state.modelsSlice.models;

export default ModelsSlice.reducer;
