export const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  label: {
    lineHeight: 1.33,
  },
  required: {
    color: "red.error",
  },
  formControl: {
    width: 255,
  },
  select: {
    "&.MuiOutlinedInput-root": {
      backgroundColor: "white.main",
      borderRadius: 2,
      height: 40,
      "& input": {
        fontFamily: "Roboto",
        fontSize: 18,
        letterSpacing: "-0.015em",
        color: "black.main",
      },
      "& fieldset": {
        borderColor: "primary.main",
        boxShadow: 0,
      },
      "&:hover fieldset": {
        borderColor: "primary.main",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main",
      },
    },
  },
  arrowBtn: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "primary.main",
  },
  dropdownStyle: {
    border: "1px solid",
    borderColor: "primary.main",
    borderRadius: 2,
    marginTop: 0.5,
    "& ul": {
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
      backgroundColor: "white.main",
    },
    "& li": {
      padding: "3px 15px",
      color: "black.main",
      fontSize: 15,
      backgroundColor: "white.main",
      "&:hover": {
        bgcolor: "secondary.main",
      },
    },
  },
};
