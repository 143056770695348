import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../../context";

export const RequireAuth = ({ children }) => {
  const { isAuth } = useAuthContext();
  const location = useLocation();

  return isAuth ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
