export const styles = {
  content: {
    height: "100%",
    width: "100%",
    pl: 9,
    pt: 3,
    bgcolor: "white.main",
  },
  pageTitle: (breadcrumbs) => ({
    fontWeight: breadcrumbs === 1 ? 400 : 600,
    fontSize: 48,
    lineHeight: 1.125,
    color: "primary.main",
  }),
  separator: {
    width: 32,
    height: 32,
    color: "primary.main",
  },
  scenarioText: {
    fontFamily: "Comfortaa",
    fontSize: 30,
    fontWeight: 400,
    color: "black.main",
  },
  tableCell: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "17px",
    color: "black.main",
  },
  tableRow: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "black.main",
  },
  addScenarioText: {
    // padding: "1rem",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "15px",
    color: "#7257CB",
    cursor: "pointer",
    margin: "2rem 1rem 3rem 0 !important",
    width: "11%",
  },
  scroll: {
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
};
