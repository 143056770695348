import { Box, Drawer, Typography } from "@mui/material";
import { useQuery } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { Loader } from "../../../../components";
import { Header } from "./Header";
import { Description } from "./Description";
import { IndicatorType } from "./IndicatorType";
import { DataType } from "./DataType";
import { ChartType } from "./ChartType";
import { FormatType } from "./FormatType";
import { OutputFormat } from "./OutputFormat";
import { DefaultIndicator } from "./DefaultIndicator";

export const IndicatorPropertiesSidebar = ({ open, onClose, indicatorId }) => {
  const { data, isLoading } = useQuery(
    ["indicator", indicatorId],
    () => apiService.getIndicator(indicatorId),
    {
      enabled: open,
    }
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={styles.sidebar}>
        {isLoading ? (
          <Loader sx={{ justifyContent: "center" }} />
        ) : (
          <>
            <Header onClose={onClose} />
            <Typography sx={styles.indicatorName}>{data?.data.name}</Typography>
            <Description
              description={data?.data.help}
              indicatorId={indicatorId}
            />
            <IndicatorType type={data?.data.type} indicatorId={indicatorId} />
            <DefaultIndicator data={data?.data} indicatorId={indicatorId}/>
            <DataType
              dataType={data?.data.data_type}
              indicatorId={indicatorId}
            />
            <ChartType
              chartType={data?.data.chart_type}
              indicatorId={indicatorId}
            />
            <FormatType
              dataFormat={data?.data.data_format}
              dataFormatProperties={data?.data.data_format_properties}
              indicatorId={indicatorId}
            />
            <OutputFormat
              outputFormat={data?.data?.output_format}
              indicatorId={indicatorId}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};
