export const styles = {
  desc: {
    mt: 4,
    mb: 7,
    fontFamily: "Roboto",
    lineHeight: 1.12,
    fontSize: 30,
    color: "black.main",
  },
  subtitle: {
    mb: 5,
    fontSize: 40,
    lineHeight: 1.12,
    color: "black.main",
  },
};
