import { Collapse, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { ToastMessage } from "../../../../components";
import { CollapseControlRow } from "../CollapseControlRow";
import { CollapseTableHeader } from "../CollapseTableHeader";
import { TableBodyRawType } from "../TableBodyRawType";
import { TableBodyConstantType } from "../TableBodyConstantType";
import { TableBodyGrowthType } from "../TableBodyGrowthType";
import { useSelector } from "react-redux";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";

const getDimensionsCombinations = (list) => {
  const dimensionItems = [];
  const max = list.length - 1;
  function helper(array, index) {
    for (let j = 0, l = list[index]?.length; j < l; j++) {
      const arrayCopied = [...array];
      arrayCopied.push(list[index][j]);
      if (index === max) dimensionItems.push(arrayCopied);
      else helper(arrayCopied, index + 1);
    }
  }
  helper([], 0);
  return dimensionItems;
};

export const CollapseInputType = ({
  indicator,
  isExpanded,
  indicatorDimensions,
  timeDimensionId,
  columns,
  formatProps,
  isEdit,
  setIsEdit,
}) => {
  const { blockId } = useParams();
  const queryClient = useQueryClient();
  const [entryType, setEntryType] = useState("raw");
  const [selectedIndicatorDimensions, setSelectedIndicatorDimensions] =
    useState([]);
  const [rawTableValues, setRawTableValues] = useState({});
  const [growthTableValues, setGrowthTableValues] = useState({});
  const [constantTableValues, setConstantTableValues] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [prevMeasure, setPrevMeasure] = useState(null);
  const [isPrevInit, setIsPrevInit] = useState(false);
  const scenarioId = useSelector(getSelectedScenario);

  const { mutate: overwriteIndicatorInputMutation } = useMutation(
    ({ indicatorId, data }) =>
      apiService.overwriteIndicatorInput(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["indicatorInput", indicator?.id]);
        queryClient.invalidateQueries(["blockValues", blockId]);
        setSuccessMessage("The data have been submitted successfully");
      },
    }
  );

  useQuery(
    ["indicatorInput", indicator?.id, scenarioId],
    () =>
      apiService.getIndicatorInput(indicator?.id, {
        params: { scenario_id: scenarioId },
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if (response?.data) {
          const metaData = response.data;

          switch (metaData.type) {
            case "growth":
              const modifiedGrowthValues = metaData.data_values?.reduce(
                (acc, row) => {
                  if (metaData.dimensions?.length === 0) {
                    acc[metaData.indicator_id] = row;
                  } else if (metaData.dimensions?.length === 1) {
                    acc[row[metaData.dimensions?.[0]?.id]] = row;
                  } else {
                    let createdKey = "";
                    metaData.dimensions.forEach((dimension, index) => {
                      if (index < metaData.dimensions.length - 1) {
                        createdKey = createdKey + `${row[dimension?.id]}-`;
                      } else {
                        createdKey = createdKey + `${row[dimension?.id]}`;
                      }
                    });
                    acc[`${createdKey}`] = row;
                  }
                  return acc;
                },
                {}
              );
              setGrowthTableValues(modifiedGrowthValues);
              break;

            case "constant":
              const modifiedConstantValues = metaData.data_values?.reduce(
                (acc, row) => {
                  if (metaData.dimensions?.length === 0) {
                    if (formatProps?.measure === "%") {
                      acc[metaData.indicator_id] = {
                        ...row,
                        value: `${Number(row.value)}`,
                      };
                    } else {
                      acc[metaData.indicator_id] = row;
                    }
                  } else if (metaData.dimensions?.length === 1) {
                    acc[row[metaData.dimensions?.[0]?.id]] = row;
                  } else {
                    let createdKey = "";
                    metaData.dimensions.forEach((dimension, index) => {
                      if (index < metaData.dimensions.length - 1) {
                        createdKey = createdKey + `${row[dimension?.id]}-`;
                      } else {
                        createdKey = createdKey + `${row[dimension?.id]}`;
                      }
                    });
                    acc[`${createdKey}`] = row;
                  }
                  return acc;
                },
                {}
              );
              setConstantTableValues(modifiedConstantValues);
              break;

            default:
              const modifiedRawValues = metaData.data_values?.reduce(
                (acc, row) => {
                  if (metaData.dimensions?.length === 0) {
                    if (formatProps?.measure === "%") {
                      acc[`${metaData.indicator_id}-${row[timeDimensionId]}`] =
                        { ...row, value: `${Number(row.value)}` };
                    } else {
                      acc[`${metaData.indicator_id}-${row[timeDimensionId]}`] =
                        row;
                    }
                  } else if (metaData.dimensions?.length === 1) {
                    acc[
                      `${row[metaData.dimensions?.[0]?.id]}-${
                        row[timeDimensionId]
                      }`
                    ] = row;
                  } else {
                    let createdKey = "";
                    metaData.dimensions.forEach((dimension, index) => {
                      if (index < metaData.dimensions.length - 1) {
                        createdKey = createdKey + `${row[dimension?.id]}-`;
                      } else {
                        createdKey = createdKey + `${row[dimension?.id]}`;
                      }
                    });
                    createdKey = createdKey + "-" + `${row[timeDimensionId]}`;
                    acc[`${createdKey}`] = row;
                  }
                  return acc;
                },
                {}
              );
              setRawTableValues(modifiedRawValues);
              break;
          }

          setEntryType(metaData.type);
          setSelectedIndicatorDimensions(metaData.dimensions);
        }
      },
    }
  );

  useEffect(() => {
    if (formatProps && !isPrevInit) {
      if (formatProps.measure && !prevMeasure) {
        setPrevMeasure(formatProps?.measure);
        setIsPrevInit(true);
      }
    }
  }, [formatProps, prevMeasure, isPrevInit]);

  const itemsQueries = useQueries(
    selectedIndicatorDimensions?.map((obj) => ({
      queryKey: ["dimensionItems", obj.id, scenarioId],
      queryFn: () =>
        apiService.getDimensionItems(obj?.id, {
          params: { scenario_id: scenarioId },
        }),
    }))
  );
  useEffect(() => {
    queryClient.invalidateQueries([
      "indicatorInput",
      indicator?.id,
      scenarioId,
    ]);
    itemsQueries.forEach((query, index) => {
      queryClient.invalidateQueries([
        "dimensionItems",
        selectedIndicatorDimensions[index]?.id,
        scenarioId,
      ]);
    });
  }, [scenarioId]);

  const getDimensionItems = (queriesArray) => {
    if (queriesArray?.length === 1) {
      return queriesArray?.[0]?.data?.data?.items;
    }

    if (queriesArray?.length >= 2) {
      const modifiedArray = queriesArray?.reduce((acc, current) => {
        const items = current?.data?.data?.items?.map((item) => item.name);
        acc.push(items);
        return acc;
      }, []);

      return getDimensionsCombinations(modifiedArray);
    }

    return null;
  };

  const changeTableValues = useCallback(
    (item, selectedDimensionsQuantity, index) => (event) => {
      const { name, value } = event.currentTarget;
      const year = columns[index];
      switch (entryType) {
        case "growth":
          if (!selectedDimensionsQuantity) {
            setGrowthTableValues((prevState) => {
              const rowValues = {
                ...prevState[item?.id],
                [name]: value,
              };
              return {
                ...prevState,
                [item?.id]: rowValues,
              };
            });
          } else if (selectedDimensionsQuantity === 1) {
            setGrowthTableValues((prevState) => {
              const rowValues = {
                ...prevState[item?.name],
                [selectedIndicatorDimensions?.[0]?.id]: item?.name,
                [name]: value,
              };
              return {
                ...prevState,
                [item?.name]: rowValues,
              };
            });
          } else {
            setGrowthTableValues((prevState) => {
              let data = {};
              selectedIndicatorDimensions.forEach((dimension, index) => {
                data[selectedIndicatorDimensions?.[index]?.id] = item[index];
              });
              const rowValues = {
                ...prevState[item?.join("-")],
                ...data,
                [name]: value,
              };
              return {
                ...prevState,
                [item?.join("-")]: rowValues,
              };
            });
          }
          break;

        case "constant":
          if (!selectedDimensionsQuantity) {
            setConstantTableValues((prevState) => {
              const rowValues = {
                ...prevState[item?.id],
                [name]: value,
              };
              return {
                ...prevState,
                [item?.id]: rowValues,
              };
            });
          } else if (selectedDimensionsQuantity === 1) {
            setConstantTableValues((prevState) => {
              const rowValues = {
                ...prevState[item?.name],
                [selectedIndicatorDimensions?.[0]?.id]: item?.name,
                [name]: value,
              };
              return {
                ...prevState,
                [item?.name]: rowValues,
              };
            });
          } else {
            setConstantTableValues((prevState) => {
              let data = {};
              selectedIndicatorDimensions.forEach((dimension, index) => {
                data[selectedIndicatorDimensions?.[index]?.id] = item[index];
              });
              const rowValues = {
                ...prevState[item?.join("-")],
                ...data,
                [name]: value,
              };
              return {
                ...prevState,
                [item?.join("-")]: rowValues,
              };
            });
          }
          break;

        default:
          if (!selectedDimensionsQuantity) {
            setRawTableValues((prevState) => {
              const cellValue = {
                ...prevState[`${item?.id}-${year}`],
                [timeDimensionId]: year,
                [name.slice(0, 5)]: value,
              };
              return {
                ...prevState,
                [`${item?.id}-${year}`]: cellValue,
              };
            });
          } else if (selectedDimensionsQuantity === 1) {
            setRawTableValues((prevState) => {
              const cellValue = {
                ...prevState[`${item?.name}-${year}`],
                [timeDimensionId]: year,
                [selectedIndicatorDimensions?.[0]?.id]: item?.name,
                [name.slice(0, 5)]: value,
              };
              return {
                ...prevState,
                [`${item?.name}-${year}`]: cellValue,
              };
            });
          } else {
            setRawTableValues((prevState) => {
              let data = {};
              selectedIndicatorDimensions.forEach((dimension, index) => {
                data[selectedIndicatorDimensions?.[index]?.id] = item[index];
              });
              const cellValue = {
                ...prevState[`${item?.join("-")}-${year}`],
                [timeDimensionId]: year,
                ...data,
                [name.slice(0, 5)]: value,
              };
              return {
                ...prevState,
                [`${item?.join("-")}-${year}`]: cellValue,
              };
            });
          }
          break;
      }
    },
    [columns, selectedIndicatorDimensions, entryType, timeDimensionId]
  );

  const submitTableValues = () => {
    const tableValues = (entryType) => {
      const percentageRawTableValues = {};
      for (const i in rawTableValues) {
        percentageRawTableValues[i] = {
          ...rawTableValues[i],
          value: `${Number(rawTableValues[i].value)}`,
        };
      }

      const percentageConstantTableValues = {};
      for (const i in constantTableValues) {
        percentageConstantTableValues[i] = {
          ...constantTableValues[i],
          value: `${Number(constantTableValues[i].value)}`,
        };
      }

      const percentageGrowthTableValues = {};
      for (const i in growthTableValues) {
        percentageGrowthTableValues[i] = {
          ...growthTableValues[i],
          value: `${Number(growthTableValues[i].value)}`,
        };
      }

      switch (entryType) {
        case "growth":
          return Object.values(
            formatProps.measure === "%"
              ? percentageGrowthTableValues
              : growthTableValues
          );

        case "constant":
          return Object.values(
            formatProps.measure === "%"
              ? percentageConstantTableValues
              : constantTableValues
          );

        default:
          return Object.values(
            formatProps.measure === "%"
              ? percentageRawTableValues
              : rawTableValues
          );
      }
    };

    overwriteIndicatorInputMutation({
      indicatorId: indicator?.id,
      data: {
        type: entryType,
        dimensions: selectedIndicatorDimensions,
        data_values: tableValues(entryType),
        scenario_id: scenarioId,
      },
    });
    setIsEdit(false);
  };

  const changeSwitch = (callback) => {
    setRawTableValues({});
    setGrowthTableValues({});
    setConstantTableValues({});
    setSelectedIndicatorDimensions(callback);
  };

  const selectEntryType = (event, type) => {
    setEntryType(type);
  };

  return (
    <>
      <Collapse
        in={
          isExpanded.indicatorId === indicator?.id &&
          isExpanded.expanded === true
        }
        timeout="auto"
        unmountOnExit
        sx={styles.collapse}
      >
        <CollapseControlRow
          indicatorDimensions={indicatorDimensions}
          selectedIndicatorDimensions={selectedIndicatorDimensions}
          onSelectIndicatorDimension={changeSwitch}
          columns={columns}
          entryType={entryType}
          onSelectEntryType={selectEntryType}
          onSubmitTableValues={submitTableValues}
        />

        <Table sx={{ width: "auto" }}>
          <TableHead>
            <TableRow>
              <CollapseTableHeader selectedInputType={entryType} />
            </TableRow>
          </TableHead>

          <TableBody sx={styles.collapseTableBody}>
            {selectedIndicatorDimensions?.length === 0 ? (
              <TableRow>
                {entryType === "growth" ? (
                  <TableBodyGrowthType
                    indicator={indicator}
                    onChangeTableValues={changeTableValues}
                    tableValues={growthTableValues}
                    selectedDimensionsQuantity={itemsQueries?.length}
                    formatProps={formatProps}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                  />
                ) : entryType === "constant" ? (
                  <TableBodyConstantType
                    indicator={indicator}
                    onChangeTableValues={changeTableValues}
                    tableValues={constantTableValues}
                    selectedDimensionsQuantity={itemsQueries?.length}
                    formatProps={formatProps}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                  />
                ) : (
                  <TableBodyRawType
                    indicator={indicator}
                    onChangeTableValues={changeTableValues}
                    tableValues={rawTableValues}
                    columns={columns}
                    selectedDimensionsQuantity={itemsQueries?.length}
                    formatProps={formatProps}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                  />
                )}
              </TableRow>
            ) : (
              getDimensionItems(itemsQueries)?.map((dimensionItem, index) => (
                <TableRow key={index}>
                  {entryType === "growth" ? (
                    <TableBodyGrowthType
                      dimensionItem={dimensionItem}
                      selectedDimensionsQuantity={itemsQueries?.length}
                      tableValues={growthTableValues}
                      onChangeTableValues={changeTableValues}
                      formatProps={formatProps}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  ) : entryType === "constant" ? (
                    <TableBodyConstantType
                      dimensionItem={dimensionItem}
                      selectedDimensionsQuantity={itemsQueries?.length}
                      tableValues={constantTableValues}
                      onChangeTableValues={changeTableValues}
                      formatProps={formatProps}
                      indicator={indicator}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  ) : (
                    <TableBodyRawType
                      dimensionItem={dimensionItem}
                      selectedDimensionsQuantity={itemsQueries?.length}
                      tableValues={rawTableValues}
                      onChangeTableValues={changeTableValues}
                      columns={columns}
                      formatProps={formatProps}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Collapse>

      <ToastMessage
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        alertTitle="Success"
        type="success"
      />
    </>
  );
};
