export const globalStyles = {
  html: {
    width: "100%",
    height: "100%",
  },
  body: {
    padding: 0,
    width: "100%",
    height: "100%",
  },
  a: { textDecoration: "none", color: "inherit" },
};
