import React, { createContext, useContext, useState } from "react";
import { ThemeProvider } from "@mui/material";

import { getTheme } from "../utils";

export const ThemeContext = createContext();

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};

export const MyThemeProvider = ({ children }) => {
  const currentTheme = localStorage.getItem("theme") || "light";

  const [theme, setTheme] = useState(currentTheme);

  const selectTheme = (theme) => {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  };

  const myTheme = getTheme(theme);

  return (
    <ThemeContext.Provider value={{ theme, onSelectTheme: selectTheme }}>
      <ThemeProvider theme={myTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
