export const styles = {
  content: {
    height: "100%",
    width: "100%",
    pl: 9,
    pt: 3,
    bgcolor: "white.main",
  },
  pageTitle: {
    fontSize: 48,
    lineHeight: 1.125,
    color: "primary.main",
  },
  cardsContainer: {
    height: "100%",
    pr: 15,
    pb: 5,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    rowGap: 10,
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
  sectionTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: 34,
    lineHeight: 1.125,
    letterSpacing: "-0.03em",
    color: "primary.main",
  },
  viewMoreButton: {
    lineHeight: 1.44,
    color: "black.main",
    textTransform: "none",
    "&:hover": {
      color: "primary.main",
      bgcolor: "transparent",
    },
  },
  searchIcon: { width: 28, height: 28 },
  loader: {
    height: 230,
    justifyContent: "center",
    alignItems: "center",
  },
  libraryModelGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 380px)",
    gridTemplateRows: 230,
    gap: 3.75,
  },
  looker: {
    width: 276,
    height: 355,
    ml: 10.5,
    fill: "transparent",
  },
  ownModelGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(380px, 1fr))",
    gridTemplateRows: 230,
    gap: 3.75,
  },
};
