export function setTokenWithExpiry(token) {
  // if (tokenExpiryTime) {
  //   const expiry = new Date(tokenExpiryTime).getTime();
  //   localStorage.setItem("BLOX_USER_TOKEN_EXPIRY", expiry);
  // }

  localStorage.setItem("BLOX_USER_TOKEN", token);
}

export function checkToken() {
  // const now = new Date();
  // const tokenExpiry = +localStorage.getItem("BLOX_USER_TOKEN_EXPIRY");
  return localStorage.getItem("BLOX_USER_TOKEN");
  // return now.getUTCMilliseconds() <= tokenExpiry;

}
