export const styles = {
  deleteButton: {
    color: "red.main",
    "&:hover": {
      bgcolor: "transparent",
    },
  },
  propertyName: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.26,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      height: 48,
      width: "100%",
      "& input": {
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 1.26,
      },
    },
  },
};
