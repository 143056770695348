import { Stack, SvgIcon, Typography } from "@mui/material";
import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as Lady } from "../../../../assets/icons/dimensions-lady.svg";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { DimensionElement } from "../DimensionElement";
import { Input } from "../Input";
import { Loader, CircleAddButton } from "../../../../components";

export const DimensionsList = ({
  data,
  setSelectedId,
  selectedId,
  isLoading,
  refetchModel,
}) => {
  const queryClient = useQueryClient();
  const { modelId } = useParams();
  const [addClicked, setAddClicked] = useState(false);
  const [name, setName] = useState("");

  const { mutate: addDimensionMutation } = useMutation(
    ({ modelId, newDimension }) =>
      apiService.addDimension(modelId, newDimension),
    {
      onSuccess: (response) => {
        if (response?.status === 201) {
          queryClient.setQueryData(["dimensions", modelId], (oldQueryData) => {
            return {
              ...oldQueryData,
              data: {
                dimensions: [
                  ...(oldQueryData?.data?.dimensions || []),
                  response?.data,
                ],
              },
            };
          });
          setSelectedId(response?.data?.id);
          refetchModel();
        }
      },
    }
  );

  const { mutate: deleteDimensionMutation } = useMutation(
    (dimensionId) => apiService.deleteDimension(dimensionId),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData(["dimensions", modelId], (oldQueryData) => {
            const filteredDimensions = oldQueryData?.data?.dimensions?.filter(
              (dimension) => dimension?.id !== response?.data?.id
            );

            return {
              ...oldQueryData,
              data: {
                dimensions: filteredDimensions,
              },
            };
          });
          refetchModel();
        }
      },
    }
  );

  const { mutate: updateDimensionMutation } = useMutation(
    ({ dimensionId, data }) => apiService.updateDimension(dimensionId, data),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData(["dimensions", modelId], (oldQueryData) => {
            const updatedDimensions = oldQueryData?.data?.dimensions?.map(
              (dimension) => {
                if (dimension?.id === response?.data?.id) {
                  return {
                    ...dimension,
                    name: response?.data?.name,
                  };
                }
                return dimension;
              }
            );

            return {
              ...oldQueryData,
              data: {
                dimensions: updatedDimensions,
              },
            };
          });
        }
      },
    }
  );

  const handleAddNewDimension = (event) => {
    if (event.charCode === 13 && name?.trim().length !== 0) {
      const newDimension = {
        name,
      };
      addDimensionMutation({ modelId, newDimension });
      setAddClicked(false);
      setName("");
    }
  };

  return (
    <Stack sx={styles.dimensionSection} spacing={5}>
      <Typography sx={styles.sectionTitle}>Dimensions</Typography>

      {isLoading ? (
        <Loader justifyContent="center" alignItems="center" />
      ) : (
        <Stack spacing={5} sx={styles.content}>
          <Stack sx={styles.dimensionList}>
            {data?.data?.dimensions?.length === 0 ? (
              <Typography sx={styles.noDimensions}>
                No Dimensions to show
              </Typography>
            ) : (
              <>
                {data?.data?.dimensions?.map((dimension) => (
                  <DimensionElement
                    key={dimension?.id}
                    data={dimension}
                    dimensionsList={data?.data.dimensions}
                    onDelete={deleteDimensionMutation}
                    onUpdate={updateDimensionMutation}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                  />
                ))}
              </>
            )}
          </Stack>

          {addClicked ? (
            <Input
              value={name}
              placeholder="New Dimension"
              onChange={(e) => setName(e.currentTarget.value)}
              onBlur={() => setAddClicked(false)}
              onKeyPress={handleAddNewDimension}
              inputProps={{ style: styles.input }}
            />
          ) : null}

          <CircleAddButton
            label="Add dimension"
            icon="plus"
            onClick={() => setAddClicked(true)}
          />

          <SvgIcon component={Lady} viewBox="0 0 401 264" sx={styles.lady} />
        </Stack>
      )}
    </Stack>
  );
};
