export const styles = {
  sidebar: {
    width: 350,
    height: "100%",
    py: 3.75,
    pl: 3,
    pr: 1.75,
  },
  propertiesIcon: { width: 24, height: 26, color: "secondary.main" },
  headerTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.25,
    color: "black.main",
  },
  closeButton: {
    position: "absolute",
    top: 3,
    right: 3,
    "&:hover": { color: "red.error" },
  },
  closeButtonIcon: { width: 30, height: 30 },
  indicatorName: {
    mt: 2.5,
    fontFamily: "Comfortaa",
    fontSize: 24,
    lineHeight: 1.1,
    color: "primary.main",
  },
  sectionTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.25,
    color: "primary.main",
  },
  checkboxWithLabel: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: 1.4,
      color: "grey.secondary",
    },
    "& .MuiCheckbox-root": {
      color: "grey.secondary",
    },
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  editIcon: {
    p: 0,
    color: "grey.secondary",
    cursor: "pointer",
    "&:hover": { color: "primary.main" },
    "& svg": {
      width: 14,
      height: 14,
    },
  },
  sectionSubtitle: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.17,
    color: "black.dark",
  },
  descriptionText: {
    mt: 1,
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 1.17,
    color: "grey.secondary",
    wordBreak: 'break-word',
  },
  editInput: {
    mt: 1,
    backgroundColor: 'red',
    p: 0,
    "& .MuiOutlinedInput-root": {
      p: '8px',
    },
    "& .MuiOutlinedInput-input": {
      p: 0,
      fontFamily: "Roboto",
      fontSize: 11,
      lineHeight: 1.17,
      color: "grey.secondary",
    },
  },
  formatField: {
    height: 24,
    width: "50%",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.4,
    color: "grey.secondary",
  },
   formatInputField: {
      height: 24,
      width: "50%",
      padding:'0 .5rem',
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: 1.4,
      color: "grey.secondary",
      border: "1px #7163d0 solid",
      borderRadius:'4px',
      outline:'none'
     },
  checkboxWithoutLabel: {
    p: 0,
    position: "relative",
    right: "14%",
    "&:hover, &:active": { background: "none" },
    "& .MuiCheckbox-root": {
      color: "grey.secondary",
    },
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  secondryFormLabel: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.17,
    color: "grey.secondary",
  },
};
