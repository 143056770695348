import {  Stack } from '@mui/material';
import {Button} from '../../components/UI/Button'
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import  { Loader } from '../Loader/index'
import {handleFileDelete, handleFileUpload, replaceImage} from '../../services/s3Bucket';

function UploadFiles({uploadedFile,data,values,deleteImages,updateImages,setLoading}) {
  const [image,setImage]=useState('');
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [progress,setProgress]=useState(false);

  const onDrop = async(acceptedFiles) => {
    setProgress(true);
    setLoading(true);
    const data=await handleFileUpload(acceptedFiles[0]);
    uploadedFile(data)
    setLoading(false);
    setProgress(false);
    setSelectedFiles(acceptedFiles);
    setCurrentFile(acceptedFiles[0]);
  };
  const dndStyle={
    padding:'1rem',
    textAlign:'center',
    border:'1px dashed #988EDD',
    borderRadius:'4px',
    color:'#7C6FD4',
    cursor:'pointer',
    backgroundColor:'#EAE8F8',
    width:'100%'
  }
  const deleteImage=async(e)=>{
    setProgress(true);
    setLoading(true)
    await handleFileDelete(e);
    await deleteImages();
    setLoading(false)
    setProgress(false);
  }
  const updateImage=async(e)=>{
    setProgress(true);
    setLoading(true)
    const dataa=await replaceImage(data?.icon,e.target.files[0]);
    setImage(dataa);
    await updateImages(dataa);
    setLoading(false)
    setProgress(false);
  }
  return (
    <div style={{width:'100%',marginTop:'0'}}>
      {progress===true?
      <Loader sx={{ justifyContent: "center" }} />
      :
      <>
    {!data?.icon || !values?.icon?
      <Dropzone onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
            <div style={dndStyle} {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {selectedFiles && selectedFiles[0].name ? (
                <div className="selected-file">
                  {selectedFiles && selectedFiles[0].name}
                </div>
              ) : (
                "Drag and drop file here or click to select one"
              )}
            </div>
        )}
      </Dropzone>
      :
      <Stack flexDirection={'row'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        <div style={{height:'100px',width:'150px'}}>
            <img alt="" style={{height:'100%',width:'100%'}} src={image?image:data?.icon}></img>
            </div>
            <Button onClick={()=>deleteImage(data?.icon)} style={{width:'fit-content',color:'black',height:'fit-content',padding:'0 5px 5px 5px'}}><small>Delete</small></Button>
            <Button style={{width:'fit-content',height:'fit-content',padding:'0 5px 5px 5px'}}>
            <label style={{fontSize:'15px'}} htmlFor="file-input">
        Update
      </label>
            </Button>
            <Button></Button>
      <input
        id="file-input"
        type="file"
        onChange={(e)=>{updateImage(e)}}
        style={{ display: "none" }}
      />
          </Stack>
      }
      </>
      }
      {/* {progress===true?
      <Stack>
        <div style={progressStyle}></div>
      <p>Progress</p>
      </Stack>:''
      } */}
    </div>
  );
}

export default UploadFiles;