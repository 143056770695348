import { IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "./styles";

export const PropertyElement = ({ data, onDelete, onUpdate }) => {
  const [editClicked, setEditClicked] = useState(false);
  const [updatedName, setUpdatedName] = useState("");

  const handleChangeDimensionPropertyName = (event) => {
    if (event.charCode === 13 && updatedName?.trim().length !== 0) {
      onUpdate({
        propertyId: data?.id,
        data: { name: updatedName },
      });
      setEditClicked(false);
      setUpdatedName("");
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      {editClicked ? (
        <TextField
          value={updatedName}
          onChange={(event) => setUpdatedName(event.currentTarget.value)}
          onKeyPress={handleChangeDimensionPropertyName}
          onBlur={() => setEditClicked(false)}
          placeholder={data?.name}
          autoFocus
          sx={styles.textField}
        />
      ) : (
        <>
          <Typography
            sx={styles.propertyName}
            onDoubleClick={() => setEditClicked(true)}
          >
            {data?.name}
          </Typography>
          <IconButton
            aria-label="delete property"
            sx={styles.deleteButton}
            onClick={() => onDelete(data?.id)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
