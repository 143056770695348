export const styles = {
  sidebar: {
    width: 350,
    height: "100%",
    py: 3.75,
    pl: 3,
    pr: 1.75,
  },
  propertiesIcon: { width: 24, height: 26, color: "secondary.main" },
  headerTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.25,
    color: "black.main",
  },
  closeButton: {
    position: "absolute",
    top: 3,
    right: 3,
    "&:hover": { color: "red.error" },
  },
  closeButtonIcon: { width: 30, height: 30 },
  indicatorName: {
    mt: 2.5,
    fontFamily: "Comfortaa",
    fontSize: 24,
    lineHeight: 1.1,
    color: "primary.main",
  },
  description: {
    mt: 2.5,
  },
  sectionTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.25,
    color: "black.main",
  },
  editIcon: {
    p: 0,
    color: "grey.secondary",
    cursor: "pointer",
    "&:hover": { color: "primary.main" },
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  descriptionText: {
    mt: 1,
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.17,
    color: "grey.secondary",
  },
  editInput: {
    mt: 1,
    "& .MuiOutlinedInput-input": {
      p: 1,
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: 1.17,
      color: "grey.secondary",
    },
  },
  checkboxWithLabel: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: 1.4,
      color: "grey.secondary",
    },
    "& .MuiCheckbox-root": {
      color: "grey.secondary",
    },
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  select: {
    height: 30,
    width: "50%",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.4,
    color: "grey.secondary",
  },
  chartTypeButton: (selectedType) => ({
    height: 30,
    borderRadius: 2.5,
    textTransform: "capitalize",
    pointerEvents: selectedType ? "none" : "initial",
  }),
  chartTypeLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
  },
  formatName: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.4,
    color: "grey.secondary",
  },
  formatField: {
    height: 24,
    width: "35%",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.4,
    color: "grey.secondary",
  },
  input: {
    width: "35%",
    "& .MuiOutlinedInput-root": {
      height: 24,
      "& input": {
        height: "100%",
        py: 0,
        px: 1.75,
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: 1.4,
        color: "grey.secondary",
      },
    },
  },
  checkboxWithoutLabel: {
    p: 0,
    position: "relative",
    right: "14%",
    "&:hover, &:active": { background: "none" },
    "& .MuiCheckbox-root": {
      color: "grey.secondary",
    },
    "& svg": {
      width: 18,
      height: 18,
    },
  },
};
