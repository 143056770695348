import React from "react";
import { Card, CardActions, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { styles } from "./styles";

export const CreateModelButton = ({ onClick }) => {
  return (
    <Card sx={styles.card}>
      <CardActions>
        <IconButton onClick={onClick}>
          <AddCircleOutlineIcon sx={styles.button} />
        </IconButton>
      </CardActions>
      <Typography sx={styles.text}>Create a new model</Typography>
    </Card>
  );
};
