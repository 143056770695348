export const styles = {
  upperRow: (isExpanded, indicatorId, isDragging) => ({
    height: 65,
    bgcolor:
      isExpanded.indicatorId === indicatorId &&
      isExpanded.expanded === true &&
      !isDragging
        ? "grey.border"
        : "white.main",
    "& th, & td": {
      borderLeft: 0,
      borderRight: "1px solid",
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderColor: "grey.main",
    },
    "& td:last-child": {
      borderRight: 0,
    },
    transition: "all 275ms",
  }),
  collapsedRow: {
    bgcolor: "white.main",
  },
  collapseCell: { p: 0, border: 0, cursor: "default" },
  tableCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: 130,
    minWidth: 130,
    p: 0,
    textAlign: "center",
    cursor: "default",
  },
  cellValue: {
    fontFamily: "Roboto",
    fontSize: 18,
    color: "black.main",
  },
};
