import React, { createContext, useContext, useState } from "react";

export const PivotContext = createContext();

export const usePivotContext = () => {
  const context = useContext(PivotContext);
  if (!context) {
    throw new Error("usePivotContext must be used within a PivotProvider");
  }
  return context;
};

export const PivotProvider = ({ children }) => {
  const [pivotState, setPivotState] = useState({});

  const savePivotState = (blockId, newState) => {
    return setPivotState((prevState) => ({
      ...prevState,
      [blockId]: newState,
    }));
  };

  return (
    <PivotContext.Provider
      value={{
        pivotState,
        onSavePivotState: savePivotState,
      }}
    >
      {children}
    </PivotContext.Provider>
  );
};
