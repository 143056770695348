import { Stack, Typography, SvgIcon, Divider } from "@mui/material";
import React from "react";
import { Droppable } from "react-beautiful-dnd";

import { ReactComponent as OppositeArrowsIcon } from "../../../../assets/icons/opposite_arrows.svg";
import { ReactComponent as RowsIcon } from "../../../../assets/icons/rows.svg";
import { ReactComponent as ColumnsIcon } from "../../../../assets/icons/columns.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";

import { styles } from "./styles";
import { PivotItem } from "../PivotItem";

export const PivotList = ({ list, listIndex, blockId, onFilterItemSelect }) => {
  const { id, heading, name, dimensions } = list;

  const showListIcon = (columnName) => {
    switch (columnName.toLocaleLowerCase()) {
      case "rows":
        return (
          <SvgIcon
            component={RowsIcon}
            viewBox="0 0 24 24"
            sx={styles.listIcon}
          />
        );

      case "columns":
        return (
          <SvgIcon
            component={ColumnsIcon}
            viewBox="0 0 23 23"
            sx={styles.listIcon}
          />
        );

      case "filters":
        return (
          <SvgIcon
            component={FilterIcon}
            viewBox="0 0 24 24"
            sx={styles.listIcon}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Droppable droppableId={String(id)}>
      {(provided) => (
        <Stack
          {...provided.droppableProps}
          ref={provided.innerRef}
          sx={styles.pivotList(listIndex)}
          spacing={1.5}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {showListIcon(heading)}
            <Typography component="h3" sx={styles.pivotListName}>
              {heading}
            </Typography>
          </Stack>

          <Stack spacing={2}>
            {dimensions ? (
              dimensions.map((dimension, index) => (
                <PivotItem
                  key={dimension.id}
                  dimension={dimension}
                  index={index}
                  listName={name}
                  listIndex={listIndex}
                  blockId={blockId}
                  onFilterItemSelect={onFilterItemSelect}
                />
              ))
            ) : (
              <Typography>Nothing to display</Typography>
            )}
          </Stack>

          {listIndex === 0 ? (
            <Divider sx={styles.divider}>
              <SvgIcon
                component={OppositeArrowsIcon}
                viewBox="0 0 30 33"
                sx={styles.dividerIcon}
              />
            </Divider>
          ) : null}
          {provided.placeholder}
        </Stack>
      )}
    </Droppable>
  );
};
