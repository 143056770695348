import { SvgIcon } from "@mui/material";
import React from "react";

import { ReactComponent as LogoIcon } from "../assets/icons/logo.svg";

export const Logo = ({ isSmall }) => {
  return (
    <SvgIcon
      component={LogoIcon}
      viewBox="0 0 226 71"
      sx={{ width: isSmall ? 196 : 355, height: isSmall ? 80 : 140 }}
    />
  );
};
