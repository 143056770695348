import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { useRenameIndicatorContext } from "../../../../context";
import { Button, Loader, ToastMessage } from "../../../../components";
import { IndicatorsTable } from "../IndicatorsTable";
import { AddDimensionsModal } from "../AddDimensionsModal";
import ScenarioBox from "../ViewTab/ScenarioComponent";
import { useDispatch } from "react-redux";
import { getAddIndicatorLoaderStatus, handleAddIndicatorLoader } from "../../../../Redux/IndicatorSlice";
import { useSelector } from "react-redux";

const BuildTab = ({
  requiredBlock,
  modelDimensions,
  selectedModelDimensions,
  onSelectModelDimensions,
}) => {
  const queryClient = useQueryClient();
  const { onOpenEditField } = useRenameIndicatorContext();
  const [dimensionsModalOpen, setDimensionsModalOpen] = useState(false);
  const [addIndicatorError, setAddIndicatorError] = useState({
    isError: false,
    message: "",
  });
  const dispatch = useDispatch();
  const addIndicatorLoaderStatus = useSelector(getAddIndicatorLoaderStatus);

  const { mutate: addIndicatorToBlockMutation } =
    useMutation(
      ({ blockId, newIndicator }) =>
        apiService.addIndicator(blockId, newIndicator),
      {
        onSuccess: (response) => {
          queryClient.invalidateQueries(["block", String(requiredBlock?.id)]);
          onOpenEditField(response.data.id);
        },
        onError: (response) => {
          dispatch(handleAddIndicatorLoader(false));
          if (response.message.includes("status code 400")) {
            setAddIndicatorError({
              isError: true,
              message: "An indicator with the same name already exists",
            });
          }
        },
      }
    );

  const toggleDimensionsModal = () => {
    setDimensionsModalOpen((prevState) => !prevState);
  };

  const createIndicator = () => {
    dispatch(handleAddIndicatorLoader(true));
    const newIndicator = { name: "New Indicator", type: "Input" };
    addIndicatorToBlockMutation({
      blockId: String(requiredBlock?.id),
      newIndicator,
    });
  };

  const closeToastMessage = () => {
    setAddIndicatorError({ isError: false, message: "" });
  };

  const timeDimensionId = requiredBlock?.dimensions?.find(
    (dimension) => dimension?.name.toLocaleLowerCase() === "time"
  )?.id;

  return (
    <Stack sx={styles.tabContainer} spacing={6}>
      <Stack direction="row" alignItems="center" spacing={4}>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton
            sx={styles.addIndicatorButton}
            onClick={createIndicator}
            disabled={addIndicatorLoaderStatus}
          >
            <AddIcon sx={styles.icon} />
          </IconButton>
          <Typography sx={styles.text}>Add indicator</Typography>
        </Stack>

        <Divider orientation="vertical" flexItem />

        <Stack direction="row" height="100%" alignItems="center" spacing={2}>
          <Typography sx={styles.text}>Block dimensions:</Typography>

          {requiredBlock?.dimensions?.map((dimension) => (
            <Chip
              label={dimension?.name}
              variant="outlined"
              key={dimension?.id}
              sx={styles.dimensionChip}
            />
          ))}
          <Button
            variant="outlined"
            startIcon={<EditIcon sx={{ width: 24, height: 24 }} />}
            sx={styles.editDimensionsButton}
            onClick={toggleDimensionsModal}
          >
            Edit
          </Button>
        </Stack>
          <>
            <Divider orientation="vertical" flexItem />
            <Stack sx={styles.BuildScenario}>
              <ScenarioBox />
            </Stack>
          </>
      </Stack>

      {requiredBlock?.indicators?.length > 0 ? (
        <Box sx={{ overflowY: "hidden", position: "relative" }}>
          {addIndicatorLoaderStatus && (
            <Loader
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100,
              }}
            />
          )}
          <IndicatorsTable
            requiredBlock={requiredBlock}
            timeDimensionId={timeDimensionId}
            isAddingIndicator={addIndicatorLoaderStatus}
          />
        </Box>
      ) : null}

      <AddDimensionsModal
        open={dimensionsModalOpen}
        onClose={toggleDimensionsModal}
        modelDimensions={modelDimensions}
        selectedModelDimensions={selectedModelDimensions}
        onSelectModelDimensions={onSelectModelDimensions}
      />

      <ToastMessage
        open={addIndicatorError.isError}
        onClose={closeToastMessage}
        message={addIndicatorError.message}
        alertTitle="Warning"
        type="warning"
      />
    </Stack>
  );
};
export default BuildTab;
