import { CssBaseline } from "@mui/material";

import { MyThemeProvider } from "./context";
import { Layout } from "./components";

function App() {
  return (
    <MyThemeProvider>
      <CssBaseline />
      <Layout />
    </MyThemeProvider>
  );
}

export default App;
