import { Stack, TableCell, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { useRenameIndicatorContext } from "../../../../context";
import { IndicatorTypeMenu } from "../IndicatorTypeMenu";
import { IndicatorTypeMenuButton } from "./IndicatorTypeMenuButton";
import { IndicatorNameEditField } from "./IndicatorNameEditField";
import { ActionButton } from "./ActionButton";
import { SettingsMenu, ToastMessage } from "../../../../components";

export const IndicatorNameCell = ({
  indicator,
  isExpanded,
  onExpand,
  setIsEdit,
}) => {
  const { editField, onOpenEditField, onCloseEditField } =
    useRenameIndicatorContext();
  const { modelId, blockId } = useParams();
  const queryClient = useQueryClient();
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const [typeAnchor, setTypeAnchor] = useState(null);
  const [isEditError, setIsEditError] = useState(false);

  const { mutate: deleteIndicatorMutation } = useMutation(
    (indicatorId) => apiService.deleteIndicator(indicatorId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["block", blockId]);
        queryClient.invalidateQueries(["favorites", modelId]);
      },
    }
  );

  const { mutate: updateIndicatorMutation } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["block", blockId]);
        queryClient.invalidateQueries(["favorites", modelId]);
      },
    }
  );

  const openSettings = (event) => {
    setSettingsAnchor(event.currentTarget);
    setIsEdit(false);
  };

  const closeSettings = () => {
    setSettingsAnchor(null);
  };

  const openTypeMenu = (event) => {
    setTypeAnchor(event.currentTarget);
  };

  const closeTypeMenu = () => {
    setTypeAnchor(null);
  };

  const toggleFavourite = () => {
    updateIndicatorMutation({
      indicatorId: indicator?.id,
      data: { favourite: indicator?.favourite ? "false" : "true" },
    });
  };

  return (
    <>
      <TableCell component="th" sx={styles.tableCell}>
        {editField[indicator?.id] ? (
          <IndicatorNameEditField
            indicatorName={indicator?.name}
            indicatorId={indicator?.id}
            setIsEditError={setIsEditError}
            onCloseEditField={onCloseEditField}
            onUpdateIndicator={updateIndicatorMutation}
          />
        ) : (
          <Stack sx={styles.cellContainer}>
            <Stack direction="row" alignItems="center">
              <DragIndicatorIcon sx={styles.dragIcon} />

              <IndicatorTypeMenuButton
                openTypeMenu={openTypeMenu}
                indicatorType={indicator?.type}
              />

              <Tooltip title="Double click to rename">
                <Typography
                  sx={styles.indicatorName}
                  onDoubleClick={() => onOpenEditField(indicator?.id)}
                >
                  {indicator?.name}
                </Typography>
              </Tooltip>
            </Stack>

            <Stack direction="row" alignItems="center">
              <ActionButton
                tooltipTitle="Add to favourite"
                type="favourite"
                isFavourite={indicator?.favourite}
                onClick={toggleFavourite}
              />

              <ActionButton
                tooltipTitle="Context menu"
                type="menu"
                onClick={(event) => openSettings(event, indicator?.id)}
              />

              <ActionButton
                tooltipTitle="Expand/collapse button"
                type="expand"
                isExpanded={isExpanded}
                indicatorId={indicator?.id}
                onClick={() => onExpand(indicator?.id, true)}
              />
            </Stack>
          </Stack>
        )}
      </TableCell>

      <IndicatorTypeMenu
        indicatorId={indicator?.id}
        anchorEl={typeAnchor}
        open={Boolean(typeAnchor)}
        onClose={closeTypeMenu}
        onUpdate={updateIndicatorMutation}
      />

      <SettingsMenu
        anchorEl={settingsAnchor}
        data={indicator}
        target="indicator"
        open={Boolean(settingsAnchor)}
        onClose={closeSettings}
        onDelete={deleteIndicatorMutation}
        onUpdate={updateIndicatorMutation}
      />

      <ToastMessage
        open={isEditError}
        onClose={() => setIsEditError(false)}
        message="Name should contain at least one character"
        alertTitle="Warning"
        type="warning"
      />
    </>
  );
};
