import React, { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  Box,
  Tooltip,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  currentScenario,
  fetchScenarios,
} from "../../../../../Redux/ScenarioSlice";
import FormPopover from "./FormPopover";
import InfoPopover from "./InfoPopover";
import { styles } from "./styles";

const ScenarioBox = ({
  getScenarios,
  scenarioList,
  currentSelectedScenario,
}) => {
  const { modelId } = useParams();

  const [currentScenerio, setCurrentScenerio] = useState("");
  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = useState(null);
  const [formPopoverAnchorEl, setFormPopoverAnchorEl] = useState(null);
  const [menuScenerio, setMenuScenerio] = useState(null);
  const [selectDropdownOpen, setSelectDropdownOpen] = useState(false);
  const [renameData, setRenameData] = useState({
    scenarioName: "",
    scenarioDescription: "",
  });

  useEffect(() => {
    const baseScenerio = scenarioList?.scenarios.find(
      (scenerio) => scenerio?.is_base
    );
    if (baseScenerio) {
      setCurrentScenerio(baseScenerio?.id);
    }
  }, [scenarioList?.scenarios]);

  useEffect(() => {
    getScenarios(modelId);
  }, [modelId]);
  useEffect(() => {
    currentSelectedScenario(currentScenerio);
  }, [currentScenerio]);

  const handleSelectChange = (event) => {
    setCurrentScenerio(event.target.value);
  };

  const handleInfoIconClick = useCallback((event, scenario) => {
    event.stopPropagation();
    event.preventDefault();
    setInfoPopoverAnchorEl(event.currentTarget);
    setMenuScenerio(scenario);
  }, []);

  const handleAddScenarioClick = useCallback((event) => {
    // event.stopPropagation();
    setFormPopoverAnchorEl(event.target);
  }, []);

  const handleInfoPopoverClose = useCallback(() => {
    setInfoPopoverAnchorEl(null);
  }, []);

  const handleFormPopoverClose = () => {
    setFormPopoverAnchorEl(null);
    setRenameData({
      scenarioName: "",
      scenarioDescription: "",
    });
  };

  const handleFormPopoverOpen = (anchorEl) => {
    setFormPopoverAnchorEl(anchorEl);
  };

  const handleRename = (event) => {
    event.stopPropagation();

    if (menuScenerio) {
      setRenameData({
        scenarioId: menuScenerio.id,
        scenarioName: menuScenerio.name,
        scenarioDescription: menuScenerio.description || "",
        isRename: true,
      });
      handleInfoPopoverClose();
      handleFormPopoverOpen(event.currentTarget);
    }
  };
  const handleDeleteScenario = useCallback(() => {
    setSelectDropdownOpen(false);
  }, []);
  return (
    <>
      <Typography sx={styles.scenarioText}>Scenario:</Typography>
      <Box width={"65%"} minWidth={"140px"}>
        <FormControl fullWidth>
          <Select
            open={selectDropdownOpen}
            onOpen={() => setSelectDropdownOpen(true)}
            onClose={() => setSelectDropdownOpen(false)}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={styles.scenarioselect}
            MenuProps={{
              PaperProps: {
                sx: styles.menuBox,
              },
            }}
            onChange={handleSelectChange}
            value={currentScenerio}
            renderValue={(scenerio) => {
              const selectedId = scenarioList?.scenarios.find(
                (scen) => scen?.id === scenerio
              );
              return (
                <Tooltip title={selectedId?.name} placement="top">
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`${selectedId?.name}`}
                  </Typography>
                </Tooltip>
              );
            }}
          >
            {scenarioList?.scenarios &&
              scenarioList?.scenarios?.map((scenario) => (
                <MenuItem
                  key={scenario?.id}
                  value={scenario?.id}
                  sx={styles.menuItem}
                >
                  {scenario?.name}
                  <MoreHorizIcon
                    sx={{ color: "black.main" }}
                    onClick={(e) => handleInfoIconClick(e, scenario)}
                  />
                </MenuItem>
              ))}
            <MenuItem onClick={handleAddScenarioClick}>
              <Typography sx={styles.addScenarioText}>
                + Add Scenario
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <InfoPopover
        anchorEl={infoPopoverAnchorEl}
        onClose={handleInfoPopoverClose}
        onRename={handleRename}
        menuScenerio={menuScenerio}
        handleDeleteScenario={handleDeleteScenario}
      />
      <FormPopover
        anchorEl={formPopoverAnchorEl}
        onClose={handleFormPopoverClose}
        initialValues={renameData}
      />
    </>
  );
};
const stateToProperty = (state) => {
  return {
    scenarioList: state.scenarioSlice?.scenarioList,
    scenarioListStatus: state.scenarioSlice?.scenarioListStatus,
  };
};
const mapDispatchToProperties = (dispatch) => {
  return {
    getScenarios: (modelId) => dispatch(fetchScenarios(modelId)),
    currentSelectedScenario: (data) => dispatch(currentScenario(data)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(ScenarioBox);
