import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useStoreApi, useReactFlow } from "react-flow-renderer";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";

import { styles } from "./styles";

export const MapControls = ({
  layoutDirection,
  onLayout,
  isDraggable,
  setIsDraggable,
}) => {
  const store = useStoreApi();
  const { zoomIn, zoomOut, setCenter } = useReactFlow();

  const focusNode = () => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {
      const node = nodes[0];

      const x = node.position.x;
      const y = node.position.y + node.height / 0.5;
      const zoom = 1;

      setCenter(x, y, { zoom, duration: 500 });
    }
  };

  const toggleInteractivity = () => {
    setIsDraggable((prevState) => !prevState);
  };

  return (
    <Stack sx={styles.mapControls}>
      <Tooltip title="zoom in" placement="right">
        <IconButton onClick={() => zoomIn({ duration: 500 })}>
          <ZoomOutMapIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="zoom out" placement="right">
        <IconButton onClick={() => zoomOut({ duration: 500 })}>
          <ZoomInMapIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="fit view" placement="right">
        <IconButton onClick={focusNode}>
          <CenterFocusStrongIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="toggle interactivity" placement="right">
        <IconButton onClick={toggleInteractivity}>
          {isDraggable ? <LockOpenIcon /> : <LockIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="vertical layout" placement="right">
        <IconButton
          onClick={() => onLayout("TB")}
          sx={{
            color: layoutDirection === "TB" ? "primary.main" : "black.main",
          }}
        >
          <AlignVerticalBottomIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="horizontal layout" placement="right">
        <IconButton
          onClick={() => onLayout("LR")}
          sx={{
            color: layoutDirection === "LR" ? "primary.main" : "black.main",
          }}
        >
          <AlignHorizontalLeftIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
