import React, { forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

import { styles } from "./styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteAlert = ({ open, onClose, onDelete, data, type }) => {
  const handleDelete = () => {
    onDelete(data?.id);
    onClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.dialogTitle}>
        {"Please confirm your action"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={styles.dialogContent}>
          {`Are you sure you want to delete "${data?.name}"
           ${type.toLocaleLowerCase()}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={styles.button("cancel")}>
          Cancel
        </Button>
        <Button onClick={handleDelete} sx={styles.button("confirm")}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
