export const styles = {
  dimensionSection: {
    height: "100%",
    pb: 5,
    overflowY: "hidden",
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 38,
    lineHeight: 1.12,
    color: "primary.main",
  },
  content: {
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
  dimensionList: {
    pr: 2,
    "& div:not(:last-child)": {
      mb: 2,
    },
  },
  noDimensions: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 1.12,
    textAlign: "center",
    color: "black.main",
    opacity: 0.7,
  },
  input: {
    width: "100%",
    fontFamily: "Comfortaa",
    fontWeight: 700,
    fontSize: 30,
    lineHeight: 1.06,
  },
  lady: {
    alignSelf: "center",
    width: 333,
    height: 220,
    fill: "transparent",
  },
};
