import React from "react";
import { Stack, Typography } from "@mui/material";

import { styles } from "./styles";
import { ProfileForm, ProfileMedia } from "./components";
import { Logo } from "../../icons";

export const Profile = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      <Logo />
      <Typography sx={styles.desc}>
        Business modelling for ambitious leaders
      </Typography>
      <Typography component="h2" sx={styles.subtitle}>
        Please tell us more about you...
      </Typography>
      <Stack direction="row" justifyContent="center">
        <ProfileForm />
        <ProfileMedia />
      </Stack>
    </Stack>
  );
};
