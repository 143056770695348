import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../constants";
import { apiService } from "../services";

export const fetchModel = createAsyncThunk(
  "model/fetch",
  async (modelId, thunkAPI) => {
    try {
      const response = await apiService.getModel(modelId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  modelDetail: null,
  modelStatus: STATUS.IDLE,
};
const SharedSlice = createSlice({
  initialState,
  name: "sharedSlice",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModel.fulfilled, (state, action) => {
      state.modelDetail = action.payload;
      state.modelStatus = STATUS.SUCCESS;
    });
  },
});
// export const getModelDetails = (state) => state.sharedSlice.modelDetail;

export default SharedSlice.reducer;
