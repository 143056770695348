import {
  Collapse,
  InputAdornment,
  List,
  ListItemButton,
  ListSubheader,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

import { ReactComponent as FunctionIcon } from "../../../../assets/icons/function.svg";

import { styles } from "./styles";
import { apiService } from "../../../../services";

export const CollapseCalculationType = ({
  indicator,
  isExpanded,
  indicatorsList,
}) => {
  const { blockId } = useParams();
  const queryClient = useQueryClient();
  const [formula, setFormula] = useState(
    indicator?.formula === "0" ? '' : indicator?.formula
  );
  useEffect(() => {
    if (indicator?.formula === "0") {
      setFormula('');
    } else {
      setFormula(indicator?.formula);
    }
  }, [indicator]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [filterValue, setfilterValue] = useState('');

  const { mutate: updateIndicatorMutation } = useMutation(
    ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["blockValues", blockId]);
        queryClient.invalidateQueries(["block", blockId]);
        setSuccessMessage("The formula has been submitted successfully");
        setErrorMessage('');
      },
      onError: (error) => {
        if (error.message.includes("status code 400")) {
          setErrorMessage(error.response.data.message);
        }
      },
    }
  );

  const changeFormula = ({ currentTarget: { value } }) => {
    const lastTypedIndicator = value.match(/\b(\w+)$/g);

    setfilterValue(lastTypedIndicator ? lastTypedIndicator[0] : '');
    setFormula(value);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const blurFormula = () => {
    setSuccessMessage('');
    setfilterValue('');
  };

  const submitFormula = (event) => {
    if (event.charCode === 13 && !event.shiftKey) {
      event.preventDefault();
      updateIndicatorMutation({
        indicatorId: indicator?.id,
        data: { formula: formula === "" ? "0" : formula },
      });
    }
  };

  const pressESC = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setFormula(indicator?.formula);
        setfilterValue('');
      }
    },
    [indicator?.formula]
  );

  useEffect(() => {
    window.addEventListener("keydown", pressESC);

    return () => {
      window.removeEventListener("keydown", pressESC);
    };
  }, [pressESC]);

  const selectOption = useCallback(
    (option) => {
      const numberOfOptionWords = option.split(" ").length;

      const formulaArray = formula.split(" ");

      formulaArray.splice(-1, 1, option);

      if (numberOfOptionWords > 1) {
        const formulaString = formulaArray
          .map((el) => {
            if (el === option) {
              return `'${option}'`;
            }
            return el;
          })
          .join(" ");

        setFormula(formulaString);
      }

      if (numberOfOptionWords === 1) {
        const formulaString = formulaArray.join(" ");

        setFormula(formulaString);
      }

      setfilterValue('');
    },
    [formula]
  );

  const isOptionsVisible =
    Boolean(filterValue) && !Boolean(errorMessage) && !Boolean(successMessage);

  const optionList = useMemo(() => {
    return indicatorsList.reduce((acc, option) => {
      if (option.name.toLowerCase().includes(filterValue.toLowerCase())) {
        return [
          ...acc,
          <ListItemButton
            key={option.name}
            onClick={() => selectOption(option.name)}
            sx={{ py: 0 }}
          >
            <Typography sx={styles.optionItem}>{option.name}</Typography>
          </ListItemButton>,
        ];
      }
      return acc;
    }, []);
  }, [filterValue, indicatorsList, selectOption]);

  return (
    <Collapse
      in={
        isExpanded.indicatorId === indicator?.id && isExpanded.expanded === true
      }
      timeout="auto"
      unmountOnExit
      sx={styles.collapse}
    >
      <TableRow>
        <TableCell sx={{ width: 350, ...styles.tableCell }} />

        <TableCell sx={{ width: "100%", ...styles.tableCell }}>
          <Stack sx={styles.functionWrapper}>
            <SvgIcon
              component={FunctionIcon}
              viewBox="0 0 38 29"
              sx={styles.functionIcon}
            />

            <TextField
              value={formula}
              onChange={changeFormula}
              onKeyPress={submitFormula}
              onBlur={blurFormula}
              multiline
              maxRows={5}
              fullWidth
              error={Boolean(errorMessage)}
              helperText={errorMessage || successMessage}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SubdirectoryArrowLeftIcon />
                  </InputAdornment>
                ),
              }}
              sx={styles.textField}
            />

            {isOptionsVisible ? (
              <List
                sx={styles.optionList}
                subheader={
                  <ListSubheader
                    component="div"
                    id="list-subheader"
                    sx={{ color: "primary.main", textTransform: "uppercase" }}
                  >
                    Current block
                  </ListSubheader>
                }
              >
                {optionList}
              </List>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
    </Collapse>
  );
};
