import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useQuery } from "react-query";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { useSelector } from "react-redux";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";

export const PivotItem = ({
  dimension,
  index,
  listName,
  listIndex,
  blockId,
  onFilterItemSelect,
}) => {
  const { id, name } = dimension;
  const [item, setItem] = useState("");
  const scenarioId = useSelector(getSelectedScenario);

  const isDimensions = id.toString() !== "Indicators";
  let items = [];

  const { data: dimItems, refetch: dimItemsRefetch } = useQuery(
    ["dimension", id, scenarioId],
    () =>
      apiService.getDimensionItems(id, {
        params: { scenario_id: scenarioId },
      }),
    { enabled: isDimensions }
  );
  useEffect(() => {
    dimItemsRefetch();
  }, [scenarioId, dimItemsRefetch]);
  const { data: indicators } = useQuery(
    ["indicators", blockId],
    () => apiService.getIndicators(blockId),
    { enabled: !isDimensions }
  );

  const handleSelectItem = (event) => {
    setItem(event.target.value);
    const selectedItem = { dimension: name, item: event.target.value };
    onFilterItemSelect(selectedItem);
  };

  if (isDimensions && typeof dimItems !== "undefined") {
    items = dimItems?.data.items;
  } else if (!isDimensions && typeof indicators !== "undefined") {
    items = indicators?.data.indicators;
  }

  return (
    <Draggable draggableId={String(id)} index={index}>
      {(provided, snapshot) => (
        <Card
          sx={styles.pivotItem(
            listIndex,
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          ref={provided.innerRef}
          snapshot={snapshot}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <DragIndicatorIcon sx={styles.dragIcon(snapshot.isDragging)} />
            {listName !== "filters" ? (
              <Typography
                component="h3"
                sx={styles.pivotItemName(snapshot.isDragging)}
              >
                {name}
              </Typography>
            ) : (
              <FormControl sx={{ m: 1, minWidth: 130 }}>
                <InputLabel
                  id={`${id.toString()}-label`}
                  sx={styles.selectLabel}
                >
                  {name}
                </InputLabel>
                <Select
                  labelId={`${id.toString()}-label`}
                  id={`${id.toString()}-select`}
                  autoWidth
                  value={item}
                  label={name}
                  onChange={handleSelectItem}
                  sx={{ height: 40 }}
                >
                  {items?.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Card>
      )}
    </Draggable>
  );
};
