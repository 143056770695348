export const styles = {
  itemSection: {
    overflowY: "auto",
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 38,
    lineHeight: 1.12,
    color: "primary.main",
  },
  itemName: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 18,
    color: "black.main",
  },
  deleteButton: {
    color: "red.main",
  },
  tableContainer: {
    height: "100%",
    width: "100%",
    paddingBottom: 5,

    "& .ag-root-wrapper": {
      border: 0,
    },

    "& .ag-header": {
      borderRadius: 1,
      border: 0,
      bgcolor: "grey.border",

      "& .ag-header-cell": {
        fontFamily: "Roboto",
        fontSize: 18,
        lineHeight: 1.26,
        letterSpacing: "-0.015em",
        color: "black.main",
      },
    },

    "& .ag-body-viewport": {
      overflowY: "auto",
      "::-webkit-scrollbar": {
        width: 5,
        bgcolor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        bgcolor: "primary.main",
        borderRadius: 1,
      },
      "::-webkit-scrollbar-button": {
        bgcolor: "transparent",
        height: 5,
      },

      "& .ag-center-cols-container": {
        width: "100%!important",
      },

      "& .ag-row": {
        borderBottom: "1px solid",
        borderColor: "grey.border",
        bgcolor: "inherit",
        "&:hover": {
          bgcolor: "inherit",
        },
      },

      "& .ag-cell": {
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 18,
        color: "black.main",
        bgcolor: "white.main",
      },
    },
  },
  scenarioContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "20%",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
};
