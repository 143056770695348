import {
  Avatar,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as BlockIcon } from "../../../../assets/icons/block-aux.svg";

import { styles } from "./styles";
import { SettingsMenu } from "../../../../components";

export const BlockRow = ({ block, onDelete, onUpdate }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openSettingsMenu = Boolean(anchorEl);

  const openSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSettings = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow sx={{ height: 95 }}>
      <TableCell component="th" scope="row">
        <Stack direction="row" alignItems="center" spacing={8}>
          <Stack
            sx={styles.iconWrapper}
            onClick={() =>
              navigate(`/model/${block?.model_id}/block/${block?.id}`)
            }
          >
            <SvgIcon
              component={BlockIcon}
              viewBox="0 0 79 81"
              sx={styles.icon}
            />
          </Stack>
          <Typography
            sx={[styles.mainText, styles.blockName]}
            onClick={() =>
              navigate(`/model/${block?.model_id}/block/${block?.id}`)
            }
          >
            {block?.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={styles.avatar} aria-label="avatar">
            A
          </Avatar>
          <Typography sx={styles.mainText}>User</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing="auto">
          <Stack direction="row" spacing={1}>
            {block?.dimensions?.map((dimension) => (
              <Chip
                label={dimension?.name}
                size="small"
                variant="outlined"
                key={dimension?.name}
                sx={styles.dimensionChip}
              />
            ))}
          </Stack>
          <IconButton aria-label="settings" onClick={openSettings}>
            <MoreHorizIcon fontSize="large" sx={{ color: "grey.main" }} />
          </IconButton>
        </Stack>
      </TableCell>

      <SettingsMenu
        anchorEl={anchorEl}
        data={block}
        target="block"
        open={openSettingsMenu}
        onClose={closeSettings}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </TableRow>
  );
};
