import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material";
import React from "react";

import { styles } from "./styles";

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

export const ToastMessage = ({ message, type, alertTitle, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <Alert
        variant="filled"
        severity={type}
        onClose={onClose}
        sx={styles.alert}
      >
        <AlertTitle>{alertTitle}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};
