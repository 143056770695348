import { IconButton, Stack, Tooltip as MuiTooltip } from "@mui/material";
import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";

import { styles } from "./styles";
import { palette } from "../../../../theme/palette";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const PerformanceChart = ({ favourite }) => {
  const [isLineChart, setIsLineChart] = useState(true);

  const toggleChart = () => {
    setIsLineChart((prevState) => !prevState);
  };

  const chartData = {
    labels: Object.keys(favourite.data.Values),
    datasets: [
      {
        label: favourite.name,
        data: Object.values(favourite.data.Values),
        borderColor: palette.primary.main,
        backgroundColor: palette.primary.main,
      },
    ],
  };

  return (
    <Stack sx={styles.chartContainer}>
      <MuiTooltip title="chart toggle">
        <IconButton sx={styles.chartButton} onClick={toggleChart}>
          {isLineChart ? (
            <TimelineIcon sx={styles.chartIcon} />
          ) : (
            <BarChartIcon sx={styles.chartIcon} />
          )}
        </IconButton>
      </MuiTooltip>
      {isLineChart ? <Line data={chartData} /> : <Bar data={chartData} />}
    </Stack>
  );
};
