export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 890,
    py: 7,
    px: 6,
    borderRadius: 5,
    bgcolor: "white.main",
  },
  close: {
    position: "absolute",
    top: 5,
    right: 5,
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: "red.error",
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 40,
    lineHeight: 1.1,
    color: "primary.main",
  },
  image: {
    width: 288,
    height: 345,
    fill: "transparent",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#979797!important",
    marginBottom:'.5rem'
  },
  textField: {
    marginTop:'0 !important',
    "& .MuiOutlinedInput-root": {
      height: 46,
      "& input": {
        fontFamily: "Roboto",
        fontSize: 18,
        letterSpacing: "-0.015em",
        color: "black.main",
      },
      "& fieldset": {
        borderColor: "black.main",
        boxShadow: 0,
      },
      "&:hover fieldset": {
        borderColor: "primary.main",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main",
      },
    },
  },
  button: (action) => ({
    color: action === "cancel" ? "red.error" : "primary.main",
  }),
};
