import { useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";
import { useAuthContext } from "../../../../context";
import { apiService } from "../../../../services";
import { loginSchema } from "../../../../validation";
import { Button } from "../../../../components";

export const LoginForm = ({ isMobile }) => {
  const { onSuccessAuth } = useAuthContext();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [responseError, setResponseError] = useState({
    email: "",
    password: "",
  });

  const { mutate } = useMutation(
    ({ username, password }) => apiService.login({ username, password }),
    {
      onSuccess: (response) => {
        onSuccessAuth(response.data.token, response.data.expiry);
        navigate("/workspace");
      },
      onError: (response) => {
        if (response.response.data.message.includes("password")) {
          setResponseError((prevState) => ({
            ...prevState,
            password: response.response.data.message,
          }));
        }

        if (response.response.data.message.includes("user")) {
          setResponseError((prevState) => ({
            ...prevState,
            email: response.response.data.message.split(".")[0],
          }));
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: ({ email, password }) => {
      mutate({ username: email, password });
    },
  });

  const handleInputChange = (event) => {
    formik.handleChange(event);

    setResponseError((prevState) => ({
      ...prevState,
      [event.target.name]: "",
    }));
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box textAlign={isMobile ? "center" : "left"}>
        <Typography component="h3" sx={styles.title} textAlign={'center'}>
          Log in to BloX
        </Typography>

        <Stack
          sx={styles.inputsContainer}
          mb={isMobile ? "0px" : "24px"}
          mr={isMobile ? 0 : 2}
        >
          <TextField
            name="email"
            type="email"
            label="Enter your email address"
            value={formik.values.email}
            onChange={handleInputChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              (formik.touched.email && formik.errors.email) ||
              (responseError.email && (
                <Typography sx={styles.error}>{responseError.email}</Typography>
              ))
            }
            inputProps={{ style: styles.input }}
          />
          <TextField
            name="password"
            type="password"
            autoComplete="on"
            label="Now choose a password"
            value={formik.values.password}
            onChange={handleInputChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              (formik.touched.password && formik.errors.password) ||
              (responseError.password && (
                <Typography sx={styles.error}>
                  {responseError.password}
                </Typography>
              ))
            }
            inputProps={{ style: styles.input }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "remember me" }}
              />
            }
            label="Remember me"
            sx={{
              alignSelf: "flex-start",
            }}
          />
          <Typography sx={styles.forgotPassword}>
            Forgot your password?
          </Typography>
        </Stack>

        <Stack>
          <Button
            submit
            isLarge={false}
            endIcon={<ChevronRightIcon />}
            variant="contained"
            bgcolor="primary.main"
          >
            Log in
          </Button>
        </Stack>
      </Box>
    </form>
  );
};
