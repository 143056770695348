import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { setTokenWithExpiry, checkToken } from "../utils";
import { useOnLocationChange } from "../hooks/useOnLocationChange";

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const isAuthenticated = useOnLocationChange(checkToken);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("BLOX_USER_TOKEN");
    localStorage.removeItem("BLOX_USER_TOKEN_EXPIRY");
    navigate("/");
  }, [navigate]);

  const handleSuccessAuth = useCallback((token, tokenExpiryTime) => {
    // setTokenWithExpiry(token, tokenExpiryTime);
        setTokenWithExpiry(token);

  }, []);

  const value = useMemo(
    () => ({
      isAuth: isAuthenticated,
      onLogout: handleLogout,
      onSuccessAuth: handleSuccessAuth,
    }),
    [handleLogout, handleSuccessAuth, isAuthenticated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthContext = React.createContext();

export const useAuthContext = () => {
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return authContext;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
