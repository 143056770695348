import { createTheme } from "@mui/material/styles";

import { globalStyles } from "./globalStyles";
import { palette } from "./palette";

export const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: globalStyles,
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (min-width: 600px)": {
            paddingLeft: "0px",
            paddingRight: "0px",
            overflow: "hidden",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Comfortaa",
          letterSpacing: "-0.015em",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 20,
          fontFamily: "Roboto",
          lineHeight: 2.95,
          backgroundColor: palette.secondary.main,
          transition: "all 275ms",
          "&:hover": {
            backgroundColor: palette.primary.dark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& input": {
            backgroundColor: palette.white.main,
          },
          "& fieldset": {
            borderColor: palette.primary.main,
            boxShadow: palette.inputShadow,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid",
          borderColor: palette.grey.main,
          borderRadius: 10,
          backgroundColor: "#353535",
          boxShadow: palette.inputShadow,
        },
      },
    },
  },
  palette: {
    mode: "dark",
    background: { default: "#212121" },
    text: { primary: "#ffffff" },
  },
});
