import React, { useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../services";
import {
  Loader,
  ModelLibrarySidebar,
  ModelLibraryCard,
  ModelLibraryInput,
  CreateModelModal,
} from "../../components";
import { PointerIcon } from "../../icons";
import NavigationSidebar from "../../components/NavigationSidebar";

export const FinancialModels = () => {
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelId, setModelId] = useState(null);

  const { data, isLoading } = useQuery("financialModels", () =>
    apiService.getLibraryModels({ params: { search: "finance" } })
  );

  const toggleCreateModelModal = (modelId) => {
    setIsModalOpen((prevState) => !prevState);

    if (typeof modelId === "number") {
      setModelId(modelId);
    } else {
      setModelId(null);
    }
  };

  const searchModel = (event) => {
    setSearch(event.target.value);
  };

  const filteredLibraryModel = useMemo(() => {
    return data?.data?.models?.filter((model) =>
      model?.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  }, [data?.data?.models, search]);

  return (
    <Stack height="100%" direction="row">
      <NavigationSidebar />

      <Stack sx={styles.content} spacing={3}>
        <Typography sx={styles.pageTitle}>BloX Model Library</Typography>

        <Box sx={styles.pageGrid}>
          <PointerIcon />

          <Stack sx={{ width: 890 }}>
            <Typography sx={styles.goalTitle}>
              Select a model template below or tell us your goal to get started
              quickly
            </Typography>

            <ModelLibraryInput value={search} onChange={searchModel} />
          </Stack>

          <Typography sx={styles.chapterTitle}>Explore</Typography>
          <Typography sx={styles.chapterTitle}>Finance</Typography>

          <ModelLibrarySidebar />

          {isLoading ? (
            <Loader sx={styles.loader} />
          ) : (
            <Box sx={styles.cardGrid}>
              {filteredLibraryModel?.map((model) => (
                <ModelLibraryCard
                  key={model?.id}
                  model={model}
                  onClick={() => toggleCreateModelModal(model?.id)}
                />
              ))}
            </Box>
          )}
        </Box>
      </Stack>

      <CreateModelModal
        open={isModalOpen}
        onClose={toggleCreateModelModal}
        modelId={modelId}
      />
    </Stack>
  );
};
