export const styles = {
  loader: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
  droppable: {
    boxShadow: 0,
    height: "100%",
    overflow: "hidden",
  },
  tableContainer: (isAddingIndicator) => ({
    height: "100%",
    filter: isAddingIndicator ? "blur(3px)" : "none",

    overflow: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar:horizontal": {
      height: 8,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-thumb:horizontal": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  }),
  headerRow: {
    height: 65,
    "& th": {
      borderTop: 0,
      borderLeft: 0,
      borderBottom: "1px solid",
      borderColor: "grey.main",
    },
    "& th:not(:last-child)": {
      borderRight: "1px solid",
      borderColor: "grey.main",
    },
  },
  tableCell: (type) => ({
    minWidth: type === "name" ? 400 : 130,
    py: 0,
    px: type === "name" ? 2 : 0,
    textAlign: type === "name" ? "left" : "center",
    cursor: "default",
  }),
  columnName: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 20,
    color: "black.main",
  },
  tableBody: {
    "& > .MuiTableRow-root:last-child": {
      "& > td": {
        "& > tr:first-of-type": {
          "& th, & td": {
            borderBottom: 0,
          },
        },
      },
    },
  },
};
