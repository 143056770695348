import { Box, Typography, Stack } from "@mui/material";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../services";
import { CreateModelButton, ModelCard } from "./components";
import {
  Loader,
  CreateModelModal,
  ToastMessage,
} from "../../components";
import NavigationSidebar from "../../components/NavigationSidebar";

export const WorkSpace = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelId, setModelId] = useState(null);
  const [allCreatedModels, setAllCreatedModels] = useState([]);
  const queryClient = useQueryClient();
  const [addModelError, setAddModelError] = useState({
    isError: false,
    message: "",
  });

  const { isLoading } = useQuery("models", () => apiService.getModels(), {
    onSuccess: (response) => {
      if(response)
      setAllCreatedModels(response.data.models);
    },
  });

  const handleDuplicate = () => {
    queryClient.setQueryData("models");
  }

  const toggleCreateModelModal = (modelId) => {
    setIsModalOpen((prevState) => !prevState);

    if (typeof modelId === "number") {
      setModelId(modelId);
    } else {
      setModelId(null);
    }
  };


  return (
    <Stack height="100%" direction="row">
      <NavigationSidebar />

      <Stack sx={styles.content} spacing={3}>
        <Typography sx={styles.pageTitle}>Your models</Typography>

        <Box sx={styles.cardsContainer}>
          <Stack spacing={3}>
            {isLoading ? (
              <Loader sx={styles.loader} />
            ) : (
              <Box sx={styles.ownModelGrid}>
                <CreateModelButton onClick={toggleCreateModelModal} />
                { allCreatedModels?.map((model) => (
                      <ModelCard key={model?.id} model={model} handleDuplicate={()=>handleDuplicate}/>
                    ))}
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>

      <CreateModelModal
        open={isModalOpen}
        onClose={toggleCreateModelModal}
        modelId={modelId}
        setAddModelError={setAddModelError}
        addModelError={addModelError}
      />

      <ToastMessage
        open={addModelError.isError}
        onClose={() => setAddModelError({ isError: false, message: "" })}
        message={addModelError.message}
        alertTitle="Warning"
        type="warning"
      />
    </Stack>
  );
};
