import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "./styles";
import { Button } from "../UI";

import { ReactComponent as DataIcon } from "../../assets/icons/dataIcon.svg";
import { ReactComponent as CsvIcon } from "../../assets/icons/csvIcon.svg";
import { ReactComponent as CsvCircleIcon } from "../../assets/icons/csvCircleIcon.svg";

const Input = styled("input")({
  display: "none",
});

export const AddDataModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modal}>
        <Stack sx={styles.header} direction="row" spacing={3}>
          <SvgIcon
            component={DataIcon}
            viewBox="0 0 83 78"
            sx={{ width: 82, height: 76 }}
          />
          <Typography component="h1" sx={styles.title}>
            Add data to your model
          </Typography>
        </Stack>

        <IconButton sx={styles.closeButton} onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>

        <Stack direction="row">
          <Box width="320px" textAlign="center" paddingRight={5}>
            <Typography sx={styles.subtitle} mb={4}>
              Enter values in a grid
            </Typography>
            <Typography sx={styles.desc}>
              (Basic mapping experience, mapping columns in the data file, to
              Indicators in the Model where the data should be uploaded)
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem sx={{ ml: 5 }} />

          <Box flex={1} textAlign="center">
            <Typography sx={styles.subtitle} mb={2}>
              Upload CSV file
            </Typography>
            <label htmlFor="icon-button-file">
              <Input accept="image/*" id="icon-button-file" type="file" />
              <IconButton
                aria-label="upload picture"
                component="span"
                sx={{ position: "relative" }}
              >
                <SvgIcon
                  component={CsvCircleIcon}
                  viewBox="0 0 96 96"
                  sx={{ width: 96, height: 96, fill: "red" }}
                />
                <SvgIcon
                  component={CsvIcon}
                  viewBox="0 0 38 44"
                  sx={{ width: 38, height: 44, position: "absolute" }}
                />
              </IconButton>
            </label>
          </Box>
        </Stack>

        <Stack direction="row" mt={6} justifyContent=" center" spacing={5}>
          <Button submit variant="contained" bgcolor="primary.main">
            Add data
          </Button>
          <Button variant="outlined" color="primary.main" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

AddDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
