import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../constants";

const initialState = {
  modelDetail: null,
  modelStatus: STATUS.IDLE,
};
const Userslice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {},
  extraReducers: (builder) => {
  },
});
export default Userslice.reducer;
