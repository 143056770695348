export const styles = {
  listTitle: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 2.44,
    letterSpacing: "-0.03em",
    color: "black.default",
  },
  listItem: (isCurrentPage) => ({
    fontWeight: 300,
    color: isCurrentPage ? "primary.dark" : "black.default",
  }),
  button: { p: 0, pl: 3 },
  nonClickable: { pointerEvents: "none" },
};
