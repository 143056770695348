import retailImg from "../assets/images/retail.png";
import commerceImg from "../assets/images/e-commerce.png";
import nonprofitImg from "../assets/images/non-profit.png";
import fmcgImg from "../assets/images/fmcg.png";

export const INDUSTRY_BLOCKS_MOCKUP = [
  {
    id: 6,
    name: "Retail",
    image: retailImg,
  },
  {
    id: 7,
    name: "e-Commerce",
    image: commerceImg,
  },
  {
    id: 8,
    name: "Non-Profit",
    image: nonprofitImg,
  },
  {
    id: 9,
    name: "FMCG",
    image: fmcgImg,
  },
  {
    id: 10,
    name: "Test",
    image: fmcgImg,
  },
];
