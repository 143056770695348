export const styles = {
  content: {
    height: "100%",
    width: "100%",
    pl: 9,
    pt: 3,
    bgcolor: "white.main",
    overflow: "hidden",
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: 48,
    lineHeight: 1.125,
    color: "primary.main",
  },
  pageGrid: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "240px 1fr",
    gridTemplateRows: "auto auto 1fr",
    columnGap: 4,
    rowGap: 4,
    overflow: "hidden",
  },
  goalTitle: {
    mb: 4,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.26,
    color: "black.main",
  },
  chapterTitle: {
    fontSize: 26,
    lineHeight: 1.115,
    letterSpacing: "-0.03em",
    color: "primary.main",
  },
  loader: { height: 230, justifyContent: "center", alignItems: "center" },
  cardGrid: {
    height: "100%",
    pb: 5,
    display: "grid",
    gridTemplateColumns: "repeat(3, 380px)",
    gridTemplateRows: 230,
    gap: 3.75,
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
};
