import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { styles } from "./styles";

export const ModelLibraryInput = ({ value, onChange, onKeyDown }) => {
  const { pathname } = useLocation();

  const getPlaceholder = (pathname) => {
    switch (pathname) {
      case "/library":
        return "E.g. Revenue Growth, Fundraising, Maximise Valuation etc";

      default:
        return "Search your model";
    }
  };

  return (
    <TextField
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={getPlaceholder(pathname)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">Your goal</InputAdornment>
        ),
      }}
      sx={styles.textField}
    />
  );
};

ModelLibraryInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};
