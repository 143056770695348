import { SvgIcon } from "@mui/material";
import React from "react";

import { ReactComponent as Pointer } from "../assets/icons/pointer.svg";

export const PointerIcon = () => {
  return (
    <SvgIcon
      component={Pointer}
      viewBox="0 0 239 186"
      sx={{ width: 239, height: 186, fill: "transparent" }}
    />
  );
};
