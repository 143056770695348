export const styles = {
  content: {
    height: "100%",
    width: "100%",
    pl: 9,
    pt: 3,
    bgcolor: "white.main",
  },
  pageTitle: (breadcrumbs) => ({
    fontWeight: breadcrumbs === 1 ? 400 : 600,
    fontSize: 48,
    lineHeight: 1.125,
    color: "primary.main",
  }),
  separator: {
    width: 32,
    height: 32,
    color: "primary.main",
  },
  buttonsSection: {
    pr: 14,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewBy: {
    fontSize: 26,
    lineHeight: 1.115,
    letterSpacing: "-0.03em",
  },
  cardsContainer: {
    pr: 14,
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
  grid: (viewMode) => ({
    pb: 5,
    display: viewMode === "grid" ? "grid" : "block",
    gridTemplateColumns: "repeat(auto-fill, minmax(296px, 1fr))",
    gridTemplateRows: 402,
    gap: 5,
  }),
};
