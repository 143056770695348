export const styles = {
  viewButton: (isCurrentView) => ({
    color: "primary.main",
    pointerEvents: isCurrentView ? "none" : "initial",

    "&.Mui-selected": {
      color: "white.main",
      backgroundColor: "secondary.main",
    },

    "&.MuiToggleButtonGroup-grouped": {
      border: 0,
      borderRadius: 0,
    },
  }),
};
