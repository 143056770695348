import { Divider, Menu, MenuItem } from "@mui/material";
import React from "react";

export const IndicatorTypeMenu = ({
  open,
  onClose,
  indicatorId,
  anchorEl,
  onUpdate,
}) => {
  const updateIndicatorType = (indicatorType) => {
    onUpdate({ indicatorId, data: { type: indicatorType } });
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        sx={{ py: 0, bgcolor: "white.main" }}
        onClick={() => updateIndicatorType("input")}
      >
        Input
      </MenuItem>
      <Divider sx={{ opacity: 0.5 }} />
      <MenuItem
        sx={{ py: 0, bgcolor: "white.main" }}
        onClick={() => updateIndicatorType("calculation")}
      >
        Calculation
      </MenuItem>
    </Menu>
  );
};
