import React, { useState } from "react";
import { Card, CardContent, SvgIcon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import card_bg from "../../../../assets/images/model_card-bg.png";
import { ReactComponent as ChartIcon } from "../../../../assets/icons/model_card-icon.svg";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { SettingsMenu, ToastMessage } from "../../../../components";

export const ModelCard = ({ model, handleDuplicate }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const openSettingsMenu = Boolean(anchorEl);
  const [duplicateModelError, setDuplicateModelError] = useState({
    isDuplicateError: false,
    message: "",
  });

  const { mutate: deleteModelMutation } = useMutation(
    (modelId) => apiService.deleteModel(modelId),
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          queryClient.setQueryData("models", (oldQueryData) => {
            const filteredModels = oldQueryData?.data?.models?.filter(
              (model) => model?.id !== response?.data?.id
            );

            return {
              ...oldQueryData,
              data: {
                models: filteredModels,
              },
            };
          });
        }
      },
    }
  );

  const { mutate: updateModelMutation } = useMutation(
    ({ id, newName }) => apiService.updateModel(id, newName),
    {
      onSuccess: (response) => {
        if (response.status === 201 || response.status === 200) {
          queryClient.setQueryData("models", (oldQueryData) => {
            const updatedModels = oldQueryData?.data?.models?.map((model) => {
              if (model?.id === response?.data?.id) {
                return {
                  ...model,
                  name: response?.data?.name,
                  icon: response?.data?.icon,
                };
              }
              return model;
            });

            return {
              ...oldQueryData,
              data: {
                models: updatedModels,
              },
            };
          });
        }
      },
    }
  );

  const { mutate: duplicateModelMutation } = useMutation(
    ({ modelId, modelName }) =>
      apiService.addModelFromLibrary(modelId, modelName),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          handleDuplicate();
          queryClient.invalidateQueries("models");
        }
        closeSettings();
      },

      onError: (response) => {
        setDuplicateModelError({
          isDuplicateError: true,
          message: response?.response?.data?.message,
        });
      },
    }
  );

  const openSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSettings = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        style={{
          backgroundImage: model.icon
            ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${model.icon})`
            : `url(${card_bg})`,
        }}
        sx={styles.card}
      >
        <MoreHorizIcon onClick={openSettings} sx={styles.settingIcon} />

        <CardContent
          onClick={() => navigate(`/model/${model?.id}`)}
          sx={styles.cardContent}
        >
          <SvgIcon
            component={ChartIcon}
            viewBox="0 0 48 43"
            sx={styles.chartIcon}
          />
          <Typography sx={styles.title}>{model?.name}</Typography>
        </CardContent>

        <SettingsMenu
          anchorEl={anchorEl}
          data={model}
          target="model"
          open={openSettingsMenu}
          onClose={closeSettings}
          onDelete={deleteModelMutation}
          onUpdate={updateModelMutation}
          onDuplicate={duplicateModelMutation}
        />
      </Card>

      <ToastMessage
        open={duplicateModelError.isDuplicateError}
        onClose={() =>
          setDuplicateModelError({ isDuplicateError: false, message: "" })
        }
        message={duplicateModelError.message}
        alertTitle="Error"
        type="error"
      />
    </>
  );
};
