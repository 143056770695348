export const styles = {
  card: {
    width: 250,
    height: 135,
    boxShadow: 0,
  },
  actionArea: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    pt: 1,
    px: 2,
  },
  cardContent: {
    p: 0,
    pb: 2,
  },
  cardTitle: {
    fontSize: 18,
    lineHeight: 1.15,
    letterSpacing: "-0.03em",
    color: "primary.main",
  },
  imageContainer: {
    height: 120,
    "& > img": {
      objectFit: "scale-down",
    },
  },
};
