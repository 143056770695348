import {
  FormControlLabel,
  FormGroup,
  Switch as MuiSwitch,
} from "@mui/material";
import React from "react";

import { styles } from "./styles";

export const Switch = ({
  data,
  selectedDimensions,
  onSelect,
  isDimensionsModal,
}) => {
  const handleChange = () => {
    if (isDimensionsModal) {
      onSelect((prevState) =>
        prevState?.dimension_id?.includes(data?.id)
          ? {
              dimension_id: prevState?.dimension_id?.filter(
                (id) => id !== data?.id
              ),
            }
          : {
              dimension_id: [...(prevState?.dimension_id || []), data?.id],
            }
      );
    } else {
      onSelect((prevState) =>
        prevState?.some((dimension) => dimension?.id === String(data?.id))
          ? prevState?.filter((obj) => obj?.id !== String(data?.id))
          : [...(prevState || []), { id: String(data?.id) }]
      );
    }
  };

  const isModelDimensionsChecked = (selectedDimensions) => {
    if (selectedDimensions === undefined) {
      return false;
    }
    return selectedDimensions?.includes(data?.id);
  };

  const isBlockDimensionsChecked = (selectedDimensions) => {
    if (selectedDimensions === undefined) {
      return false;
    }
    return selectedDimensions?.some(
      (dimension) => dimension?.id === String(data?.id)
    );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <MuiSwitch
            sx={styles.switch}
            checked={
              isDimensionsModal
                ? isModelDimensionsChecked(selectedDimensions)
                : isBlockDimensionsChecked(selectedDimensions)
            }
            onChange={handleChange}
          />
        }
        label={data?.name}
      />
    </FormGroup>
  );
};
