export * from "./userMockup";
export * from "./departmentBlocksMockup";
export * from "./industryBlocksMockup";
export * from "./dataTypeMockup";
export * from "./formatMockup";
export * from "./scaleMockup";
export * from "./currencyOptions";
export * from "./outputFormat";

export const STATUS = Object.freeze({
  IDLE: "IDLE",
  FAILED: "FAILED",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
});
