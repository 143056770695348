import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  createScenarios,
  fetchScenarios,
  renameScenario,
} from "../../../../../Redux/ScenarioSlice";
import { styles } from "./styles";
import { ToastMessage } from "../../../../../components";

const validationSchema = Yup.object({
  scenarioName: Yup.string().required("Scenario name is required"),
  // scenarioDescription: Yup.string().required(
  //   "Scenario description is required"
  // ),
});

const FormPopover = ({
  anchorEl,
  onClose,
  initialValues,
  renameScenario,
  getScenarios,
  createScenario,
  scenarioList,
}) => {
  const { modelId } = useParams();
  const open = Boolean(anchorEl);
  const popoverId = open ? "form-popover" : undefined;
  const [currentScenerio, setCurrentScenerio] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const baseScenerio = scenarioList?.scenarios.find(
      (scenerio) => scenerio?.is_base
    );
    if (baseScenerio) {
      setCurrentScenerio(baseScenerio?.id);
    }
  }, [scenarioList?.scenarios]);

  const handleSubmit = async (values, { resetForm }) => {
    const requestBody = {
      scenarioId: values?.scenarioId,
      data: {
        name: values?.scenarioName,
        description: values?.scenarioDescription,
      },
    };
    const crearteRequestBody = {
      modelId: modelId,
      data: {
        name: values?.scenarioName,
        description: values?.scenarioDescription,
        duplicate_id: currentScenerio,
      },
    };
    onClose();
    if (
      values?.isRename === true
        ? (await renameScenario(requestBody),
          setSuccessMessage("Scenario name have been updated successfully"),
          await getScenarios(modelId))
        : (await createScenario(crearteRequestBody),
          setSuccessMessage("Scenario have been created successfully"),
          await getScenarios(modelId))
    )
      resetForm();
  };
  const handleSelectChange = (event) => {
    setCurrentScenerio(event.target.value);
  };

  const cancelData = () => {
    onClose();
  };
  return (
    <>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "end",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            borderRadius: "11px",
          },
        }}
      >
        <Box sx={styles.formPopoverContent}>
          <Typography sx={styles.addScenarioText}>Enter details</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, handleChange }) => {
              return (
                <Form style={{ flexGrow: 1 }}>
                  <FormLabel sx={styles.formText}>Name</FormLabel>
                  <Field
                    name="scenarioName"
                    as={TextField}
                    placeholder="Enter Name"
                    variant="outlined"
                    fullWidth
                    sx={styles.formField}
                    inputProps={{
                      style: { padding: "1.5px 14px" },
                    }}
                    helperText={
                      <div style={{ color: "red" }}>
                        <ErrorMessage name="scenarioName" />
                      </div>
                    }
                  />
                  <FormLabel sx={styles.formText}>Description</FormLabel>
                  <Field
                    name="scenarioDescription"
                    as={TextField}
                    placeholder="Enter Description"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      style: { padding: "1.5px 14px" },
                    }}
                    sx={styles.formField}
                    helperText={
                      <div style={{ color: "red" }}>
                        <ErrorMessage name="scenarioDescription" />
                      </div>
                    }
                  />
                  <FormLabel sx={styles.formText}>Duplicate from:</FormLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      disabled={Boolean(initialValues?.scenarioId)}
                      id="demo-simple-select"
                      sx={styles.scenarioselect}
                      MenuProps={{
                        PaperProps: {
                          sx: styles.menuBox,
                        },
                      }}
                      onChange={handleSelectChange}
                      value={currentScenerio}
                      renderValue={(scenerio) => {
                        const selectedId = scenarioList?.scenarios.find(
                          (scen) => scen?.id === scenerio
                        );
                        return `${selectedId?.name}`;
                      }}
                    >
                      {scenarioList?.scenarios &&
                        scenarioList?.scenarios?.map((scenario) => (
                          <MenuItem
                            key={scenario?.id}
                            value={scenario?.id}
                            sx={styles.menuItem}
                          >
                            {scenario?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <Box sx={{ ...styles.buttonsContainer }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={styles.button}
                      onClick={cancelData}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={styles.button}
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Popover>
      <ToastMessage
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        alertTitle="Success"
        type="success"
      />
    </>
  );
};
const stateToProperty = (state) => {
  return {
    scenarioList: state.scenarioSlice?.scenarioList,
    scenarioListStatus: state.scenarioSlice?.scenarioListStatus,
  };
};
const mapDispatchToProperties = (dispatch) => {
  return {
    renameScenario: (requestBody) => dispatch(renameScenario(requestBody)),
    createScenario: (requestBody) => dispatch(createScenarios(requestBody)),
    getScenarios: (modelId) => dispatch(fetchScenarios(modelId)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(FormPopover);
