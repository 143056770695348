export const styles = {
  pageContent: {
    height: "100%",
    width: "100%",
    pl: 9,
    pt: 3,
    bgcolor: "white.main",
    overflow: "hidden",

    "& .split": {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      overflow: "hidden",

      "& .gutter": {
        bgcolor: "white.main",
        borderLeft: "1px solid",
        borderRight: "1px solid",
        borderColor: "grey.border",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
      },

      "& .gutter.gutter-horizontal": {
        backgroundImage:
          "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')",
        cursor: "col-resize",
      },
    },
  },
  pageTitle: (breadcrumbs) => ({
    fontWeight: breadcrumbs === 1 ? 400 : 600,
    fontSize: 48,
    lineHeight: 1.125,
    color: "primary.main",
    cursor: breadcrumbs === 1 ? "pointer" : "initial",
    "&:hover": {
      textDecoration: breadcrumbs === 1 ? "underline" : "none",
      textDecorationThickness: 2,
    },
  }),
  separator: {
    width: 32,
    height: 32,
    color: "primary.main",
  },
  tabs: {
    height: "100%",
    pr: 4,
    pb: 2,
    overflow: "hidden",
  },
  tabsNavigation: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
  },
  tabButton: (isCurrentTab) => ({
    width: 144,
    height: 65,
    borderRadius: 5,
    fontFamily: "Comfortaa",
    fontWeight: 700,
    fontSize: 34,
    lineHeight: 1.12,
    textTransform: "capitalize",
    color: isCurrentTab ? "primary.main" : "black.main",
    bgcolor: isCurrentTab ? "grey.border" : "white.main",
    pointerEvents: isCurrentTab ? "none" : "initial",
    transition: "all 275ms",
    "&:not(:last-child)": {
      mr: 1,
    },
  }),
  performanceButton: (performanceOpen) => ({
    display: performanceOpen ? "none" : "inline-flex",
    ml: "auto",
    border: "1px solid",
    borderColor: "secondary.main",
    borderRadius: 0,
    width: 40,
    height: 40,
    color: "secondary.main",
    bgcolor: "white.main",
    "&:hover": {
      color: "white.main",
      bgcolor: "secondary.main",
    },
  }),
  tabsContent: {
    height: "100%",
    overflow: "hidden",
  },
};
