import { TextField } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";

import { styles } from "./styles";

export const IndicatorNameEditField = ({
  indicatorName,
  indicatorId,
  setIsEditError,
  onCloseEditField,
  onUpdateIndicator,
}) => {
  const formik = useFormik({
    initialValues: { name: indicatorName },
    onSubmit: ({ name }) => {
      if (name?.trim().length === 0) {
        setIsEditError(true);
        return;
      }

      onUpdateIndicator({
        indicatorId,
        data: { name },
      });
      onCloseEditField(indicatorId);
      setIsEditError(false);
    },
  });

  const pressESC = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onCloseEditField(indicatorId);
      }
    },
    [indicatorId, onCloseEditField]
  );

  useEffect(() => {
    window.addEventListener("keydown", pressESC);

    return () => {
      window.removeEventListener("keydown", pressESC);
    };
  }, [pressESC]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      onKeyDown={(event) => {
        if (event.code === 13) {
          formik.handleSubmit();
        }
      }}
    >
      <TextField
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={() => onCloseEditField(indicatorId)}
        autoFocus
        fullWidth
        autoComplete="off"
        sx={styles.textField}
        inputProps={{
          style: styles.indicatorName,
        }}
      />
    </form>
  );
};
