export const styles = {
  title: {
    fontWeight: 700,
    fontSize: 65,
    lineHeight: 1.13,
  },
  desc: {
    mb: 2,
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.87,
    textAlign: "center",
  },
  subtitle: {
    mb: 2,
    fontWeight: 700,
    fontSize: 36,
    lineHeight: 1.2,
    textAlign: "center",
  },
  dividerText: {
    fontWeight: 700,
    fontSize: 12,
    color: "primary.main",
  },
};
