import React from "react";
import ReactSlider from "react-slick";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { styles } from "./styles";

const NavigationButton = ({ onClick, direction, isIndicatorDimensions }) => (
  <IconButton
    onClick={onClick}
    sx={styles.navigationButton(onClick, direction, isIndicatorDimensions)}
  >
    {direction === "next" ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
  </IconButton>
);

export const Slider = ({
  children,
  slidesToShow = 4,
  slidesToScroll = 1,
  isIndicatorDimensions,
}) => {
  const sliderSettings = {
    infinite: false,
    slidesToShow,
    slidesToScroll,
    nextArrow: (
      <NavigationButton
        direction="next"
        isIndicatorDimensions={isIndicatorDimensions}
      />
    ),
    prevArrow: (
      <NavigationButton
        direction="prev"
        isIndicatorDimensions={isIndicatorDimensions}
      />
    ),
  };

  return <ReactSlider {...sliderSettings}>{children}</ReactSlider>;
};
