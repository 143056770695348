export const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: 5,
    },
  },
  dialogTitle: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 18,
  },
  dialogContent: { fontFamily: "Roboto", fontSize: 18 },
  button: (action) => ({
    color: action === "cancel" ? "red.error" : "primary.main",
    fontFamily: "Roboto",
  }),
};
