export const styles = {
  dimensionBlock: (selectedId, dimensionId, editClicked) => ({
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor:
      selectedId === dimensionId && !editClicked ? "grey.border" : "initial",
    cursor: "pointer",
    transition: "all 275ms",
  }),
  textField: {
    paddingLeft: 2,
  },
  input: {
    fontFamily: "Roboto",
    fontSize: 24,
    lineHeight: 1.17,
  },
  dimensionName: {
    fontFamily: "Roboto",
    fontSize: 24,
    lineHeight: 1.17,
    mr: "auto",
    pl: 2,
  },
  actionButton: (label) => ({
    color:
      label === "edit"
        ? "secondary.main"
        : label === "delete"
        ? "red.main"
        : "grey.secondary",
  }),
};
