export const breakpoints = {
  login: "/login",
  register: "/signup",
  userInfo: "/user_info", // TODO: update endpoint

  userModels: "/user/models",
  models: "/models",
  model: (modelId) => `/model/${modelId}`,
  syncModels: (modelId) => `model/${modelId}/sync`,
  modelFavourites: (modelId) => `/model/${modelId}/favourites/data`,

  blocks: (modelId) => `/model/${modelId}/blocks`,
  blocks_full: (modelId) => `/model/${modelId}/blocks_full`,
  block: (blockId) => `/block/${blockId}`,
  blockValues: (blockId) => `/block/${blockId}/data_values`,
  blockDimensions: (blockId) => `/block/${blockId}/dimensions`,
  blockMap: (blockId) => `/block/${blockId}/map`,

  indicators: (blockId) => `/block/${blockId}/indicators`,
  indicatorsReorder: (blockId) => `/block/${blockId}/indicators/reorder`,
  indicator: (indicatorId) => `/indicator/${indicatorId}`,

  dimensions: (modelId) => `/model/${modelId}/dimensions`,
  dimension: (dimensionId) => `/dimension/${dimensionId}`,

  dimensionProperties: (dimensionId) => `/dimension/${dimensionId}/properties`,
  dimensionProperty: (propertyId) => `/dimension/property/${propertyId}`,

  dimensionItems: (dimensionId) => `/dimension/${dimensionId}/items`,
  dimensionItem: (itemId) => `/dimension/item/${itemId}`,

  dimensionReorder: (itemId) => `dimension/${itemId}/items/reorder`,
  dimensionPropertyReorder: (itemId) =>
    `dimension/${itemId}/items-properties/reorder`,

  dimensionItemProperties: (itemId) => `/dimension/item/${itemId}/properties`,
  dimensionItemsWithProperties: (dimensionId) =>
    `/dimension/${dimensionId}/items/properties`,

  indicatorDataInput: (indicatorId) => `/indicator/${indicatorId}/data_input`,
  indicatorDataInputDisplay: (indicatorId) =>
    `/indicator/${indicatorId}/data_input/display`,

  libraryModels: "/library/models",
  modelCopy: (modelId) => `/model/${modelId}/copy`,

  libraryBlocks: "/library/blocks",
  blockCopy: (blockId) => `/block/${blockId}/copy`,
  scenarios: (modelId) => `/model/${modelId}/scenarios`,
  deleteOneScenario: (scenariosId) => `/scenario/${scenariosId}`,
  renameScenario: (scenariosId) => `/scenario/${scenariosId}`,
  singleScenario: (scenariosId) => `/scenario/${scenariosId}`,
  createNewScenario: (modelId) => `/model/${modelId}/scenarios`,
  duplicateScenario: (scenariosId) => `/scenario/${scenariosId}/copy`,
};
