import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { apiService } from "../../../../services";
import { Map } from "../Map";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";
import { useSelector } from "react-redux";

export const MapTab = () => {
  const { blockId } = useParams();
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const scenarioId = useSelector(getSelectedScenario);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getBlockMap(blockId, {
          params: { scenario_id: scenarioId },
        });

        if (response.data.length > 0) {
          const sourceElements = response.data;
          const initialNodes = sourceElements.reduce((acc, sourceElement) => {
            if (sourceElement.hasOwnProperty("data")) {
              sourceElement.type = "indicator";
              sourceElement.id = String(sourceElement.id);
              sourceElement.position = { x: 0, y: 0 };
              acc.push(sourceElement);
            }
            return acc;
          }, []);

          const initialEdges = sourceElements.reduce((acc, sourceElement) => {
            if (sourceElement.hasOwnProperty("source")) {
              sourceElement.type = "smoothstep";
              sourceElement.animated = false;
              sourceElement.markerEnd = { type: "arrow" };
              acc.push(sourceElement);
            }
            return acc;
          }, []);

          setInitialNodes(initialNodes);
          setInitialEdges(initialEdges);
        }
      } catch (error) {
      }
    };

    fetchData();
  }, [scenarioId, blockId]);

  return <Map initialNodes={initialNodes} initialEdges={initialEdges} />;
};
