export const styles = {
  navigationButton: (onClick, direction, isIndicatorDimensions) => ({
    display: onClick === null ? "none" : "inline-flex",
    zIndex: 10,
    p: isIndicatorDimensions ? 0 : "default",
    position: "absolute",
    top: "50%",
    right: () => {
      if (direction === "next") {
        return 0;
      }

      if (direction === "prev") {
        return "100%";
      }
    },
    transform: () => {
      if (direction === "next") {
        return isIndicatorDimensions
          ? "translate(100%, -50%)"
          : "translateY(-50%)";
      }

      if (direction === "prev") {
        return isIndicatorDimensions
          ? "translate(-4px, -50%)"
          : "translate(-8px, -50%)";
      }
    },
    "&:hover": {
      bgcolor: isIndicatorDimensions ? "transparent" : "default",
    },
  }),
};
