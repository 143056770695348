import { Button as MuiButton } from "@mui/material";
import PropTypes from "prop-types";

export const Button = ({
  children,
  submit,
  isLarge,
  color,
  bgcolor,
  ...props
}) => (
  <MuiButton
    type={submit ? "submit" : "button"}
    sx={{
      width: isLarge ? 207 : 135,
      height: isLarge ? 65 : 45,
      borderRadius: isLarge ? 8 : 5,
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: isLarge ? 24 : 18,
      textTransform: "none",
      color,
      bgcolor,
      border: props.variant === "outlined" ? "1px solid" : null,
      borderColor: color,
    }}
    {...props}
  >
    {children}
  </MuiButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.bool,
  isLarge: PropTypes.bool,
  color: PropTypes.string,
  bgcolor: PropTypes.string,
};
