import React from "react";
import { TextField } from "@mui/material";

export const TableCellInput = ({ name, value, onChange }) => {
  return (
    <TextField
      name={name}
      value={value}
      onChange={onChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& input": {
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: 18,
            letterSpacing: "-0.015em",
            color: "black.main",
          },
          "& fieldset": {
            borderColor: "white.main",
            height: "100%",
            borderRadius: 0,
            boxShadow: 0,
          },
          "&:hover fieldset": {
            borderColor: "transparent",
          },
          "&.Mui-focused fieldset": {
            borderColor: "transparent",
          },
        },
      }}
    />
  );
};
