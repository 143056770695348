import axios from "axios";
import create from "zustand";

import { setTokenWithExpiry } from "../utils/localStorage";
import { breakpoints } from "./breakpoints";

const { REACT_APP_DOMAIN_URL } = process.env;

export const useErrorsStore = create(() => ({
  hasError: false,
  error: undefined,
  date: undefined,
}));

class ApiService {
  constructor() {
    this.instance = axios.create({
      baseURL: REACT_APP_DOMAIN_URL,
    });

    this.instance.interceptors.request.use(async (config) => {
      const token = localStorage.getItem("BLOX_USER_TOKEN");
      config.headers.authorization = token;
      config.timeout = 60 * 4 * 1000;

      return config;
    });

    this.instance.interceptors.response.use(
      (response) => {
        setTokenWithExpiry(response.headers.token);
        if (response.data.ErrorCode !== 0) {
          useErrorsStore.setState({
            hasError: true,
            error: response.data.ErrorMessage,
            date: Date.now(),
          });
        }
        return response;
      },
      (error) => {
        useErrorsStore.setState({
          hasError: true,
          error,
          date: Date.now(),
        });
        return Promise.reject(error);
      }
    );
  }

  login = (data) => this.instance.post(breakpoints.login, data);
  register = (data) => this.instance.post(breakpoints.register, data);

  getModels = () => this.instance.get(breakpoints.userModels);
  getSyncModels = (modelId) =>
    this.instance.get(breakpoints.syncModels(modelId));
  addModel = (data) => this.instance.post(breakpoints.models, data);
  getModel = (modelId) => this.instance.get(breakpoints.model(modelId));
  updateModel = (modelId, data) =>
    this.instance.put(breakpoints.model(modelId), data);
  deleteModel = (modelId) => this.instance.delete(breakpoints.model(modelId));
  getModelFavourites = (modelId) =>
    this.instance.get(breakpoints.modelFavourites(modelId));

  getBlocks = (modelId) => this.instance.get(breakpoints.blocks(modelId));
  getBlocksFull = (modelId) =>
    this.instance.get(breakpoints.blocks_full(modelId));
  addBlock = (modelId, data) =>
    this.instance.post(breakpoints.blocks(modelId), data);
  getBlock = (blockId) => this.instance.get(breakpoints.block(blockId));
  updateBlock = async (blockId, data) => {
    await this.instance.put(breakpoints.block(blockId), data);
  };
  deleteBlock = (blockId) => this.instance.delete(breakpoints.block(blockId));

  getBlockValues = (blockId, params) =>
    this.instance.get(breakpoints.blockValues(blockId), params);
  getBlockValueswithParams = (blockId, params) =>
    this.instance.get(breakpoints.blockValues(blockId), params);
  getBlockMap = (blockId, params) =>
    this.instance.get(breakpoints.blockMap(blockId), params);

  getDimensionsOfBlock = (blockId) =>
    this.instance.get(breakpoints.blockDimensions(blockId));
  addDimensionsToBlock = (blockId, data) =>
    this.instance.post(breakpoints.blockDimensions(blockId), data);

  getIndicators = (blockId) =>
    this.instance.get(breakpoints.indicators(blockId));
  addIndicator = (blockId, data) =>
    this.instance.post(breakpoints.indicators(blockId), data);
  getIndicator = (indicatorId) =>
    this.instance.get(breakpoints.indicator(indicatorId));
  updateIndicator = (indicatorId, data) =>
    this.instance.patch(breakpoints.indicator(indicatorId), data);
  deleteIndicator = (indicatorId) =>
    this.instance.delete(breakpoints.indicator(indicatorId));
  reorderIndicators = (blockId, data) =>
    this.instance.patch(breakpoints.indicatorsReorder(blockId), data);

  getIndicatorInput = (indicatorId, params) =>
    this.instance.get(breakpoints.indicatorDataInput(indicatorId), params);
  addIndicatorInput = (indicatorId, data) =>
    this.instance.post(breakpoints.indicatorDataInput(indicatorId), data);
  overwriteIndicatorInput = (indicatorId, data) =>
    this.instance.put(breakpoints.indicatorDataInput(indicatorId), data);
  updateIndicatorInput = (indicatorId, data) =>
    this.instance.patch(breakpoints.indicatorDataInput(indicatorId), data);
  deleteIndicatorInput = (indicatorId) =>
    this.instance.delete(breakpoints.indicatorDataInput(indicatorId));

  getIndicatorInputDisplay = (indicatorId) =>
    this.instance.get(breakpoints.indicatorDataInputDisplay(indicatorId));

  getDimensions = (modelId) =>
    this.instance.get(breakpoints.dimensions(modelId));
  addDimension = (modelId, data) =>
    this.instance.post(breakpoints.dimensions(modelId), data);
  getDimension = (dimensionId) =>
    this.instance.get(breakpoints.dimension(dimensionId));
  updateDimension = (dimensionId, data) =>
    this.instance.patch(breakpoints.dimension(dimensionId), data);
  deleteDimension = (dimensionId) =>
    this.instance.delete(breakpoints.dimension(dimensionId));

  getDimensionProperties = (dimensionId) =>
    this.instance.get(breakpoints.dimensionProperties(dimensionId));
  addDimensionProperty = (dimensionId, data) =>
    this.instance.post(breakpoints.dimensionProperties(dimensionId), data);
  getDimensionProperty = (propertyId) =>
    this.instance.get(breakpoints.dimensionProperty(propertyId));
  updateDimensionProperty = (propertyId, data) =>
    this.instance.put(breakpoints.dimensionProperty(propertyId), data);
  deleteDimensionProperty = (propertyId) =>
    this.instance.delete(breakpoints.dimensionProperty(propertyId));

  getDimensionItems = (dimensionId, params) =>
    this.instance.get(breakpoints.dimensionItems(dimensionId), params);
  addDimensionItem = (dimensionId, data) =>
    this.instance.post(breakpoints.dimensionItems(dimensionId), data);
  getDimensionItem = (itemId) =>
    this.instance.get(breakpoints.dimensionItem(itemId));
  updateDimensionItem = (itemId, data) =>
    this.instance.put(breakpoints.dimensionItem(itemId), data);
  deleteDimensionItem = (itemId, params) =>
    this.instance.delete(breakpoints.dimensionItem(itemId), params);

  dimensionReorderData = (itemId, data) =>
    this.instance.patch(breakpoints.dimensionReorder(itemId), data);

  dimensionReorderColumn = (itemId, data) =>
    this.instance.patch(breakpoints.dimensionPropertyReorder(itemId), data);

  getDimensionItemProperties = (itemId) =>
    this.instance.get(breakpoints.dimensionItemProperties(itemId));
  updateDimensionItemProperty = (itemId, data) =>
    this.instance.put(breakpoints.dimensionItemProperties(itemId), data);

  getDimensionItemsWithProperties = (dimensionId, params) =>
    this.instance.get(
      breakpoints.dimensionItemsWithProperties(dimensionId),
      params
    );

  getLibraryModels = (params) =>
    this.instance.get(breakpoints.libraryModels, params);
  addModelFromLibrary = (modelId, data) =>
    this.instance.post(breakpoints.modelCopy(modelId), data);

  getLibraryBlocks = (data) =>
    this.instance.get(breakpoints.libraryBlocks, data);
  importBlockFromLibrary = (blockId, data) =>
    this.instance.post(breakpoints.blockCopy(blockId), data);
  getScenarios = (modelId) => this.instance.get(breakpoints.scenarios(modelId));
  deleteScenario = (scenarioId) =>
    this.instance.delete(breakpoints.deleteOneScenario(scenarioId));
  renameScenario = (scenarioId, data) =>
    this.instance.put(breakpoints.renameScenario(scenarioId), data);
  getSingleScenario = (scenarioId) =>
    this.instance.delete(breakpoints.singleScenario(scenarioId));
  createScenario = (modelId, data) =>
    this.instance.post(breakpoints.createNewScenario(modelId), data);
  createDuplicateScenario = (scenarioId, data) =>
    this.instance.post(breakpoints.duplicateScenario(scenarioId), data);
}

export const apiService = new ApiService();
