export const styles = {
  mapControls: {
    position: "absolute",
    alignItems: "center",
    left: 0,
    bottom: 0,
    zIndex: 10,
    fontSize: 12,
  },
};
