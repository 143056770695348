import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { Loader } from "../../../../components";
import { IndicatorRow } from "../IndicatorRow";
import { reorderIndicators } from "../../../../utils";
import { useSelector } from "react-redux";
import { getSelectedScenario } from "../../../../Redux/ScenarioSlice";

export const IndicatorsTable = ({
  requiredBlock,
  timeDimensionId,
  isAddingIndicator,
}) => {
  const { modelId, blockId } = useParams();
  const queryClient = useQueryClient();
  const [draggedIndicators, setDraggedIndicators] = useState([]);
  const [indicatorExpanded, setIndicatorExpanded] = useState({
    indicatorId: null,
    expanded: false,
  });
  const scenarioId = useSelector(getSelectedScenario);

  const { mutate: reorderIndicatorsMutation } = useMutation(
    ({ blockId, data }) => apiService.reorderIndicators(blockId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["block", blockId]);
        queryClient.invalidateQueries(["favorites", modelId]);
      },
    }
  );

  const {
    data: dimensionItems,
    isLoading: isDimenionItemLoading,
    refetch: dimItemsRefetch,
  } = useQuery(["dimensionItems", timeDimensionId, scenarioId], () =>
    apiService.getDimensionItems(timeDimensionId, {
      params: { scenario_id: scenarioId },
    })
  );
  useEffect(() => {
    dimItemsRefetch();
  }, [scenarioId, dimItemsRefetch]);

  useEffect(() => {
    setDraggedIndicators(requiredBlock?.indicators);
  }, [requiredBlock?.indicators]);

  const expandIndicator = (indicatorId, isExpanded) => {
    setIndicatorExpanded((prevState) => ({
      indicatorId,
      expanded:
        indicatorId === prevState.indicatorId && prevState.expanded === true
          ? false
          : isExpanded,
    }));
  };

  const columns = dimensionItems?.data?.items
    ? dimensionItems.data.items.map((item) => item.name)
    : [];

  const dimensionsToShowInsideIndicator = requiredBlock?.dimensions?.filter(
    (dimension) => dimension?.name !== "Time"
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const itemsDragged = reorderIndicators(
      draggedIndicators,
      result.source.index,
      result.destination.index
    );

    const mappedItemsDragged = itemsDragged.map((items) => items.id);

    setDraggedIndicators(itemsDragged);
    reorderIndicatorsMutation({
      blockId,
      data: { indicators: mappedItemsDragged },
    });
  };

  return (
    <>
      {isDimenionItemLoading ? (
        <Loader sx={styles.loader} />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={String(requiredBlock?.id)}>
            {(provided) => (
              <Paper
                sx={styles.droppable}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <TableContainer sx={styles.tableContainer(isAddingIndicator)}>
                  <Table stickyHeader sx={{ width: "auto" }}>
                    <TableHead>
                      <TableRow sx={styles.headerRow}>
                        <TableCell sx={styles.tableCell("name")} />

                        {columns?.map((column) => (
                          <TableCell key={column} sx={styles.tableCell("date")}>
                            <Typography sx={styles.columnName}>
                              {column}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody sx={styles.tableBody}>
                      {draggedIndicators.map((indicator, index) => (
                        <IndicatorRow
                          key={indicator?.id}
                          indicator={indicator}
                          index={index}
                          indicatorDimensions={dimensionsToShowInsideIndicator}
                          timeDimensionId={timeDimensionId}
                          columns={columns}
                          isExpanded={indicatorExpanded}
                          onExpand={expandIndicator}
                          indicatorsList={requiredBlock?.indicators}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {provided.placeholder}
              </Paper>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
