import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../constants";
import { apiService } from "../services";

export const fetchScenarios = createAsyncThunk(
  "scenario/fetch",
  async (modelId, thunkAPI) => {
    try {
      const response = await apiService.getScenarios(modelId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const renameScenario = createAsyncThunk(
  "scenarioRename/fetch",
  async (data, thunkAPI) => {
    try {
      const response = await apiService.renameScenario(
        data?.scenarioId,
        data.data
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const deleteScenarios = createAsyncThunk(
  "scenarioDelete/fetch",
  async (scenarioId, thunkAPI) => {
    try {
      const response = await apiService.deleteScenario(scenarioId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createScenarios = createAsyncThunk(
  "scenarioCreate/fetch",
  async (data, thunkAPI) => {
    try {
      const response = await apiService.createScenario(
        data?.modelId,
        data.data
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const duplicateScenarios = createAsyncThunk(
  "scenarioDuplicate/fetch",
  async (data, thunkAPI) => {
    try {
      const response = await apiService.createDuplicateScenario(
        data?.scenarioId,
        data.data
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getSingleScenarios = createAsyncThunk(
  "scenarioget/fetch",
  async (scenarioId, thunkAPI) => {
    try {
      const response = await apiService.getSingleScenario(scenarioId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  scenarioList: null,
  scenarioListStatus: STATUS.IDLE,
  selectedSceanrioId: null,
  isIndicatorAvailabe: false,
};
const ScenarioSlice = createSlice({
  initialState,
  name: "scenarioSlice",
  reducers: {
    currentScenario: (state, action) => {
      state.selectedSceanrioId = action.payload;
    },
    indicatorAvailable: (state, action) => {
      state.isIndicatorAvailabe = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScenarios.fulfilled, (state, action) => {
      state.scenarioList = action.payload;
      state.scenarioListStatus = STATUS.SUCCESS;
    });
  },
});
export const getSelectedScenario = (state) =>
  state.scenarioSlice.selectedSceanrioId;

export const { currentScenario, indicatorAvailable } = ScenarioSlice.actions;
export default ScenarioSlice.reducer;
