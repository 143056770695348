import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import SharedSlice from "./SharedSlice";
import ScenarioSlice from "./ScenarioSlice";
import UserSlice from "./UserSlice";
import ModelsSlice from "./ModelsSlice";
import IndicatorSlice from "./IndicatorSlice";

export const mainReducer = combineReducers({
  userSlice: UserSlice,
  modelsSlice: ModelsSlice,
  sharedSlice: SharedSlice,
  scenarioSlice: ScenarioSlice,
  indicatorSlice: IndicatorSlice,
});

const store = configureStore({
  reducer: mainReducer,
});
export default store;
