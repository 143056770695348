export const styles = {
  node: {
    width: 100,
    padding: 5,
    border: "1px solid",
    borderRadius: 5,
    backgroundColor: "white.main",
    color: "black.main",
  },
  label: { mb: 0.5, fontSize: 12, fontFamily: "Roboto", textAlign: "center" },
  chip: {
    py: 0.25,
    px: 0.5,
    borderRadius: 5,
    fontSize: 8,
    textAlign: "center",
    bgcolor: "primary.main",
    color: "white.main",
  },
};
