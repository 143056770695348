export const styles = {
  title: {
    mb: 3,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 26,
    lineHeight: 1.75,
  },
  inputsContainer: {
    justifyContent: "center",
    alignItems: "center",

    "& .MuiTextField-root:nth-of-type(odd)": {
      marginBottom: 1,
    },

    "& .MuiTextField-root:nth-of-type(even)": {
      marginBottom: 2,
    },

    "& .MuiTextField-root": {
      width: 340,
      // height: 40,
      "& fieldset": { borderRadius: 1, boxShadow: 0 },
    },
  },
  input: {
    fontSize: 15,
    lineHeight: 1.2,
  },
  forgotPassword: {
    alignSelf: "flex-end",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.71,
    textAlign: "center",
  },
  error: {
    color: "red.error",
    fontSize: 12,
    lineHeight: 1.66,
    letterSpacing: "0.033em",
  },
};
