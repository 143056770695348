import React, { createContext, useContext, useState } from "react";

export const LibraryModalContext = createContext();

export const useLibraryModalContext = () => {
  const context = useContext(LibraryModalContext);
  if (!context) {
    throw new Error(
      "useLibraryModalContext must be used within a LibraryModalProvider"
    );
  }
  return context;
};

export const LibraryModalProvider = ({ children }) => {
  const [isFirstLibraryModal, setIsFirstLibraryModal] = useState(false);
  const [isSecondLibraryModal, setIsSecondLibraryModal] = useState(false);

  const toggleSecondLibraryModal = () => {
    setIsSecondLibraryModal((prevState) => !prevState);
    setIsFirstLibraryModal(false);
  };

  const backToFirstLibraryModal = () => {
    setIsFirstLibraryModal(true);
    setIsSecondLibraryModal(false);
  };

  return (
    <LibraryModalContext.Provider
      value={{
        isFirstLibraryModal,
        isSecondLibraryModal,
        onToggleSecondLibraryModal: toggleSecondLibraryModal,
        onBackToFirstLibraryModal: backToFirstLibraryModal,
      }}
    >
      {children}
    </LibraryModalContext.Provider>
  );
};
