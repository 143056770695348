export const styles = {
  tableCell: (type, columnsNumber) => ({
    width: type === "switch" ? 350 : `calc(${columnsNumber} * 130px)`,
    maxWidth: type === "switch" ? 350 : `calc(${columnsNumber} * 130px)`,
    py: 0,
    px: 4,
    borderRight: type === "switch" ? "1px solid" : 0,
    borderColor: "grey.main",
    cursor: "default",

    "& .slick-list": {
      paddingLeft: 1.5,
    },
  }),
  saveButton: {
    color: "secondary.main",
  },
};
