export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    justifyContent: "space-between",
    width: 300,
    height: 330,
    p: 3,
    borderRadius: 5,
    bgcolor: "white.main",
  },
  searchIcon: {
    color: "secondary.main",
  },
  switchBlock: {
    height: 170,
    pl: 2,
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: 5,
      bgcolor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      bgcolor: "primary.main",
      borderRadius: 1,
    },
    "::-webkit-scrollbar-button": {
      bgcolor: "transparent",
      height: 5,
    },
  },
  actionButton: (action) => ({
    borderRadius: 2.5,
    height: "100%",
    fontWeight: 400,
    color: "white.main",
    textTransform: "none",
    bgcolor: action === "cancel" ? "red.error" : "primary.main",
    transition: "all 275ms",
    "&:hover": {
      bgcolor: action === "cancel" ? "red.main" : "primary.dark",
    },
  }),
};
