import { Box, Checkbox, FormControlLabel } from "@mui/material"
import { styles } from "./styles"
import { useMutation, useQueryClient } from "react-query";
import { apiService } from "../../../../services";
import { useCallback } from "react";

export const DefaultIndicator=({data,indicatorId})=>{
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
      ({ indicatorId, data }) => apiService.updateIndicator(indicatorId, data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["indicator", indicatorId]);
        },
      }
    );
  
    const onChangeIndicatorType = useCallback(
      (newType) => {
        mutate({
          indicatorId,
          data: { is_default_KPI: newType },
        });
      },
      [indicatorId, mutate]
    );
    return(
        <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={data?.is_default_KPI===true}
            onChange={(e)=>{onChangeIndicatorType(e.target.checked)}}
            inputProps={{
              "aria-label": "toggle of default formatting properties",
            }}
          />
        }
        label="Make default indicator"
        sx={styles.checkboxWithLabel}
      />
        </Box>
    )
}