import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import { styles } from "./styles";
import { BlockRow } from "../BlockRow";

export const ListView = ({ blocks, onDelete, onUpdate }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ height: "auto" }}>
            <TableCell sx={{ ...styles.tableHeader, pl: "9%" }}>Name</TableCell>
            <TableCell sx={styles.tableHeader}>Owner</TableCell>
            <TableCell sx={styles.tableHeader}>Dimensions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blocks?.map((block) => (
            <BlockRow
              key={block?.id}
              block={block}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
