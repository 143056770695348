import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import { styles } from "./styles";

export const CircleAddButton = ({ label, icon, onClick }) => {
  const getButtonIcon = (icon) => {
    switch (icon) {
      case "book":
        return <MenuBookIcon sx={styles.buttonIcon} />;

      default:
        return <AddIcon sx={styles.buttonIcon} />;
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton sx={styles.button} onClick={onClick}>
        {getButtonIcon(icon)}
      </IconButton>
      <Typography sx={styles.buttonTitle}>{label}</Typography>
    </Stack>
  );
};
