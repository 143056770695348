export const styles = {
  mediaContainer: {
    "& p:not(:last-child)": {
      mt: 2.5,
      mb: 1,
    },
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.14,
  },
};
