import { Checkbox, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { styles } from "./styles";
import { apiService } from "../../../../services";

export const PropertiesSettings = ({ property, dimensionsList }) => {
  const { id, type, dim_driver, data_format, dimension_id } = property;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ propertyId, data }) =>
      apiService.updateDimensionProperty(propertyId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dimension_properties", dimension_id]);
      },
    }
  );

  const onChangeType = useCallback(
    (event) => {
      mutate({
        propertyId: id,
        data: { type: event.target.value },
      });
    },
    [id, mutate]
  );
  const onChangeDefaultvalue = useCallback(
    (event) => {
      mutate({
        propertyId: id,
        data: { default_value: event.target.value },
      });
    },
    [id, mutate]
  );

  const onChangeDataFormat = useCallback(
    (event) => {
      mutate({
        propertyId: id,
        data: {
          data_format: event.target.value === null ? 'null' :  JSON.stringify({
            dimension: event.target.value,
          }) 
      }
      });
    },
    [id, mutate]
  );

  const onChangeIsDimensionDriver = useCallback(
    (event) => {
      mutate({
        propertyId: id,
        data: { dim_driver: event.target.checked },
      });
    },
    [id, mutate]
  );

  useEffect(() => {
    if (type === "number") {
      mutate({
        propertyId: id,
        data: {
          data_format: JSON.stringify({ dimension: null }),
        },
      });
    }
  }, [id, mutate, type]);

  return (
    <>
      <Stack direction="row" spacing="auto" alignItems="space-between">
        <Typography sx={styles.sectionSubtitle}>Type</Typography>
        <Select
          labelId="type-select-label"
          id="type-select"
          value={type.toLowerCase()}
          onChange={onChangeType}
          sx={styles.formatField}
        >
          {["number", "dimension", "text"].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Stack direction="row" spacing="auto" alignItems="center">
        <Typography sx={styles.sectionSubtitle}>Data format</Typography>
        <Select
          labelId="dataFormat-select-label"
          id="dataFormat-select"
          value={
            type === "number" ? 0 : data_format === null ? 'null' : Number(data_format?.replace(/\D/g, ""))
          }
          onChange={onChangeDataFormat}
          disabled={isLoading || type === "number"}
          sx={styles.formatField}
        >
          <MenuItem value={null}>
            None
          </MenuItem>

          {dimensionsList.map((dimension) => (
            <MenuItem
              disabled={type === "number"}
              key={dimension.id}
              value={dimension.id}
            >
              {dimension.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack direction="row" spacing="auto" alignItems="center">
        <Typography sx={styles.sectionSubtitle}>Default Value</Typography>
        <input value={property.default_value}style={styles.formatInputField} type="text" placeholder="Enter default value" onKeyDown={(e) => {
  if (e.keyCode === 13) {
    onChangeDefaultvalue(e);
  }
}} ></input>
      </Stack>

      <Stack direction="row" spacing="auto" alignItems="center">
        <Typography sx={styles.sectionSubtitle}>
          Is dimension driver?
        </Typography>
        <Checkbox
          checked={dim_driver}
          onChange={onChangeIsDimensionDriver}
          sx={styles.checkboxWithoutLabel}
        />
      </Stack>
    </>
  );
};
