import { Stack, TableCell, Typography } from "@mui/material";
import React, { useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./styles";
import { TableCellInput } from "../TableCellInput";

export const TableBodyConstantType = ({
  dimensionItem,
  selectedDimensionsQuantity,
  tableValues,
  onChangeTableValues,
  indicator,
  formatProps,
  isEdit,
  setIsEdit,
}) => {
  const formattedValue = useMemo(() => {
    if (isEdit) {
      return null;
    }

    return Number(tableValues[indicator?.id]?.value) || null;
  }, [isEdit, tableValues, formatProps, indicator?.id]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  if (!selectedDimensionsQuantity) {
    return (
      <>
        <TableCell component="th" sx={styles.tableCell}>
          <Typography sx={styles.dimensionName}>{indicator?.name}</Typography>
        </TableCell>

        <TableCell sx={styles.editableTableCell} onClick={handleEdit}>
          <EditIcon />
          <TableCellInput
            name="value"
            value={formattedValue}
            onChange={onChangeTableValues(
              indicator,
              selectedDimensionsQuantity
            )}
          />
        </TableCell>
      </>
    );
  }

  return (
    <>
      <TableCell component="th" sx={styles.tableCell}>
        {selectedDimensionsQuantity === 1 ? (
          <Typography sx={styles.dimensionName}>
            {dimensionItem?.name}
          </Typography>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {dimensionItem?.map((item) => (
              <Typography key={item} sx={styles.dimensionName}>
                {item}
              </Typography>
            ))}
          </Stack>
        )}
      </TableCell>

      <TableCell sx={styles.editableTableCell}>
        <EditIcon />
        <TableCellInput
          name="value"
          value={
            selectedDimensionsQuantity === 1
              ? tableValues[dimensionItem?.name]?.value
              : tableValues[dimensionItem?.join("-")]?.value
          }
          onChange={onChangeTableValues(
            dimensionItem,
            selectedDimensionsQuantity
          )}
        />
      </TableCell>
    </>
  );
};
