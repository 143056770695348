import {
  Avatar,
  Card,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as BlockIcon } from "../../../../assets/icons//block-main.svg";
import card_bg from "../../../../assets/images/block_card-bg.png";
import { styles } from "./styles";
import { SettingsMenu } from "../../../../components";

export const BlockCard = ({ block, onDelete, onUpdate }) => {
  const navigate = useNavigate();
  const { modelId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const openSettingsMenu = Boolean(anchorEl);

  const openSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSettings = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={styles.card}>
      <Stack
      style={{backgroundImage:block.icon?`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${block.icon})`:`url(${card_bg})`}}
        sx={styles.iconWrapper}
        onClick={() => navigate(`/model/${modelId}/block/${block?.id}`)}
      >
        <SvgIcon
          component={BlockIcon}
          viewBox="0 0 102 96"
          sx={styles.blockIcon}
        />
      </Stack>
      <Stack sx={styles.cardContent}>
        <Stack direction="row" alignItems="center" spacing="auto">
          <Tooltip title={block?.name}>
            <Typography noWrap sx={styles.mainText}>
              {block?.name}
            </Typography>
          </Tooltip>
          <Tooltip title="Context menu">
            <IconButton aria-label="settings" onClick={openSettings}>
              <MoreHorizIcon fontSize="medium" sx={{ color: "grey.main" }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} mb={1}>
          <Typography sx={styles.mainText}>Owner:</Typography>
          <Avatar sx={styles.avatar} aria-label="avatar">
            A
          </Avatar>
        </Stack>
        <Typography gutterBottom sx={styles.auxText}>
          Last modified:
        </Typography>
        <Typography gutterBottom sx={styles.auxText}>
          Dimensions:
        </Typography>
        <Stack direction="row" spacing={1}>
          {block?.dimensions?.map((dimension) => (
            <Chip
              label={dimension?.name}
              size="small"
              variant="outlined"
              key={dimension?.name}
              sx={styles.dimensionChip}
            />
          ))}
        </Stack>
      </Stack>

      <SettingsMenu
        anchorEl={anchorEl}
        data={block}
        target="block"
        open={openSettingsMenu}
        onClose={closeSettings}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </Card>
  );
};
