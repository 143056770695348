export const styles = {
  scenarioselect: {
    display: "flex !important",
    justifyContent: "center !important",
    textAlign: "center",
    background: "#DBDCF3",
    color: "#7257CB",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    height: 40,
    borderRadius: "11px",
  },
  scenarioText: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.15,
    color: "black.main",
    marginRight: "0.5rem !important",
  },
  menuBox: {
    borderRadius: "12px",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#EFEEFE",
    },
    "&.Mui-selected": {
      backgroundColor: "#EFEEFE",
    },
  },
  addScenarioText: {
    // padding: "1rem",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "15px",
    color: "#7257CB",
    cursor: "pointer",
  },
  formPopoverContent: {
    padding: "1rem",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "300px",
  },
  formText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#212121",
  },
  formField: {
    padding: "0 !important",
    "& .MuiOutlinedInput-root": {
      // padding: "5px 10px !important",
      height: "40px",
      borderRadius: "12px",
      boxSizing: "none !important",
      // padding: "1.5px 14px !important",
      padding: "0 !important",
    },
  },
  buttonsContainer: {
    marginTop: "1rem",
    gap: "1rem",
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  button: {
    width: "66px",
    height: "35px",
    borderRadius: "6px",
    fontFamily: "Roboto ",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "15px",
  },
};
