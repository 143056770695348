export const styles = {
  indicatorNode: {
    width: 120,
    padding: 5,
    border: "1px solid",
    borderRadius: 5,
    backgroundColor: "white.main",
    color: "black.main",
  },
  indicatorLabel: {
    mb: 1,
    fontFamily: "Roboto",
    fontSize: 10,
    textAlign: "center",
  },
  indicatorChip: {
    py: 0.5,
    px: 1,
    borderRadius: 5,
    fontSize: 8,
    textAlign: "center",
    bgcolor: "primary.main",
    color: "white.main",
  },
};
