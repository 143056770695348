export const styles = {
  tableContainer: {
    height: "100%",
    width: "100%",
    pb: 2,

    "& .ag-root-wrapper": {
      border: 0,
    },

    "& .ag-header": {
      borderRadius: 1,
      border: 0,
      bgcolor: "grey.border",

      "& .ag-header-cell": {
        fontFamily: "Roboto",
        fontSize: 18,
        lineHeight: 1.26,
        letterSpacing: "-0.015em",
        color: "black.main",
      },
    },

    "& .ag-body-viewport": {
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 5,
        bgcolor: "transparent",
      },
      "::-webkit-scrollbar:horizontal": {
        height: 8,
        bgcolor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        bgcolor: "primary.main",
        borderRadius: 1,
      },
      "::-webkit-scrollbar-thumb:horizontal": {
        bgcolor: "primary.main",
        borderRadius: 1,
      },
      "::-webkit-scrollbar-button": {
        bgcolor: "transparent",
        height: 5,
      },

      "& .ag-center-cols-container": {
        width: "100%!important",
      },

      "& .ag-row": {
        borderBottom: "1px solid",
        borderColor: "grey.border",
        bgcolor: "inherit",
        "&:hover": {
          bgcolor: "inherit",
        },
      },

      "& .ag-cell": {
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 18,
        color: "black.main",
        bgcolor: "white.main",
      },
    },
  },
};
