import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { ReactComponent as DataIcon } from "../../../../assets/icons/dataIcon.svg";
import { ReactComponent as CalculatorIcon } from "../../../../assets/icons/calculator.svg";

import { styles } from "./styles";

export const IndicatorTypeMenuButton = ({ openTypeMenu, indicatorType }) => {
  return (
    <Tooltip title="Indicator type menu">
      <IconButton onClick={openTypeMenu} sx={styles.typeButton(indicatorType)}>
        {indicatorType?.toLowerCase() === "input" ? (
          <>
            <SvgIcon
              component={DataIcon}
              viewBox="0 0 83 78"
              sx={{ width: 20, height: 24 }}
            />
            <ArrowDropDownIcon sx={styles.typeButtonArrow} />
          </>
        ) : (
          <>
            <SvgIcon
              component={CalculatorIcon}
              viewBox="0 0 27 31"
              sx={{ width: 20, height: 24 }}
            />
            <ArrowDropDownIcon sx={styles.typeButtonArrow} />
          </>
        )}
      </IconButton>
    </Tooltip>
  );
};
