import React, { createContext, useContext, useState } from "react";

export const RenameIndicatorContext = createContext();

export const useRenameIndicatorContext = () => {
  const context = useContext(RenameIndicatorContext);
  if (!context) {
    throw new Error(
      "useRenameIndicatorContext must be used within a RenameIndicatorProvider"
    );
  }
  return context;
};

export const RenameIndicatorProvider = ({ children }) => {
  const [editField, setEditField] = useState({});

  const openEditField = (indicatorId) => {
    setEditField({ [indicatorId]: true });
  };

  const closeEditField = (indicatorId) => {
    setEditField({ [indicatorId]: false });
  };

  return (
    <RenameIndicatorContext.Provider
      value={{
        editField,
        onOpenEditField: openEditField,
        onCloseEditField: closeEditField,
      }}
    >
      {children}
    </RenameIndicatorContext.Provider>
  );
};
