import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { styles } from "./styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { connect } from "react-redux";
import { fetchModel } from "../../Redux/SharedSlice";
import { useParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoPopover from "../BlockDetailed/components/ViewTab/ScenarioComponent/InfoPopover";
import FormPopover from "../BlockDetailed/components/ViewTab/ScenarioComponent/FormPopover";
import NavigationSidebar from "../../components/NavigationSidebar";
import { fetchScenarios } from "../../Redux/ScenarioSlice";

const Scenarios = ({
  getModelDetails,
  modelDetail,
  modelStatus,
  getScenarios,
  scenarioList,
}) => {
  const { modelId } = useParams();
  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = useState(null);
  const [formPopoverAnchorEl, setFormPopoverAnchorEl] = useState(null);
  const [renameData, setRenameData] = useState({});
  const [menuScenerio, setMenuScenerio] = useState(null);

  useEffect(() => {
    if (modelId) {
      getModelDetails(modelId);
      getScenarios(modelId);
    }
  }, [modelId]);

  const isModelLoading = modelStatus !== "SUCCESS";

  const handleInfoIconClick = useCallback((event, scenario) => {
    event.stopPropagation();
    event.preventDefault();
    setInfoPopoverAnchorEl(event.currentTarget);
    setMenuScenerio(scenario);
  }, []);

  const handleAddScenarioClick = useCallback((event) => {
    // event.stopPropagation();
    setFormPopoverAnchorEl(event.target);
  }, []);

  const handleInfoPopoverClose = useCallback(() => {
    setInfoPopoverAnchorEl(null);
  }, []);

  const handleFormPopoverClose = () => {
    setFormPopoverAnchorEl(null);
    setRenameData({
      scenarioName: "",
      scenarioDescription: "",
    });
  };

  const handleFormPopoverOpen = (anchorEl) => {
    setFormPopoverAnchorEl(anchorEl);
  };

  const handleRename = (event) => {
    event.stopPropagation();

    if (menuScenerio) {
      setRenameData({
        scenarioId: menuScenerio.id,
        scenarioName: menuScenerio.name,
        scenarioDescription: menuScenerio.description,
        isRename: true,
      });
      handleInfoPopoverClose();
      handleFormPopoverOpen(event.currentTarget);
    }
  };

  return (
    <Stack height="100%" direction="row">
      <NavigationSidebar />
      <Stack sx={styles.content} spacing={7.5}>
        {isModelLoading ? (
          <Typography sx={{ ...styles.pageTitle(1), color: "primary.main" }}>
            ...Loading
          </Typography>
        ) : (
          <Breadcrumbs separator={<NavigateNextIcon sx={styles.separator} />}>
            <Typography sx={styles.pageTitle(1)}>
              {modelDetail?.name}
            </Typography>
            <Typography sx={styles.pageTitle(2)}>Full testing block</Typography>
          </Breadcrumbs>
        )}

        <Stack direction="row" spacing={5}>
          <Typography sx={styles.scenarioText}> Scenarios</Typography>
        </Stack>

        <Stack height="auto" width={"95%"} direction="row" sx={styles.scroll}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{ backgroundColor: "#DBDCF3", position: "sticky", top: 0 }}
              >
                <TableRow>
                  <TableCell sx={styles.tableCell}>Name</TableCell>
                  <TableCell sx={styles.tableCell}>Date created</TableCell>
                  {/* <TableCell sx={styles.tableCell}>Last modified</TableCell> */}
                  <TableCell sx={styles.tableCell}>Description</TableCell>
                  <TableCell sx={styles.tableCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scenarioList?.scenarios &&
                  scenarioList?.scenarios?.map((scenario) => (
                    <TableRow key={scenario.id}>
                      <TableCell sx={styles.tableRow}>
                        {scenario.name}
                      </TableCell>
                      <TableCell sx={styles.tableRow}>
                        {scenario?.date_created}
                      </TableCell>
                      {/* <TableCell sx={styles.tableRow}>
                        {scenario.lastUpdate}
                      </TableCell> */}
                      <TableCell sx={styles.tableRow}>
                        {scenario.description}
                      </TableCell>
                      <TableCell>
                        <IconButton  onClick={(event) =>
                              handleInfoIconClick(event, scenario)
                            }>
                          <MoreHorizIcon
                            sx={{ color: "black.main" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Typography
          sx={styles.addScenarioText}
          onClick={handleAddScenarioClick}
        >
          + Add Scenario
        </Typography>
        <InfoPopover
          anchorEl={infoPopoverAnchorEl}
          onClose={() => setInfoPopoverAnchorEl(null)}
          onRename={handleRename}
          menuScenerio={menuScenerio}
        />
        <FormPopover
          anchorEl={formPopoverAnchorEl}
          onClose={handleFormPopoverClose}
          initialValues={renameData}
        />
      </Stack>
    </Stack>
  );
};
const stateToProperty = (state) => {
  return {
    modelDetail: state.sharedSlice?.modelDetail,
    modelStatus: state.sharedSlice?.modelStatus,
    scenarioList: state.scenarioSlice?.scenarioList,
    scenarioListStatus: state.scenarioSlice?.scenarioListStatus,
  };
};
const mapDispatchToProperties = (dispatch) => {
  return {
    getModelDetails: (data) => dispatch(fetchModel(data)),
    getScenarios: (modelId) => dispatch(fetchScenarios(modelId)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(Scenarios);
