export const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 250,
    height: 115,
    p: 1.5,
    boxShadow: 0,
    overflowY: "hidden",
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1,
    color: "black.main",
  },
  cardDescription: {
    mt: 1.5,
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 1.23,
    color: "black.main",
  },
  button: {
    width: 80,
    height: "100%",
    borderColor: "primary.main",
    borderRadius: 12,
    fontSize: 11,
    lineHeight: 2.95,
    color: "black.main",
    textTransform: "none",
    "&:hover": {
      color: "white.main",
    },
  },
  previewButton: (previewedBlockId, blockId) => ({
    borderColor:
      previewedBlockId === blockId ? "secondary.main" : "primary.main",
    color: previewedBlockId === blockId ? "white.main" : "black.main",
    bgcolor: previewedBlockId === blockId ? "secondary.main" : "tranparent",
    "&:hover": {
      bgcolor: "secondary.main",
      borderColor: "secondary.main",
    },
  }),
  importButton: {
    "&:hover": {
      bgcolor: "primary.main",
    },
  },
};
