export const styles = {
  collapse: {
    ml: "50px",
    border: 0,
  },
  collapseTableBody: {
    overflowX: "auto",
    "& tr:last-child th, & tr:last-child td": { borderBottom: 0 },
  },
};
