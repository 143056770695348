import { IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";

import { styles } from "./styles";
import { DimensionPropertiesSidebar } from "../DimensionPropertiesSidebar";

export const DimensionElement = ({
  data,
  dimensionsList,
  onDelete,
  onUpdate,
  selectedId,
  setSelectedId,
}) => {
  const [isPropertiesOpen, setIsPropertiesOpen] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [updatedName, setUpdatedName] = useState("");

  const handleChangeDimensionName = (event) => {
    if (event.charCode === 13 && updatedName?.trim().length !== 0) {
      onUpdate({
        dimensionId: data?.id,
        data: { name: updatedName },
      });
      setEditClicked(false);
      setUpdatedName("");
    }
  };

  const togglePropertiesSidebar = () => {
    setIsPropertiesOpen((prevState) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem("selectedId", selectedId);
  }, [selectedId]);

  return (
    <Stack
      sx={styles.dimensionBlock(selectedId, data?.id, editClicked)}
      onClick={() => setSelectedId(data?.id)}
    >
      {editClicked ? (
        <TextField
          variant="standard"
          value={updatedName}
          onChange={(event) => setUpdatedName(event.currentTarget.value)}
          onKeyPress={handleChangeDimensionName}
          onBlur={() => setEditClicked(false)}
          placeholder={data?.name}
          autoFocus
          fullWidth
          sx={styles.textField}
          inputProps={{ style: styles.input }}
        />
      ) : (
        <>
          <Typography sx={styles.dimensionName}>{data?.name}</Typography>
          <IconButton
            aria-label="dimension settings"
            sx={styles.actionButton("settings")}
            onClick={togglePropertiesSidebar}
          >
            <SettingsIcon fontSize="large" />
          </IconButton>

          <IconButton
            aria-label="edit dimension"
            sx={styles.actionButton("edit")}
            onClick={() => setEditClicked(true)}
          >
            <EditIcon fontSize="large" />
          </IconButton>

          <IconButton
            aria-label="delete dimension"
            sx={styles.actionButton("delete")}
            onClick={() => onDelete(data?.id)}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>

          <DimensionPropertiesSidebar
            open={isPropertiesOpen}
            onClose={togglePropertiesSidebar}
            dimension={data}
            dimensionsList={dimensionsList}
          />
        </>
      )}
    </Stack>
  );
};
