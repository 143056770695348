export const styles = {
  appbar: {
    height: 100,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    px: 5,
    bgcolor: "transparent",
    boxShadow: 0,
  },
  iconsContainer: {
    flexDirection: "row",
    alignItems: "center",
    "& > button:not(:last-child), & > hr": {
      mr: 1,
    },
  },
  iconButton: {
    color: "primary.main",
    transition: "all 275ms",
    "&:hover": { color: "primary.dark", bgcolor: "transparent" },
  },
  icon: { width: 32, height: 32 },
  shareIcon: {
    height: 40,
    fontWeight: 700,
    fontSize: 14,
    textTransform: "capitalize",
  },
  avatar: {
    color: "white",
    bgcolor: "primary.main",
    transition: "all 275ms",
    "&:hover": { bgcolor: "primary.dark" },
  },
};
