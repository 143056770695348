export const styles = {
  card: {
    width: 380,
    height: 230,
    p: 3.5,
    pb: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  cardTitle: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.26,
    color: "black.main",
  },
  cardSubtitle: {
    mb: 1.5,
    fontFamily: "Roboto",
    fontSize: 15,
    color: "primary.light",
    lineHeight: 1.51,
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  },
  cardDesc: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 1.33,
    color: "black.main",
  },
  previewButton: {
    height: 32,
    fontSize: 13,
    letterSpacing: "0.05",
  },
};
