export const DATA_TYPE = [
  {
    name: "Number",
    value:  "number",
  },
  {
    name: "Statistic",
    value:  "statistic",
  },
  {
    name: "Percentage",
    value:  "percentage",
  },
  {
    name: "Ratio",
    value:  "ratio",
  },
  {
    name: "Balance",
    value:  "balance",
  },
  {
    name: 'Opening balance',
    value:  'opening_balance',
  }
];
