export const styles = {
  mapContainer: {
    position: "relative",
    width: "100%",
    height: "100%",

    "& .react-flow": {
      "& .react-flow__attribution": {
        display: "none",
      },
    },
  },
};
