import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  addIndicatorLoaderStatus: false
};

const IndicatorSlice = createSlice({
  initialState,
  name: "indicatorSlice",
  reducers: {
    handleAddIndicatorLoader: (state, action) => {
      state.addIndicatorLoaderStatus = action.payload
    }
  },
  extraReducers: (builder) => {},
});

export const getAddIndicatorLoaderStatus = (state) => state.indicatorSlice.addIndicatorLoaderStatus;

export const { handleAddIndicatorLoader } = IndicatorSlice.actions

export default IndicatorSlice.reducer;
