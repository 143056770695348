import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import {
  Box,
  Breadcrumbs,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";

import { styles } from "./styles";
import { apiService } from "../../../../services";
import { useLibraryModalContext } from "../../../../context";
import { Loader } from "../../../../components";
import { Logo } from "../../../../icons";
import { LibrarySecondModalCard } from "../LibrarySecondModalCard";
import { Preview } from "../Preview";

export const LibrarySecondModal = ({
  open,
  onClose,
  onImportBlockFromLibrary,
  selectedFirstModalBlockName,
  setSelectedFirstModalBlockName,
}) => {
  const { onBackToFirstLibraryModal } = useLibraryModalContext();
  const [searchValue, setSearchValue] = useState("");
  const [previewedBlockId, setPreviewedBlockId] = useState(null);
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);

  const { data, refetch } = useQuery("libraryBlocks", () =>
    apiService.getLibraryBlocks({
      params: {
        search: selectedFirstModalBlockName ? selectedFirstModalBlockName : "",
      },
    })
  );

  const { isLoading: isPreviewLoading } = useQuery(
    ["blockMap", previewedBlockId],
    () => apiService.getBlockMap(previewedBlockId),
    {
      enabled: Boolean(previewedBlockId),
      onSuccess: (response) => {
        if (response.data.length > 0) {
          const sourceElements = response.data;
          const initialNodes = sourceElements.reduce((acc, sourceElement) => {
            if (sourceElement.hasOwnProperty("data")) {
              sourceElement.type = "indicator";
              sourceElement.id = String(sourceElement.id);
              sourceElement.position = { x: 0, y: 0 };

              acc.push(sourceElement);
            }
            return acc;
          }, []);

          const initialEdges = sourceElements.reduce((acc, sourceElement) => {
            if (sourceElement.hasOwnProperty("source")) {
              sourceElement.type = "smoothstep";
              sourceElement.animated = false;
              sourceElement.markerEnd = { type: "arrow" };
              acc.push(sourceElement);
            }
            return acc;
          }, []);

          setInitialNodes(initialNodes);
          setInitialEdges(initialEdges);
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch, selectedFirstModalBlockName]);

  const searchBlock = (event) => {
    setSearchValue(event.target.value);
  };

  const previewBlock = (block) => {
    setPreviewedBlockId(block?.id);
  };

  const importBlock = (block) => {
    onImportBlockFromLibrary(block);
    setSelectedFirstModalBlockName("");
    onClose();
  };

  const closeModal = () => {
    onClose();
    setSelectedFirstModalBlockName("");
  };

  const backToFirstModal = () => {
    onBackToFirstLibraryModal();
    setSelectedFirstModalBlockName("");
  };

  const filteredLibraryBlocks = useMemo(() => {
    return data?.data?.blocks?.filter((block) =>
      block?.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );
  }, [data?.data?.blocks, searchValue]);

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={styles.modal}>
        <IconButton onClick={closeModal} sx={styles.closeButton}>
          <CloseIcon fontSize="large" />
        </IconButton>

        <Stack spacing={5} sx={{ height: "100%" }}>
          <Stack direction="row" alignItems="flex-end" spacing={3}>
            <Logo isSmall />
            <Breadcrumbs
              separator={<NavigateNextIcon sx={styles.separator} />}
              sx={styles.breadcrumbs}
            >
              <Typography sx={styles.modalName} onClick={backToFirstModal}>
                Library
              </Typography>
              <Typography sx={styles.blockName}>
                {selectedFirstModalBlockName
                  ? selectedFirstModalBlockName
                  : "All blocks"}
              </Typography>
            </Breadcrumbs>
          </Stack>

          <Stack sx={{ width: 570 }}>
            <TextField
              value={searchValue}
              onChange={searchBlock}
              placeholder={`Search ${
                selectedFirstModalBlockName
                  ? selectedFirstModalBlockName?.toLocaleLowerCase()
                  : "any"
              } block`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="large"
                      sx={{ color: "secondary.main" }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={styles.textField}
            />
          </Stack>

          <Box sx={styles.contentSection}>
            <Box sx={styles.cardsContainer}>
              {filteredLibraryBlocks?.map((block) => (
                <LibrarySecondModalCard
                  key={block?.id}
                  block={block}
                  previewedBlockId={previewedBlockId}
                  onImport={() => importBlock(block)}
                  onPreview={() => previewBlock(block)}
                />
              ))}
            </Box>

            <Box sx={styles.previewContainer}>
              {isPreviewLoading ? (
                <Loader sx={styles.loader} />
              ) : (
                <Preview
                  initialNodes={initialNodes}
                  initialEdges={initialEdges}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

LibrarySecondModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onImportBlockFromLibrary: PropTypes.func.isRequired,
  selectedFirstModalBlockName: PropTypes.string,
  setSelectedFirstModalBlockName: PropTypes.func,
};
