export const styles = {
  pivotList: (listIndex) => ({
    px: listIndex > 0 ? 4 : 0,
    pt: listIndex === 0 || listIndex > 1 ? 0 : 4,
    pb: listIndex > 0 ? 4 : 0,
    borderTopLeftRadius: listIndex === 1 ? 20 : 0,
    borderTopRightRadius: listIndex === 1 ? 20 : 0,
    borderBottomLeftRadius: listIndex === 3 ? 20 : 0,
    borderBottomRightRadius: listIndex === 3 ? 20 : 0,
    bgcolor: listIndex > 0 ? "grey.background" : "white.main",
  }),
  listIcon: { width: 16, height: 16 },
  pivotListName: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    lineHeight: 1.375,
    color: "black.main",
  },
  divider: {
    "&.MuiDivider-root": { my: 3 },
    "&.MuiDivider-root::before": { top: 0 },
    "&.MuiDivider-root::after": { top: 0 },
  },
  dividerIcon: {
    width: 20,
    height: 24,
    opacity: 0.4,
  },
};
