
export const styles = {
  card: {
    position: "relative",
    width: 380,
    height: 230,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  cardContent: {
    height: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  title: {
    mt: 4,
    fontFamily: "Roboto",
    fontSize: 22,
    lineHeight: 1.04,
    color: "white.main",
  },
  chartIcon: { width: 48, height: 43, fill: "transparent" },
  settingIcon: {
    position: "absolute",
    top: 0,
    right: 10,
    width: 40,
    height: 40,
    cursor: "pointer",
    color: "white.main",
    opacity: 0.5,
    transition: "all 275ms",
    "&:hover": { opacity: 1 },
  },
};
