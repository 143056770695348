import { Stack } from "@mui/material";
import ReactLoader from "react-loader-spinner";

import { palette } from "../../theme/palette";

export const Loader = ({ loaderWidth = 50, loaderHeight = 50, ...props }) => {
  return (
    <Stack direction="row" {...props}>
      <ReactLoader
        type="Circles"
        color={palette.secondary.main}
        height={loaderHeight}
        width={loaderWidth}
      />
    </Stack>
  );
};
